import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountPipe } from './count.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { NgMaxlengthDirective } from '../directives/ng-maxlength.directive';
import { ChatDateFormatPipe } from './chat-date-format.pipe';
import { PointFilterPipe } from './point-filter.pipe';

@NgModule({
  declarations: [
    NgMaxlengthDirective,
    CountPipe,
    DateFormatPipe,
    ChatDateFormatPipe,
    PointFilterPipe,
  ],
  imports: [CommonModule],
  exports: [
    NgMaxlengthDirective,
    CountPipe,
    DateFormatPipe,
    ChatDateFormatPipe,
    PointFilterPipe,
  ],
})
export class PipesModule {
  static forRoot() {
    return {
      ngModule: PipesModule,
      providers: [],
    };
  }
}
