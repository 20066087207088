import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionSheetController, NavController } from '@ionic/angular';
import { Comment } from 'src/app/core/models/comment.model';
import { Member } from 'src/app/core/models/member.model';
import { Post } from 'src/app/core/models/post.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommunityService } from 'src/app/core/services/community.service';
import { ImageService } from 'src/app/core/services/image.service';

@Component({
  selector: 'app-comment-shared',
  templateUrl: './comment-shared.component.html',
  styleUrls: ['./comment-shared.component.scss'],
})
export class CommentSharedComponent implements OnInit {
  @Input() item: Comment | any;
  @Input() commentWriter: Comment['uuid']; // 댓글 작성자
  @Input() postWriterUuid: Post['uuid']; // 게시글 작성자
  @Input() recomments: Comment | any;
  @Input() type: 'replyWritePage'; // 대댓글 작성 페이지에서 '답글쓰기' 나타나지 않기 위함
  @Output() itemChanged: EventEmitter<string> = new EventEmitter<string>();
  member: Member;

  constructor(
    private actionSheetController: ActionSheetController,
    private navController: NavController,
    private alertService: AlertService,
    private auth: AuthService,
    private communityService: CommunityService,
    private imageService: ImageService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
  }

  //타인의 댓글 신고
  async commentReportAction(
    type: 'recomment' | 'comment',
    commentId: Comment['id']
  ) {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: `${type === 'recomment' ? '답글' : '댓글'} 신고`,
          handler: () => {
            this.navController.navigateForward('/post-report', {
              queryParams: { type: 'comment', commentId },
            });
          },
        },
        {
          text: '취소',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });

    await actionSheet.present();
  }

  //내 댓글 수정 삭제
  async commentMyReportAction(type: 'recomment' | 'comment', recomment) {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: `${type === 'recomment' ? '답글' : '댓글'} 수정`,
          handler: () => {
            const input = this.alertService
              .inputAlert(
                'textarea',
                `${type === 'recomment' ? '답글' : '댓글'}을 입력해주세요.`,
                '',
                `${type === 'recomment' ? '답글' : '댓글'} 수정`
              )
              .then(async (result) => {
                if (result) {
                  await this.communityService.updateComment(
                    type === 'comment' ? this.item : recomment,
                    result.trim()
                  );
                  this.alertService.presentToast(
                    `${type === 'recomment' ? '답글' : '댓글'}을 수정했어요.`
                  );
                  this.itemChanged.emit('edit');
                } else {
                  return;
                }
              });
          },
        },
        {
          text: `${type === 'recomment' ? '답글' : '댓글'} 삭제`,
          handler: () => {
            this.alertService
              .cancelOkBtn(
                `${type === 'recomment' ? '답글' : '댓글'}을 삭제하시겠어요?`
              )
              .then(async (ok) => {
                if (ok) {
                  await this.communityService.deleteComment(
                    type === 'comment' ? this.item.id : recomment.id
                  );
                  this.alertService.presentToast(
                    `${type === 'recomment' ? '답글' : '댓글'}을 삭제했어요.`
                  );
                  this.itemChanged.emit('delete');
                }
              });
          },
        },
        {
          text: '취소',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });

    await actionSheet.present();
  }

  // 이미지디테일 모달
  imageDetail(type: 'comment' | 'recomment', idx: number, recomment?) {
    if (type === 'comment') {
      if (this.item.uuid.profile) {
        this.imageService.imageDetail(this.item.uuid.profile, 'profile', idx);
      } else {
        return;
      }
    } else {
      if (recomment.uuid.profile) {
        this.imageService.imageDetail(recomment.uuid.profile, 'profile', idx);
      } else {
        return;
      }
    }
  }

  // [답글쓰기]
  goReplyWrite(comment: Comment) {
    this.navController.navigateForward('/reply-write', {
      queryParams: {
        comment,
        postWriterUuid: this.postWriterUuid,
      },
    });
  }
}
