import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AlarmListComponent } from './pages/common/alarm-list/alarm-list.component';
import { SettingComponent } from './pages/common/setting/setting.component';
import { IntroComponent } from './pages/common/intro/intro.component';
import { FindPasswordComponent } from './pages/common/find-password/find-password.component';
import { LoginComponent } from './pages/common/login/login.component';
import { AlarmDetailComponent } from './pages/common/alarm-detail/alarm-detail.component';
import { NoticeListComponent } from './pages/common/notice-list/notice-list.component';
import { JoinNameComponent } from './pages/common/join-name/join-name.component';
import { JoinEmailComponent } from './pages/common/join-email/join-email.component';
import { JoinPasswordComponent } from './pages/common/join-password/join-password.component';
import { JoinRecommendationComponent } from './pages/common/join-recommendation/join-recommendation.component';
import { JoinAgreeComponent } from './pages/common/join-agree/join-agree.component';
import { JoinCompleteComponent } from './pages/common/join-complete/join-complete.component';
import { TermsComponent } from './pages/common/terms/terms.component';
import { NoticeDetailComponent } from './pages/common/notice-detail/notice-detail.component';
import { FaqListComponent } from './pages/common/faq-list/faq-list.component';
import { FaqDetailComponent } from './pages/common/faq-detail/faq-detail.component';
import { PasswordEditComponent } from './pages/common/password-edit/password-edit.component';
import { WithdrawalReasonComponent } from './pages/common/withdrawal-reason/withdrawal-reason.component';
import { WithdrawalCheckComponent } from './pages/common/withdrawal-check/withdrawal-check.component';
import { SuspensionComponent } from './pages/common/suspension/suspension.component';
import { JoinExpertAddressComponent } from './pages/master/join-expert-address/join-expert-address.component';
import { SelectRegionComponent } from './pages/master/select-region/select-region.component';
import { JoinExpertAddInfoComponent } from './pages/master/join-expert-add-info/join-expert-add-info.component';
import { JoinExpertAddInfo02Component } from './pages/master/join-expert-add-info02/join-expert-add-info02.component';
import { JoinExpertCompleteComponent } from './pages/master/join-expert-complete/join-expert-complete.component';
import { SearchComponent } from './pages/user/search/search.component';
import { EstimateRequestComponent } from './pages/user/estimate-request/estimate-request.component';
import { ExpertDetailComponent } from './pages/user/expert-detail/expert-detail.component';
import { CategorySelectComponent } from './pages/user/category-select/category-select.component';
import { PostDetailComponent } from './pages/common/post-detail/post-detail.component';
import { ApplicationAddressComponent } from './pages/user/application-address/application-address.component';
import { ApplicationTypeComponent } from './pages/user/application-type/application-type.component';
import { ApplicationDateComponent } from './pages/user/application-date/application-date.component';
import { CalendarPopComponent } from './pages/user/calendar-pop/calendar-pop.component';
import { ApplicationAreaComponent } from './pages/user/application-area/application-area.component';
import { ApplicationTextareaComponent } from './pages/user/application-textarea/application-textarea.component';
import { ApplicationImageComponent } from './pages/user/application-image/application-image.component';
import { ApplicationLastInquiryComponent } from './pages/user/application-last-inquiry/application-last-inquiry.component';
import { ApplicationCompleteComponent } from './pages/user/application-complete/application-complete.component';
import { ProfileDetailUserComponent } from './pages/user/profile-detail-user/profile-detail-user.component';
import { InsuranceListComponent } from './pages/user/insurance-list/insurance-list.component';
import { InsuranceDetailComponent } from './pages/user/insurance-detail/insurance-detail.component';

import { QnaListComponent } from './pages/common/qna-list/qna-list.component';
import { MyPostComponent } from './pages/common/my-post/my-post.component';
import { InsuranceRefundApplicationComponent } from './pages/user/insurance-refund-application/insurance-refund-application.component';
import { RefundTermComponent } from './pages/common/refund-term/refund-term.component';
import { MyReviewListComponent } from './pages/common/my-review-list/my-review-list.component';
import { ReviewWriteComponent } from './pages/user/review-write/review-write.component';
import { QnaWriteComponent } from './pages/common/qna-write/qna-write.component';
import { QnaDetailComponent } from './pages/common/qna-detail/qna-detail.component';
import { FilterModalComponent } from './pages/user/filter-modal/filter-modal.component';
import { ServiceDetailComponent } from './pages/common/service-detail/service-detail.component';
import { BusinessInformationDetailComponent } from './pages/user/business-information-detail/business-information-detail.component';
import { PortfolioDetailComponent } from './pages/user/portfolio-detail/portfolio-detail.component';
import { ReviewDetailComponent } from './pages/common/review-detail/review-detail.component';
import { ExpertQnaDetailComponent } from './pages/common/expert-qna-detail/expert-qna-detail.component';
import { EstimateRequestDetailComponent } from './pages/common/estimate-request-detail/estimate-request-detail.component';
import { EstimateCheckDetailComponent } from './pages/common/estimate-check-detail/estimate-check-detail.component';
import { ChatDetailComponent } from './pages/common/chat-detail/chat-detail.component';
import { ReportComponent } from './pages/common/report/report.component';
import { InsurancePaymentComponent } from './pages/user/insurance-payment/insurance-payment.component';
import { InsuranceCompleteComponent } from './pages/user/insurance-complete/insurance-complete.component';
import { PostWriteComponent } from './pages/user/post-write/post-write.component';
import { PostReportComponent } from './pages/common/post-report/post-report.component';
import { PointChargeComponent } from './pages/master/point-charge/point-charge.component';
import { MyEstimateComponent } from './pages/master/my-estimate/my-estimate.component';
import { MyEstimateDetailComponent } from './pages/master/my-estimate-detail/my-estimate-detail.component';
import { ReplyWriteComponent } from './pages/common/reply-write/reply-write.component';
import { PaymentListComponent } from './pages/master/payment-list/payment-list.component';
import { ExpertWriteComponent } from './pages/master/expert-write/expert-write.component';
import { ServiceSelectComponent } from './pages/master/service-select/service-select.component';
import { ServiceDetailWriteComponent } from './pages/master/service-detail-write/service-detail-write.component';
import { CareerSelectComponent } from './pages/master/career-select/career-select.component';
import { PortfolioListComponent } from './pages/master/portfolio-list/portfolio-list.component';
import { PortfolioAddComponent } from './pages/master/portfolio-add/portfolio-add.component';
import { ExpertQnaListComponent } from './pages/master/expert-qna-list/expert-qna-list.component';
import { LinkComponent } from './pages/master/link/link.component';
import { MyEstimateWriteComponent } from './pages/master/my-estimate-write/my-estimate-write.component';
import { TransactionListComponent } from './pages/master/transaction-list/transaction-list.component';
import { TransactionDetailComponent } from './pages/master/transaction-detail/transaction-detail.component';
import { ExpertIntroductionWriteComponent } from './pages/master/expert-introduction-write/expert-introduction-write.component';
import { HomePopComponent } from './pages/common/home-pop/home-pop.component';
import { ApplicationComplete02Component } from './pages/common/application-complete02/application-complete02.component';
import { ProfileDetailMasterComponent } from './pages/master/profile-detail-master/profile-detail-master.component';
import { AuthGuard } from './core/guards/auth.guard';
import { JoinExpertComponent } from './pages/master/join-expert/join-expert.component';
import { MyLikeComponent } from './pages/common/my-like/my-like.component';
import { AutomaticChargingComponent } from './pages/master/automatic-charging/automatic-charging.component';
import { PaymentRegistComponent } from './pages/master/payment-regist/payment-regist.component';
import { AutoRechargeSettingComponent } from './pages/master/auto-recharge-setting/auto-recharge-setting.component';
import { AutomaticChargingChangeComponent } from './pages/master/automatic-charging-change/automatic-charging-change.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'intro',
    component: IntroComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'password-reset',
    component: FindPasswordComponent,
  },
  {
    path: 'join-name',
    component: JoinNameComponent,
  },
  {
    path: 'join-email',
    component: JoinEmailComponent,
  },
  {
    path: 'join-password',
    component: JoinPasswordComponent,
  },
  {
    path: 'join-recommendation',
    component: JoinRecommendationComponent,
  },
  {
    path: 'join-agree',
    component: JoinAgreeComponent,
  },
  {
    path: 'join-complete',
    component: JoinCompleteComponent,
  },
  {
    path: 'join-expert',
    component: JoinExpertComponent,
  },
  {
    path: 'join-expert-address',
    component: JoinExpertAddressComponent,
  },

  {
    path: 'select-region',
    component: SelectRegionComponent,
  },
  {
    path: 'join-expert-add-info',
    component: JoinExpertAddInfoComponent,
  },

  {
    path: 'join-expert-add-info02',
    component: JoinExpertAddInfo02Component,
  },

  {
    path: 'join-expert-complete',
    component: JoinExpertCompleteComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },
  {
    path: 'alarm-list',
    component: AlarmListComponent,
  },
  {
    path: 'alarm-detail',
    component: AlarmDetailComponent,
  },
  {
    path: 'notice-list',
    component: NoticeListComponent,
  },
  {
    path: 'notice-detail',
    component: NoticeDetailComponent,
  },
  {
    path: 'faq-list',
    component: FaqListComponent,
  },
  {
    path: 'faq-detail',
    component: FaqDetailComponent,
  },
  {
    path: 'qna-list',
    component: QnaListComponent,
  },
  {
    path: 'setting',
    component: SettingComponent,
  },

  {
    path: 'terms',
    component: TermsComponent,
  },

  {
    path: 'password-edit',
    component: PasswordEditComponent,
  },
  {
    path: 'withdrawal-reason',
    component: WithdrawalReasonComponent,
  },
  {
    path: 'withdrawal-check',
    component: WithdrawalCheckComponent,
  },
  {
    path: 'suspension',
    component: SuspensionComponent,
  },
  {
    path: 'estimate-request',
    component: EstimateRequestComponent,
  },
  {
    path: 'expert-detail',
    component: ExpertDetailComponent,
  },
  {
    path: 'category-select',
    component: CategorySelectComponent,
  },
  {
    path: 'post-detail',
    component: PostDetailComponent,
  },
  {
    path: 'application-address',
    component: ApplicationAddressComponent,
  },
  {
    path: 'application-type',
    component: ApplicationTypeComponent,
  },
  {
    path: 'application-date',
    component: ApplicationDateComponent,
  },
  {
    path: 'calendar-pop',
    component: CalendarPopComponent,
  },

  {
    path: 'application-area',
    component: ApplicationAreaComponent,
  },
  {
    path: 'application-textarea',
    component: ApplicationTextareaComponent,
  },
  {
    path: 'application-image',
    component: ApplicationImageComponent,
  },
  {
    path: 'application-last-inquiry',
    component: ApplicationLastInquiryComponent,
  },
  {
    path: 'application-complete',
    component: ApplicationCompleteComponent,
  },
  {
    path: 'profile-detail-user',
    component: ProfileDetailUserComponent,
  },
  {
    path: 'profile-detail-master',
    component: ProfileDetailMasterComponent,
  },
  {
    path: 'insurance-list',
    component: InsuranceListComponent,
  },
  {
    path: 'insurance-detail',
    component: InsuranceDetailComponent,
  },
  {
    path: 'my-like',
    component: MyLikeComponent,
  },
  {
    path: 'my-post',
    component: MyPostComponent,
  },
  {
    path: 'insurance-refund-application',
    component: InsuranceRefundApplicationComponent,
  },
  {
    path: 'refund-term',
    component: RefundTermComponent,
  },
  {
    path: 'my-review-list',
    component: MyReviewListComponent,
  },
  {
    path: 'review-write',
    component: ReviewWriteComponent,
  },
  {
    path: 'qna-write',
    component: QnaWriteComponent,
  },
  {
    path: 'qna-detail',
    component: QnaDetailComponent,
  },
  {
    path: 'withdrawal-reason',
    component: WithdrawalReasonComponent,
  },
  {
    path: 'withdrawal-check',
    component: WithdrawalCheckComponent,
  },
  {
    path: 'filter-modal',
    component: FilterModalComponent,
  },
  {
    path: 'service-detail',
    component: ServiceDetailComponent,
  },
  {
    path: 'business-information-detail',
    component: BusinessInformationDetailComponent,
  },
  {
    path: 'portfolio-detail',
    component: PortfolioDetailComponent,
  },
  {
    path: 'review-detail',
    component: ReviewDetailComponent,
  },
  {
    path: 'expert-qna-detail',
    component: ExpertQnaDetailComponent,
  },
  {
    path: 'estimate-request-detail',
    component: EstimateRequestDetailComponent,
  },
  {
    path: 'estimate-check-detail',
    component: EstimateCheckDetailComponent,
  },
  {
    path: 'chat-detail',
    component: ChatDetailComponent,
  },
  {
    path: 'report',
    component: ReportComponent,
  },
  {
    path: 'post-report',
    component: PostReportComponent,
  },
  {
    path: 'insurance-payment',
    component: InsurancePaymentComponent,
  },
  {
    path: 'insurance-complete',
    component: InsuranceCompleteComponent,
  },
  {
    path: 'post-write',
    component: PostWriteComponent,
  },
  {
    path: 'point-charge',
    component: PointChargeComponent,
  },
  {
    path: 'my-estimate',
    component: MyEstimateComponent,
  },
  {
    path: 'my-estimate-detail',
    component: MyEstimateDetailComponent,
  },
  {
    path: 'reply-write',
    component: ReplyWriteComponent,
  },
  {
    path: 'payment-list',
    component: PaymentListComponent,
  },
  {
    path: 'expert-write',
    component: ExpertWriteComponent,
  },
  {
    path: 'service-select',
    component: ServiceSelectComponent,
  },
  {
    path: 'service-detail-write',
    component: ServiceDetailWriteComponent,
  },
  {
    path: 'career-select',
    component: CareerSelectComponent,
  },
  {
    path: 'portfolio-list',
    component: PortfolioListComponent,
  },
  {
    path: 'portfolio-add',
    component: PortfolioAddComponent,
  },
  {
    path: 'expert-qna-list',
    component: ExpertQnaListComponent,
  },
  {
    path: 'link',
    component: LinkComponent,
  },
  {
    path: 'my-estimate-write',
    component: MyEstimateWriteComponent,
  },
  {
    path: 'transaction-list',
    component: TransactionListComponent,
  },
  {
    path: 'transaction-detail',
    component: TransactionDetailComponent,
  },
  {
    path: 'suspension',
    component: SuspensionComponent,
  },
  {
    path: 'expert-introduction-write',
    component: ExpertIntroductionWriteComponent,
  },
  {
    path: 'home-pop',
    component: HomePopComponent,
  },
  {
    path: 'application-complete02',
    component: ApplicationComplete02Component,
  },
  {
    path: 'automatic-charging',
    component: AutomaticChargingComponent,
  },
  {
    path: 'payment-regist',
    component: PaymentRegistComponent,
  },
  {
    path: 'auto-recharge-setting',
    component: AutoRechargeSettingComponent,
  },
  {
    path: 'automatic-charging-change',
    component: AutomaticChargingChangeComponent,
  },
  {
    path: 'request-list',
    loadChildren: () =>
      import('./pages/user/request-list/request-list.module').then(
        (m) => m.RequestListPageModule
      ),
  },
  {
    path: 'expert-check',
    loadChildren: () =>
      import('./pages/user/expert-check/expert-check.module').then(
        (m) => m.ExpertCheckPageModule
      ),
  },
  {
    path: 'estimate',
    loadChildren: () =>
      import('./pages/user/estimate/estimate.module').then(
        (m) => m.EstimatePageModule
      ),
  },
  {
    path: 'chat-list',
    loadChildren: () =>
      import('./pages/user/chat-list/chat-list.module').then(
        (m) => m.ChatListPageModule
      ),
  },
  {
    path: 'community',
    loadChildren: () =>
      import('./pages/common/community/community.module').then(
        (m) => m.CommunityPageModule
      ),
  },
  {
    path: 'mypage',
    loadChildren: () =>
      import('./pages/common/mypage/mypage.module').then(
        (m) => m.MypagePageModule
      ),
  },

  {
    path: 'home-nomember',
    loadChildren: () =>
      import('./pages/common/home-nomember/home-nomember.module').then(
        (m) => m.HomeNomemberPageModule
      ),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
