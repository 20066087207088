import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  LoadingController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { TextZoom } from '@capacitor/text-zoom';
import { AlertService } from 'src/app/core/services/alert.service';
import { Device } from '@capacitor/device';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import OneSignal from 'onesignal-cordova-plugin';
import 'cordova-plugin-purchase';
import { InAppService } from './core/services/in-app.service';
import { ExpertService } from './core/services/expert.service';
import { RequestService } from './core/services/request.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  //백버튼 시간
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  isAlertPresenting = false; // 백버튼 알림 중복 방지

  constructor(
    private platform: Platform,
    private router: Router,
    private loadingController: LoadingController,
    public navController: NavController,
    public alertService: AlertService,
    private ngZone: NgZone,
    public modalController: ModalController,
    private inAppService: InAppService,
    private expertService: ExpertService,
    private requestService: RequestService
  ) {
    this.initializeApp();
  }

  //시작시 세팅
  async initializeApp() {
    //안예쁘긴 한데 이게 최선이에요
    this.platform.ready().then(async (data) => {
      const deviceInfo = await Device.getInfo();

      //서버로 돌릴때 진행하지 않기
      if (!this.platform.is('capacitor')) {
        return false;
      }

      await ScreenOrientation.lock({ orientation: 'portrait' });

      this.backbutton();
      this.inAppService.init();

      //디바이스가 안드로이 12 이상일때 옵션
      if (
        deviceInfo.platform == 'android' &&
        Number(deviceInfo.osVersion) > 11
      ) {
        //유저 폰트 크기 고정
        await TextZoom.set({ value: 1 });

        SplashScreen.hide().then(async (data) => {
          await SplashScreen.show({
            autoHide: false,
          });

          setTimeout(async () => {
            await StatusBar.setBackgroundColor({
              color: '#ffffff',
            });
            await StatusBar.setOverlaysWebView({
              overlay: false,
            });
            await StatusBar.setStyle({
              style: Style.Light,
            });
            await SplashScreen.hide();
          }, 500);
        });
      } else {
        //그외
        await TextZoom.set({ value: 1 });

        await StatusBar.setStyle({
          style: Style.Light,
        });

        if (deviceInfo.platform == 'android') {
          await StatusBar.setBackgroundColor({
            color: '#ffffff',
          });
          await StatusBar.setOverlaysWebView({
            overlay: false,
          });
        }
        await SplashScreen.hide();
      }

      this.oneSignalInit();
    });
  }

  //백버튼 기본 셋팅
  backbutton() {
    this.platform.backButton.subscribeWithPriority(0, async () => {
      if (await this.loadingController.getTop()) {
        return;
      }

      // 현재 알림이 표시 중인지 확인
      if (this.alertService.isAlertDisplayed()) {
        return; // 이미 알림이 표시 중이면 추가 작업을 하지 않음
      }
      // 뒤로가기 알림 표시 여부 확인
      if (this.alertService.isBackAlertDisplayed()) {
        this.alertService.setBackAlertDisplayed(false); // 플래그 초기화
        return; // 이미 알림이 표시된 경우 추가 알림 표시를 막음
      }

      let url = this.router.url;

      switch (url) {
        case '/':
        case '/tabs/home': {
          if (
            new Date().getTime() - this.lastTimeBackPress <
            this.timePeriodToExit
          ) {
            navigator['app'].exitApp();
          } else {
            this.alertService.presentToast('다시 한번 누르면 종료됩니다.');
            this.lastTimeBackPress = new Date().getTime();
          }
          break;
        }
        default: {
          if (url.includes('/join-expert?type=')) {
            this.expertService.clearData();
            this.navController.pop();
          } else if (url.includes('/category-select?categoryId=')) {
            this.requestService.clearData();
            this.navController.pop();
          } else if (url.includes('/application-address')) {
            this.alertService
              .cancelOkBtn(
                '입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?'
              )
              .then((ok) => {
                if (ok) {
                  this.requestService.clearData();
                  this.navController.pop();
                }
              });
          } else if (url === '/expert-write?path=mypage') {
            this.alertService.okBtn(
              '최초 전문가 전환 시 전문가명을 기입해주세요.'
            );
          } else if (url === '/expert-write?path=mypage') {
            this.alertService.okBtn(
              '최초 전문가 전환 시 전문가명을 기입해주세요.'
            );
          } else if (
            url.includes('/post-report') ||
            url.includes('/insurance-refund-application') ||
            url.includes('/portfolio-list') ||
            url.includes('/portfolio-add') ||
            url.includes('/review-write') ||
            url.includes('/my-estimate-write') ||
            url.includes('/qna-write') ||
            url.includes('/password-edit') ||
            url.includes('/post-write') ||
            url.includes('/profile-detail-user') ||
            url.includes('/profile-detail-master') ||
            url.includes('/join-expert-address?type=mypage')
          ) {
            this.alertService.backAlert();
          } else if (
            url.includes('/expert-write') ||
            url.includes('/service-select') ||
            url.includes('/service-detail-write') ||
            url.includes('/expert-introduction-write') ||
            url.includes('/career-select') ||
            url.includes('/expert-qna-list') ||
            url.includes('/link') ||
            url.includes('/join-expert-address?type=mypage')
          ) {
            this.alertService
              .cancelOkBtn(
                '입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?'
              )
              .then((ok) => {
                if (ok) {
                  this.modalController.dismiss();
                }
              });
          } else {
            this.navController.pop();
          }

          break;
        }
      }
    });
  }

  // 안드로이드 백버튼 사용시 알럿
  backAlert() {
    if (this.isAlertPresenting) return; // 이미 알림이 표시되고 있는 경우 무시
    this.isAlertPresenting = true; // 알림 표시 상태로 설정
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        this.isAlertPresenting = false;
        if (ok) {
          this.navController.pop();
        }
      })
      .catch(() => {
        this.isAlertPresenting = false;
      });
  }

  // 원시그널 셋팅
  oneSignalInit() {
    OneSignal.setAppId('834f46ac-19d4-47e9-a4fa-960e49732742');
    OneSignal.promptForPushNotificationsWithUserResponse();
    OneSignal.setNotificationOpenedHandler((ev: any) => {
      const data = ev.notification.additionalData;
      if (data) {
        this.ngZone.run(() => {
          if (this.router.url.split('?')[0] === data.url.split('?')[0]) {
            this.navController.navigateRoot('/').then(() => {
              this.navController.navigateForward(data.url);
            });
          } else {
            this.navController.navigateRoot('/').then(() => {
              this.navController.navigateForward(data.url);
            });
          }
        });
      }
    });
  }
}
