import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';
import { RequestService } from 'src/app/core/services/request.service';

@Component({
  selector: 'app-application-type',
  templateUrl: './application-type.component.html',
  styleUrls: ['./application-type.component.scss'],
})
export class ApplicationTypeComponent implements OnInit {
  type = ['주거공간', '상업공간'];
  selectedType: string;

  constructor(
    private navController: NavController,
    private requestService: RequestService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // 저장된 데이터가 있다면 불러오기
    const reservation = this.requestService.request;
    if (reservation.buildingType) {
      this.selectedType = reservation.buildingType;
    }
  }

  //다음 - 건물타입
  goBulidingType() {
    const reservation = this.requestService.request;
    reservation.buildingType = this.selectedType;

    const topId = this.activatedRoute.snapshot.queryParams['topId'];
    const subId = this.activatedRoute.snapshot.queryParams['subId'];
    const requestType = this.activatedRoute.snapshot.queryParams['requestType'];
    const expertUuid = this.activatedRoute.snapshot.queryParams['expertUuid'];
    this.navController.navigateForward('/application-date', {
      queryParams: {
        topId,
        subId,
        requestType,
        expertUuid,
      },
    });
  }

  //이전
  goBack() {
    const reservation = this.requestService.request;
    reservation.buildingType = this.selectedType;
    this.navController.pop();
  }
}
