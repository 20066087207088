import { Injectable } from '@angular/core';
import naver from 'naver-id-login';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() {}

  // 랜덤 ID 생성
  generateFilename() {
    var length = 10;
    var text = '';
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text + new Date().valueOf();
  }

  async getNaverProfile(token: string) {
    return await naver.getProfile(token);
  }
}
