import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { increment } from 'firebase/firestore';
import {
  Subscription,
  catchError,
  combineLatest,
  forkJoin,
  map,
  of,
} from 'rxjs';
import { Chat } from 'src/app/core/models/chat.model';
import { Member } from 'src/app/core/models/member.model';
import { Post } from 'src/app/core/models/post.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { DbService } from 'src/app/core/services/db.service';
import { MemberService } from 'src/app/core/services/member.service';

@Component({
  selector: 'app-my-like',
  templateUrl: './my-like.component.html',
  styleUrls: ['./my-like.component.scss'],
})
export class MyLikeComponent implements OnInit {
  topLimit = 10;
  member: Member;
  posts: Post[] | any;
  chats: Chat[] | any;
  filteredChats: Chat[] | any;
  chatSubscription: Subscription[] = [];
  experts: Member[] | any;

  segmentValue = 'post';

  constructor(
    private navController: NavController,
    private memberService: MemberService,
    private auth: AuthService,
    private db: DbService,
    private chatService: ChatService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
  }

  async ionViewWillEnter() {
    await this.getData();
  }

  // 페이지 벗어날때 구독해제
  async ionViewWillLeave() {
    this.chatSubscription.forEach((subscription) => subscription.unsubscribe());
  }

  async ngOnDestroy(): Promise<void> {
    this.chatSubscription.forEach((subscription) => subscription.unsubscribe());
  }

  // 좋아요 누른 데이터 불러오기
  async getData() {
    const myLikes = await this.memberService.myLikePosts();

    const post = myLikes.filter(
      (data) => data.type === 'post' && data?.content?.uuid?.status != 'exit'
    );
    this.posts = post.filter((data) => data.content);
    this.experts = myLikes.filter((data) => data.type === 'expert');
    this.filteredChats = myLikes.filter((data) => data.type === 'chat');
    this.getChats(this.filteredChats);
  }

  // 채팅 데이터 불러오기
  getChats(filteredChats: any[]) {
    const chatObservables = filteredChats.map((tmp) =>
      this.chatService.getChatList(tmp.contentId).pipe(
        catchError((error) => {
          console.error('에러가 발생했습니다! : ', error);
          return of([]);
        })
      )
    );

    const sub = combineLatest(chatObservables)
      .pipe(
        map((results) => results.flat()) // 결과 배열 평탄화
      )
      .subscribe(
        (chats: Chat[]) => {
          this.chats = chats;
        },
        (error) => {
          console.error('에러가 발생했습니다! : ', error);
        }
      );
    this.chatSubscription.push(sub);
  }

  //게시글 상세로
  async goPostDetail(postId: Post['id']) {
    // 조회수 증가
    await this.db.updateAt(`posts/${postId}`, {
      viewCount: increment(1),
    });
    this.navController.navigateForward('/post-detail', {
      queryParams: { postId },
    });
  }

  //전문가 상세
  goExpertDetail(expertUuid: Member['uuid']) {
    this.navController.navigateForward('/expert-detail', {
      queryParams: {
        expertUuid,
        type: 'myLike',
      },
    });
  }

  //채팅 상세
  goChatDetail() {
    this.navController.navigateForward('/expert-detail');
  }

  // 인피니티 스크롤
  loadData(ev) {
    setTimeout(() => {
      this.topLimit += 10;
      ev.target.complete();
    }, 500);
  }
}
