import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-expert-qna-list',
  templateUrl: './expert-qna-list.component.html',
  styleUrls: ['./expert-qna-list.component.scss'],
})
export class ExpertQnaListComponent implements OnInit {
  member: Member;
  qnaLists: Array<{ question: string; answer: string }> = [];

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private auth: AuthService,
    private db: DbService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    if (this.member.qnaLists) {
      this.qnaLists = this.member.qnaLists;
    }
  }

  //뒤로 얼럿
  closeModal() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.modalController.dismiss();
        }
      });
  }

  // 항목 추가하기
  add() {
    this.qnaLists.push({ question: '', answer: '' });
  }

  //  항목 삭제
  remove(index: number) {
    this.qnaLists.splice(index, 1);
  }

  //저장
  async save() {
    // 빈값인 Q&A 항목 삭제
    this.qnaLists = this.qnaLists.filter(
      (item) => item.question.trim() !== '' || item.answer.trim() !== ''
    );
    await this.db.updateAt(`members/${this.member.uuid}`, {
      qnaLists: this.qnaLists,
    });
    this.modalController.dismiss({
      qnaLists: this.qnaLists,
    });
    this.alertService.presentToast('궁금해요 Q&A를 업데이트 하였습니다.');
  }
}
