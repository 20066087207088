import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMP } from 'iamport-capacitor';
import { RequestPayParams, RequestPayResponse } from 'iamport-typings';
import { catchError, from, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IamportService {
  imp: any;
  initSwitch = true;
  constructor(private http: HttpClient) {
    if (!this.initSwitch) {
      this.imp = new IMP();
    }
  }

  /**
   * 결제 함수
   * @param amount 거래 금액
   * @param buyer_name 주문자 이름
   * @param buyer_tel 주문자 전화번호
   * @param buyer_email 주문자 이메일
   * @param type 전문가-포인트 결제 & 일반유저-보험료 결제
   * @returns
   */
  async payment(
    amount: number,
    buyer_name: string,
    buyer_tel: string,
    buyer_email: string,
    type: 'expert' | 'member',
    params?: { key: string; value: string }
  ) {
    return new Promise(async (resolve, reject) => {
      const { IMP } = window;
      IMP.init('imp30003650');

      let url =
        type === 'expert'
          ? 'https://banseliin.com/point-charge'
          : 'https://banseliin.com/insurance-payment';
      if (params) {
        url + '?' + params.key + '=' + params.value;
      }
      const data: RequestPayParams = {
        pg: 'nice',
        pay_method: 'card',
        merchant_uid: `mid_${new Date().getTime()}`,
        name: type === 'expert' ? '포인트 결제' : '보험료 결제',
        buyer_email: buyer_email,
        buyer_name: buyer_name,
        buyer_tel: buyer_tel,
        m_redirect_url: url,
        amount: amount,
        app_scheme: 'bansellin',
      };

      IMP.request_pay(data, (rsp: RequestPayResponse) => {
        if (rsp.success) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  }

  // 토큰 받아오기
  getToken() {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          'https://us-central1-banseliin.cloudfunctions.net/iamportGetToken',
          {},
          {}
        )
        .toPromise()
        .then((result: any) => {
          if (result?.code === 0) {
            resolve(result.response.access_token);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
        });
    });
  }

  /**
   * 빌링키 조회
   * @param custmoerUid 빌링키 Id
   * @returns
   */
  checkBillingKey(custmoerUid: string): Observable<any> {
    console.log('넘어온custmoerUid', custmoerUid);

    return from(
      this.http
        .post(
          'https://us-central1-banseliin.cloudfunctions.net/iamportGetToken',
          {},
          {}
        )
        .toPromise()
    ).pipe(
      switchMap((result: any) => {
        console.log('빌링키result', result);

        return this.http
          .post(
            'https://us-central1-banseliin.cloudfunctions.net/getBillingKey',
            {
              customer_uid: custmoerUid,
              accessToken: result.response.access_token,
            }
          )
          .toPromise();
      }),
      switchMap((data: any) => {
        console.log('빌링키data', data);

        if (data.code === 0) {
          return Observable.create((observer) => {
            observer.next(data.response);
            observer.complete();
          });
        } else {
          return false;
        }
      }),
      catchError((err) => {
        return '';
      })
    );
  }
}
