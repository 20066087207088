// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-add-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.5938rem;
  margin-top: 1.25rem;
}

.img-wrap-cost {
  width: calc(33.3333333333vw - 1rem - 0.125rem);
  min-width: calc(33.3333333333vw - 1rem - 0.125rem);
  height: calc(33.3333333333vw - 1rem - 0.125rem);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.img-wrap-cost img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-wrap-cost .del-pic-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  --background: url('del-img-icon.png') no-repeat center /
    100% 100%;
}

.set {
  margin-bottom: 2.5rem;
}

.content {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale750);
}

ion-footer {
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);
}

.edit-btn {
  width: 2.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--main);
  --color: var(--main);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/master/my-estimate-detail/my-estimate-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;EACA,cAAA;EACA,mBAAA;AACF;;AACA;EACE,8CAAA;EACA,kDAAA;EACA,+CAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AAEF;AADE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAGJ;AAAE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;EACA;aAAA;AAGJ;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AACF;;AAEA;EACE,4CAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,oBAAA;AACF","sourcesContent":[".img-add-wrap {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: flex-start;\n  gap: 0.5938rem;\n  margin-top: 1.25rem;\n}\n.img-wrap-cost {\n  width: calc(100vw / 3 - 1rem - 0.125rem);\n  min-width: calc(100vw / 3 - 1rem - 0.125rem);\n  height: calc(100vw / 3 - 1rem - 0.125rem);\n  border-radius: 4px;\n  overflow: hidden;\n  position: relative;\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n\n  .del-pic-btn {\n    position: absolute;\n    top: 0.5rem;\n    right: 0.5rem;\n    width: 1.5rem;\n    height: 1.5rem;\n    border-radius: 50%;\n    --background: url(../../../../assets/imgs/del-img-icon.png) no-repeat center /\n      100% 100%;\n  }\n}\n\n.set {\n  margin-bottom: 2.5rem;\n}\n\n.content {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  font-weight: 400;\n  color: var(--gray-scale750);\n}\n\nion-footer {\n  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);\n}\n\n.edit-btn {\n  width: 2.75rem;\n  font-size: 1rem;\n  line-height: 1.5rem;\n  font-weight: 700;\n  color: var(--main);\n  --color: var(--main);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
