import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';
import { ExpertService } from 'src/app/core/services/expert.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MemberService } from 'src/app/core/services/member.service';
import { TimerService } from 'src/app/core/services/timer.service';

@Component({
  selector: 'app-join-expert-add-info',
  templateUrl: './join-expert-add-info.component.html',
  styleUrls: ['./join-expert-add-info.component.scss'],
})
export class JoinExpertAddInfoComponent implements OnInit {
  gender = ['남자', '여자'];
  selectedGender: string; // 선택한 성별

  // 휴대폰 인증
  confirmNum; // 인증번호
  codeNumber: string = ''; // 휴대폰 인증번호
  sendCodeNumber: boolean = false; // 휴대폰 인증번호 발송을 눌렀는지 체크처리
  isConfirmed: boolean = false; // 휴대폰 인증번호 확인 완료

  memberForm = new FormGroup({
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(11),
      Validators.pattern(/^010\d{3,4}\d{4}$/),
    ]),

    number: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]),
  });

  constructor(
    private alertService: AlertService,
    private navController: NavController,
    public expertService: ExpertService,
    private loadingService: LoadingService,
    private memberService: MemberService,
    private timerService: TimerService,
    private http: HttpClient,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const data = this.expertService.getSelectedServices();

    this.selectedGender = data.gender;
    this.memberForm.get('phone').setValue(data.phone);
    this.isConfirmed = data.isConfirmed;
    this.codeNumber = data.codeNumber;

    if (this.isConfirmed) {
      this.confirmNum = this.codeNumber;
      this.expertService.timerStr = '03:00';
    }
    this.sendCodeNumber = !!this.codeNumber;
  }

  ngOnDestroy() {
    this.expertService.setSelectedServices({
      gender: this.selectedGender,
      phone: this.memberForm.get('phone').value,
      isConfirmed: this.isConfirmed,
      timerStr: !this.isConfirmed ? this.expertService.timerStr : '03:00',
      codeNumber: this.codeNumber,
      sendCodeNumber: this.sendCodeNumber,
    });
  }

  // 인증번호 발송
  async authenticate() {
    await this.loadingService.load();
    this.initAuthenticate();
    this.loadingService.hide();
  }

  // 인증번호 재발송
  async reauthenticate(): Promise<boolean | void> {
    if (this.expertService.timerStr !== '00:00') {
      this.reSendAlert();
      return false;
    }
    this.initAuthenticate();
  }

  // 휴대폰 인증번호 확인
  async sendNumberCheck() {
    const member = await this.memberService.phoneCheck(
      this.memberForm.get('phone').value
    );

    return new Promise(async (resolve) => {
      if (this.codeNumber) {
        await this.loadingService.load();
        if (this.codeNumber === this.confirmNum) {
          this.loadingService.hide();
          this.timerService.stop();
          this.expertService.timerStr = '03:00';
          this.sendCodeNumber = true;
          this.isConfirmed = true;
          this.expertService.setSelectedServices({
            isConfirmed: true,
          });
          resolve(true);
        } else {
          this.loadingService.hide();
          this.isConfirmed = false;
          this.confirmNum = '';
          this.expertService.timerStr = '03:00';
          this.sendCodeNumber = false;
          await this.alertService.okBtn(
            '잘못된 인증번호입니다. 확인 후 다시 입력해주세요.'
          );
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  }

  // 인증번호 발송 시간이 지나지 않은 경우에 뜨는 alert
  reSendAlert() {
    this.alertService.okBtn('유효시간 이후 재발송이 가능합니다.');
  }

  // 인증 번호 발송을 시작하는 함수
  async initAuthenticate() {
    this.timerService.stop();
    this.expertService.timerStr = '03:00';
    this.getCodeNumber();

    this.loadingService.hide();
    this.sendCodeNumber = true;
    this.timerStart();
    this.alertService.presentToast('인증번호가 발송되었어요.');
  }

  // 네이버 센스에서 보내는 인증번호를 받기
  getCodeNumber() {
    this.http
      .post('https://us-central1-banseliin.cloudfunctions.net/phoneAuthApp', {
        phone: this.memberForm.get('phone').value,
      })
      .subscribe((e) => {
        const condeNumber = e;
        this.codeNumber = condeNumber.toString();
        this.expertService.setSelectedServices({
          codeNumber: this.codeNumber,
        }); // 인증 번호 저장
      });
  }

  // 타이머
  async timerStart(remainingSeconds: number = 180): Promise<void> {
    this.timerService
      .countdown(Math.ceil(remainingSeconds / 60)) // 타이머의 남은 분을 전달
      .pipe()
      .subscribe((next: any) => {
        this.expertService.timerStr = next.display;

        if (next.minutes === 0 && next.seconds === 0 && !this.isConfirmed) {
          this.timeNumber();
        }
      });
  }

  // 입력시간 경과
  timeNumber() {
    this.alertService.okBtn(
      '입력 가능 시간이 경과되었어요.\n 인증번호를 다시 발송해주세요.'
    );
  }

  // [다음]
  goAddInfo() {
    this.expertService.timerStr = '03:00';
    this.expertService.setSelectedServices({
      gender: this.selectedGender,
      phone: this.memberForm.get('phone').value,
      isConfirmed: this.isConfirmed,
      timerStr: !this.isConfirmed ? this.expertService.timerStr : '03:00',
      codeNumber: this.codeNumber,
      sendCodeNumber: this.sendCodeNumber,
    });

    this.navController.navigateForward('/join-expert-add-info02', {
      queryParams: {
        type: 'join',
      },
    });
  }

  // 이전
  goBack() {
    this.navController.pop();
  }
}
