import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-auto-recharge-setting',
  templateUrl: './auto-recharge-setting.component.html',
  styleUrls: ['./auto-recharge-setting.component.scss'],
})
export class AutoRechargeSettingComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private navController: NavController
  ) {}

  ngOnInit() {}

  //금액 변경하기: 자동 충전 변경으로 이동
  changePrice() {
    this.navController.navigateForward('/automatic-charging-change');
  }

  //결제카드 수정: 결제 카드 수정으로 이동
  changeCard() {
    this.navController.navigateForward('/payment-regist');
  }

  //자동 충전 해지: 포인트 충전으로 이동
  goCancel() {
    this.alertService
      .cancelOkBtn(
        '자동 충전을 해지하시겠습니까? \n해지 시 입력한 정보가 \n초기화 됩니다.'
      )
      .then((ok) => {
        if (ok) {
          this.navController.navigateForward('/point-charge');
          this.alertService.presentToast('자동 충전을 해지하였습니다.');
        }
      });
  }
}
