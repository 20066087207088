import { Component, Input, OnInit } from '@angular/core';
import { Member } from 'src/app/core/models/member.model';

@Component({
  selector: 'app-grade-card',
  templateUrl: './grade-card.component.html',
  styleUrls: ['./grade-card.component.scss'],
})
export class GradeCardComponent implements OnInit {
  @Input() item: Member | any;
  @Input() type: 'myLike' | 'medal' | 'estimate';
  @Input() filterOptionType?: 'recommend' | 'basic'; // 전문가찾기에서 진입시 & 'recommend' => 업체명, 'basic' => 활동명
  @Input() index?: number; // type === 'medal'인경우
  numbers: number[] = Array.from({ length: 10 }, (_, i) => i + 1);

  constructor() {}

  ngOnInit() {}
}
