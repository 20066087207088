import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AddressService } from 'src/app/core/services/address.service';

@Component({
  selector: 'app-select-region',
  templateUrl: './select-region.component.html',
  styleUrls: ['./select-region.component.scss'],
})
export class SelectRegionComponent implements OnInit {
  siArr = []; // 지역 '시' 배열
  guArr = []; // 지역 '구' 배열
  selectedSi: string = ''; // 선택한 지역 '시'
  selectedGu: string = ''; // 선택한 지역 '구'
  area = { si: '', gu: '', isDelete: false };

  constructor(
    private navController: NavController,
    private addressService: AddressService
  ) {}

  ngOnInit() {
    this.area = { si: '', gu: '', isDelete: false };
    this.selectedSi = '서울';
    this.siArr = this.addressService.getSi();
    this.guArr = this.addressService.getGu(this.selectedSi);
  }

  // '시' 선택
  setSelectSi(item) {
    this.selectedSi = item.detail.value;
    this.selectedGu = '';
    this.guArr = this.addressService.getGu(this.selectedSi);
  }

  // '구' 선택
  setSelectGu() {
    if (this.selectedSi === '전국' && this.selectedGu === '전체') {
      this.area = { si: 'all', gu: 'all', isDelete: false };
    } else if (this.selectedSi != '전국' && this.selectedGu === '전체') {
      this.area = { si: this.selectedSi, gu: 'all', isDelete: false };
    } else {
      this.area = { si: this.selectedSi, gu: this.selectedGu, isDelete: false };
    }

    const nationwide = this.selectedSi === '전국' && this.selectedGu === '전체';
    this.navController.navigateBack('/join-expert-address', {
      queryParams: { area: this.area, nationwide },
    });
  }
}
