import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';
import { RequestService } from 'src/app/core/services/request.service';
import { postcode } from 'src/assets/js/postcode.js';
@Component({
  selector: 'app-application-address',
  templateUrl: './application-address.component.html',
  styleUrls: ['./application-address.component.scss'],
})
export class ApplicationAddressComponent implements OnInit {
  //다음 api
  @ViewChild('address_pop', { read: ElementRef, static: true })
  popup!: ElementRef;
  addressForm = new FormGroup({
    address: new FormControl('', [Validators.required]),
    detailAddress: new FormControl('', [Validators.required]),
  });
  area = { si: '', gu: '' };

  constructor(
    private renderer: Renderer2,
    private navController: NavController,
    private alertService: AlertService,
    private requestService: RequestService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // 저장된 데이터가 있다면 불러오기
    const reservation = this.requestService.request;
    if (reservation.address && reservation.addressDetail) {
      this.addressForm.get('address').setValue(reservation.address);
      this.addressForm.get('detailAddress').setValue(reservation.addressDetail);
      this.area.si = reservation.addressSiGu.si;
      this.area.gu = reservation.addressSiGu.gu;
    }
  }

  //주소찾기 api 열려라
  openDaumPopup() {
    setTimeout(() => {
      this.getAddress().then((data) => {
        console.log('datas', data);

        this.area.si = data.sido;
        this.area.gu = data.sigungu;
        this.addressForm.get('address').setValue(data.address);
      });
    }, 1000);
  }

  getAddress(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      postcode(this.renderer, this.popup.nativeElement, (data) => {
        resolve(data);
      });
    });
  }

  //주소팝업 닫기
  closeAddressPopup() {
    this.renderer.setStyle(this.popup.nativeElement, 'display', 'none');
  }

  //다음 - 건물타입
  goBulidingType() {
    const reservation = this.requestService.request;
    reservation.address = this.addressForm.get('address').value;
    reservation.addressDetail =
      this.addressForm.get('detailAddress').value || '';
    reservation.addressSiGu.si = this.area.si;
    reservation.addressSiGu.gu = this.area.gu;

    const topId = this.activatedRoute.snapshot.queryParams['topId'];
    const subId = this.activatedRoute.snapshot.queryParams['subId'];
    const requestType = this.activatedRoute.snapshot.queryParams['requestType'];
    const expertUuid = this.activatedRoute.snapshot.queryParams['expertUuid'];
    this.navController.navigateForward('/application-type', {
      queryParams: {
        topId,
        subId,
        requestType,
        expertUuid,
      },
    });
  }

  //이전
  goBack() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.requestService.clearData();
          this.navController.pop();
        }
      });
  }
}
