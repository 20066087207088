import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { Notice } from 'src/app/core/models/notice.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-notice-list',
  templateUrl: './notice-list.component.html',
  styleUrls: ['./notice-list.component.scss'],
})
export class NoticeListComponent implements OnInit {
  topLimit = 10;
  member: Member;
  notices: Notice;

  constructor(
    private navController: NavController,
    private db: DbService,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    await this.getNotices();
  }

  // 공지사항 데이터 불러오기
  async getNotices() {
    const type = this.member.isExpert ? 'expert' : 'member';
    this.notices = await this.db.toCollection$('notices', (ref) =>
      ref.where('type', '==', type).orderBy('dateCreated', 'desc')
    );
  }

  // 공지 상세로 이동
  goNoticeDetail(id: Notice['id']) {
    this.navController.navigateForward('/notice-detail', {
      queryParams: {
        id,
      },
    });
  }

  // 인피니티 스크롤
  loadData(ev) {
    setTimeout(() => {
      this.topLimit += 10;
      ev.target.complete();
    }, 500);
  }
}
