import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ExpertService } from 'src/app/core/services/expert.service';
import { ImageService } from 'src/app/core/services/image.service';

@Component({
  selector: 'app-portfolio-add',
  templateUrl: './portfolio-add.component.html',
  styleUrls: ['./portfolio-add.component.scss'],
})
export class PortfolioAddComponent implements OnInit {
  images: string[] = Array(6).fill(null);
  emptyImages: string[];
  item;
  idx: number;

  memberForm = new FormGroup({
    titletext: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(20),
    ]),
    detail: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(100),
    ]),
  });

  constructor(
    private alertService: AlertService,
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private imgService: ImageService,
    private expertService: ExpertService,
    private commonService: CommonService
  ) {
    const itemParam = this.activatedRoute.snapshot.queryParams.item;
    if (itemParam) {
      this.item = JSON.parse(itemParam);
    }
    this.idx = this.activatedRoute.snapshot.queryParams.idx;
  }

  ngOnInit() {
    if (this.item) {
      this.memberForm.setValue({
        titletext: this.item.title,
        detail: this.item.content,
      });
      this.images = this.item.images
        ? this.item.images.slice(0, 6)
        : Array(6).fill(null);
    }
    if (this.images.length < 6) {
      this.images = [
        ...this.images,
        ...Array(6 - this.images.length).fill(null),
      ];
    }
    this.emptyImages = this.images.filter(
      (img) => img != null && img.trim() !== ''
    );
  }

  //뒤로 얼럿
  backAlert() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.navController.pop();
        }
      });
  }

  // 이미지 추가
  async addImg(index: number) {
    const imgUrl = await this.imgService.selectCamera('portfolio');
    this.images[index] = imgUrl;

    this.emptyImages = this.images.filter(
      (img) => img != null && img.trim() !== ''
    );
  }

  // 이미지 삭제
  removeImg(index: number) {
    this.images[index] = null;
    this.emptyImages = this.images.filter(
      (img) => img != null && img.trim() !== ''
    );
  }

  //저장
  async save() {
    this.alertService
      .cancelOkBtn(`포트폴리오를 ${this.item ? '수정' : '추가'}하시겠습니까?`)
      .then((ok) => {
        if (ok) {
          const portfolio = {
            id: this.item
              ? this.item.id
              : this.commonService.generateFilename(),
            title: this.memberForm.get('titletext').value,
            content: this.memberForm.get('detail').value,
            images: this.emptyImages,
          };

          let updatedPortfolio = this.expertService.getPortfolio();
          if (this.item) {
            const index = updatedPortfolio.findIndex(
              (item) => item.id === this.item.id
            );
            if (index !== -1) {
              // 포트폴리오 업데이트
              updatedPortfolio[index] = portfolio;
              this.expertService.setPortfolioList(updatedPortfolio);
            }
          } else {
            // 새 포트폴리오 추가
            updatedPortfolio.push(portfolio);
            this.expertService.setPortfolioList(updatedPortfolio);
          }

          this.navController.pop();
          this.alertService.presentToast(
            `포트폴리오를 ${this.item ? '수정' : '추가'} 하였습니다.`
          );
        }
      });
  }

  // 비활성화 조건
  disabled() {
    if (
      !this.memberForm.get('titletext').value ||
      !this.memberForm.get('detail').value ||
      this.memberForm.get('detail').hasError('minlength') ||
      this.memberForm.get('titletext').hasError('minlength') ||
      !this.emptyImages ||
      this.emptyImages?.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  }
}
