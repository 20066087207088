import { Injectable, inject } from '@angular/core';
import { AlertService } from './alert.service';
import { registerPlugin } from '@capacitor/core';
import { DbService } from './db.service';
import { NavController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { signInWithCustomToken } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import { LoadingService } from './loading.service';
import { PushService } from './push.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import firebase from 'firebase/compat/app';
import { CommonService } from './common.service';
import { Member } from '../models/member.model';

declare let Kakao: any;
declare let naver: any;

const NAVER_CLIENT_Id = 'LfBw420HpJ2MoB60I0XL';
const KAKAO_REST_API_KEY = 'ef78b6dcc03230e7ef4fec5f36a9d29f';
const KAKAO_JAVASCRIPT_KEY = 'd7656b13b7430d90f1a86f62e367a735';
const KAKAO_REDIRECT_URIS = {
  'https://banseliin.com': 'https://banseliin.com/login',
  'https://www.banseliin.com': 'https://www.banseliin.com/login',
  'https://banseliin-mobile.web.app': 'https://banseliin-mobile.web.app/login',
};
const currentOrigin = window.location.origin;
const REDIRECT_URI =
  KAKAO_REDIRECT_URIS[currentOrigin] ||
  KAKAO_REDIRECT_URIS['https://banseliin.com'];
export interface SocialLoginPlugin {
  kakaoLogin(): Promise<any>;
  naverLogin(): Promise<any>;
  googleLogin(): Promise<any>;
  appleLogin(): Promise<any>;
}
const SocialLoginPlugin =
  registerPlugin<SocialLoginPlugin>('SocialLoginPlugin');

@Injectable({
  providedIn: 'root',
})
export class SocialService {
  socialAuthService = inject(SocialAuthService);
  data = inject(CommonService);
  constructor(
    private alertService: AlertService,
    private db: DbService,
    private navController: NavController,
    private auth: AuthService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private pushService: PushService
  ) {
    Kakao.init(KAKAO_JAVASCRIPT_KEY); // 카카오 초기화
    this.initAppleEventListener(); // 애플 초기화
    const code = new URL(window.location.href).searchParams.get('code'); // 인가코드

    if (code) {
      this.getKakaoToken(code);
    }
    const isAccessToken = window.location.href.includes('access_token'); // 네이버 access_token
    if (isAccessToken) {
      this.getNaverProfile();
    }
  }

  ngOnInit() {
    this.socialAuthService.authState.subscribe((user) => {});
  }

  // 구글로그인(앱)
  async googleLogin() {
    try {
      let result: any = await SocialLoginPlugin.googleLogin();

      this.userSocialEmailCheck(result.email, 'google');
    } catch (error) {
      console.log('error: ', JSON.stringify(error));
    }
  }

  // 애플로그인(앱)
  async appleLogin() {
    try {
      let result: any = await SocialLoginPlugin.appleLogin();

      // apple의 경우 이름 정보를 계속 주지 않아서 session 저장
      if (result.name) {
        sessionStorage.setItem('appleName', result.name);
      }
      this.userSocialEmailCheck(result.email, 'apple');
    } catch (error) {
      console.log('error: ', JSON.stringify(error));
    }
  }

  // 카카오로그인(앱)
  async kakaoLogin() {
    try {
      let result: any = await SocialLoginPlugin.kakaoLogin();
      this.userSocialEmailCheck(result.email, 'kakao');
    } catch (error) {
      console.log('kakao Login Error :: ', error);
    }
  }

  // 네이버로그인(앱)
  async naverLogin() {
    try {
      let result: any = await SocialLoginPlugin.naverLogin();
      this.userSocialEmailCheck(result.email, 'naver');
    } catch (error) {
      console.log('naver Login Error :: ', error);
    }
  }

  // 네이버로그인(웹)
  async webNaverLogin() {
    this.naverInit();
    this.naverConfig.authorize();
  }

  naverConfig: any;
  naverInit() {
    this.naverConfig = new naver.LoginWithNaverId({
      clientId: NAVER_CLIENT_Id,
      callbackUrl: REDIRECT_URI,
      isPopup: true,
      callbackHandle: true,
    });
    this.naverConfig.init();
  }

  // 네이버 로그인한 회원 정보 가져오기
  async getNaverProfile() {
    const ACCESS_TOKEN = window.location.hash.split('&')[0].split('=')[1];
    const profile = await this.data.getNaverProfile(ACCESS_TOKEN);
    if (profile.message === 'success') {
      this.userSocialEmailCheck(profile.response.email, 'naver');
    }
  }

  // 카카오 로그인(웹)
  webKakaoLogin() {
    if (!Kakao.isInitialized()) {
      Kakao.init(KAKAO_JAVASCRIPT_KEY);
    }

    Kakao.Auth.authorize({
      redirectUri: REDIRECT_URI,
    });
  }

  // 애플 로그인(웹)
  webAppleLogin() {
    const apple = document.getElementById('appleid-signin');
    apple?.click();
  }

  // Apple Credential 생성
  getAppleCredential(idToken: string) {
    return new firebase.auth.OAuthProvider('apple.com').credential(idToken);
  }

  // Apple EventListener 초기화 설정 (웹)
  initAppleEventListener() {
    // 애플 로그인 성공시
    document.addEventListener('AppleIDSignInOnSuccess', async (event: any) => {
      const idToken = event.detail.authorization.id_token;
      const splitToken = idToken.split('.');
      const payloadValue = splitToken[1];
      const payload = window.atob(payloadValue);
      const payloadParse = JSON.parse(payload);
      const email = payloadParse.email;
      if (email) {
        this.userSocialEmailCheck(email, 'apple');
      }
    });
  }

  /**
   * 카카오 토큰 받기
   * @param code 인가 코드
   */
  async getKakaoToken(code: string) {
    const response = await fetch(
      `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${KAKAO_REST_API_KEY}&redirect_uri=${REDIRECT_URI}&code=${code}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      }
    );
    const res = await response.json();

    if (res) {
      await this.loadingService.load();

      Kakao.Auth.setAccessToken(res.access_token); // 토큰 저장
      // 유저 정보 저장
      Kakao.Auth.getStatusInfo()
        .then((res) => {
          if (res.status === 'connected') {
            this.userSocialEmailCheck(res.user.kakao_account.email, 'kakao');
          }
        })
        .catch((err) => {
          this.loadingService.hide();
          Kakao.Auth.setAccessToken(null);
        });
    }
  }

  decodeJwt(jwtToken) {
    const tokenParts = jwtToken.split('.');

    // 각 부분을 Base64 디코딩
    const payload = JSON.parse(atob(tokenParts[1]));
    return payload;
  }

  // 구글 로그인(웹)
  async webGoogleLogin(email: Member['email']) {
    try {
      if (email) {
        this.userSocialEmailCheck(email, 'google');
      }
    } catch (error) {
      console.error('Google error', error);
    }
  }

  async userSocialEmailCheck(email, socialType) {
    await this.loadingService.load();
    let user: any = await this.db.toCollection$(`members`, (ref) =>
      ref.where('email', '==', email).where('status', '!=', 'exit')
    );

    // 1 소셜유저 회원가입(추가 정보 작성)으로 이동
    if (user.length < 1) {
      // 회원가입 페이지로 이동하여 진행하기
      // 회원가입 페이지로 social의 타입과 email을 넘겨 줘야한다.
      this.loadingService.hide();
      this.navController.navigateForward(['/join-name'], {
        queryParams: {
          loginType: socialType,
          email,
        },
      });
    } else {
      let findUser = user[0];

      // 이용정지 유저
      if (findUser.status == 'suspend') {
        this.loadingService.hide();
        this.navController.navigateForward('suspension', {
          queryParams: {
            social: findUser,
          },
        });
        return;
      }

      // 2. 이미 존재하는 유저인데 소셜로 회원가입이 되어있는지 확인
      let findSocial = findUser.loginType.filter((x) =>
        ['kakao', 'apple', 'google', 'naver'].includes(x)
      );

      //2-1. 이미 존재하는 유저인데 이메일로만 등록이 되어있네요 :)
      if (findSocial.length < 1) {
        this.loadingService.hide();
        // 2-2. 연동할래?
        await this.askConnect(email, socialType);
      } else {
        // 2-3. 해당 소셜 유저이네요 :)

        if (findSocial.includes(socialType)) {
          this.http
            .post(
              'https://us-central1-banseliin.cloudfunctions.net/socialFunctions/socialLogin',
              {
                type: socialType,
                uid: findUser?.uuid,
              }
            )
            .subscribe(async (customTokenData: any) => {
              // 푸시 넣기
              const pushId = await this.pushService.getId();
              if (pushId && pushId.userId) {
                // 다른계정에 나의 푸시아이디가 들어가 있는경우 빼기
                const other = await this.db.toCollection$(`members`, (ref) =>
                  ref.where('pushId', 'array-contains', pushId.userId)
                );
                if (other) {
                  const promise = other.map((e) => {
                    if (e.uid != findUser?.uuid) {
                      this.db.updateAt(`members/${e.uuid}`, {
                        pushId: arrayRemove(pushId.userId),
                      });
                    }
                  });
                  await Promise.all(promise);
                }

                await this.db.updateAt(`members/${findUser?.uuid}`, {
                  pushId: arrayUnion(pushId.userId),
                });
              }

              signInWithCustomToken(
                this.auth.auth,
                customTokenData.firebase_token
              ).then(async () => {
                // 로그인 완료
                sessionStorage.removeItem('logoutUser');
                this.loadingService.hide();
                this.navController.navigateRoot(['/tabs/home'], {
                  animationDirection: 'forward',
                });
                this.alertService.presentToast('로그인 했어요.');
              });
            });

          // 2-3. 다른 소셜 유저네요 :)
        } else {
          this.loadingService.hide();
          this.alertService.okBtn(
            `${findSocial[0]}로그인 이력이 있습니다.\n ${findSocial[0]}로 로그인 해주세요.`,
            '',
            '확인'
          );
        }
      }
    }
  }

  /*
   * 연동여부 물어보기
   * 2-2 연동할래?
   */
  async askConnect(email, socialType) {
    this.alertService
      .okCancelBtn(
        '가입된 계정이 있습니다. 계정을 연동하시겠어요?',
        '',
        '확인',
        '취소'
      )
      .then((ok) => {
        if (ok) {
          this.connetAlert(email, socialType);
        }
      });
  }

  // 연동
  connetAlert(email, socialType) {
    this.alertService
      .inputPasswordAlert('비밀번호를 입력해 주세요.')
      .then((value) => {
        if (value === false) {
          return;
        }

        this.auth
          .loginUser({ email, password: value['0'] })
          .then(async (data) => {
            // 푸시 넣기
            const pushId = await this.pushService.getId();
            if (pushId && pushId.userId) {
              // 다른계정에 나의 푸시아이디가 들어가 있는경우 빼기
              const other = await this.db.toCollection$(`members`, (ref) =>
                ref.where('pushId', 'array-contains', pushId.userId)
              );

              if (other) {
                const promise = other.map((e) => {
                  if (e.uid != data.user?.uid) {
                    this.db.updateAt(`members/${e.uuid}`, {
                      pushId: arrayRemove(pushId.userId),
                    });
                  }
                });
                await Promise.all(promise);
              }

              await this.db.updateAt(`members/${data.user?.uid}`, {
                pushId: arrayUnion(pushId.userId),
                loginType: arrayUnion(socialType),
              });
            } else {
              // 웹 소셜로그인인 경우
              await this.db.updateAt(`members/${data.user?.uid}`, {
                loginType: arrayUnion(socialType),
              });
            }

            sessionStorage.removeItem('logoutUser');
            this.navController.navigateRoot(['/tabs/home'], {
              animationDirection: 'forward',
            });
            this.alertService.presentToast('로그인 했어요.');
          })
          .catch((err) => {
            if (err) {
              this.alertService
                .okBtn('비밀번호가 틀렸습니다. 다시 입력해 주세요.')
                .then(() => {
                  this.connetAlert(email, socialType);
                });
            }
          });
      });
  }

  // 소셜로그인 회원가입
  async socialSignup(type: any, email: any) {
    await this.loadingService.load();
    return new Promise((resolve) => {
      this.http
        .post(
          'https://us-central1-banseliin.cloudfunctions.net/socialFunctions/socialSignup',
          {
            type,
            email,
          }
        )
        .subscribe((customTokenData: any) => {
          signInWithCustomToken(
            this.auth.auth,
            customTokenData.firebase_token
          ).then((user) => {
            this.loadingService.hide();
            resolve(user);
          });
        });
    });
  }
}
