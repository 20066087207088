// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-close {
  width: 2.75rem;
  height: 2.75rem;
  --background: url('modal-close-icon.png') no-repeat
    center / 100% 100%;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale750);
}

.item-info {
  width: 100%;
  padding: 1.25rem 1rem;
}
.item-info h3 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--gray-scale900);
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/business-information-detail/business-information-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA;sBAAA;AAEF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AACF;;AAEA;EACE,WAAA;EACA,qBAAA;AACF;AAAE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,qBAAA;AAEJ","sourcesContent":[".modal-close {\n  width: 2.75rem;\n  height: 2.75rem;\n  --background: url(../../../../assets/imgs/modal-close-icon.png) no-repeat\n    center / 100% 100%;\n}\n\np {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  font-weight: 400;\n  color: var(--gray-scale750);\n}\n\n.item-info {\n  width: 100%;\n  padding: 1.25rem 1rem;\n  h3 {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 700;\n    color: var(--gray-scale900);\n    margin-bottom: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
