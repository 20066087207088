import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionSheetController, NavController } from '@ionic/angular';
import { Comment } from 'src/app/core/models/comment.model';
import { Member } from 'src/app/core/models/member.model';
import { Post } from 'src/app/core/models/post.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommunityService } from 'src/app/core/services/community.service';
import { DbService } from 'src/app/core/services/db.service';
import { ImageService } from 'src/app/core/services/image.service';
import Swiper, { Autoplay, Pagination } from 'swiper';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss'],
})
export class PostDetailComponent implements OnInit {
  member: Member;
  post: Post | any;
  postId: Post['id'];
  comments: Comment[];

  swiper: Swiper;

  //커뮤니티 하트
  heart: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private actionSheetController: ActionSheetController,
    private navController: NavController,
    private alertService: AlertService,
    public communityService: CommunityService,
    private auth: AuthService,
    private db: DbService,
    private imageService: ImageService
  ) {
    this.postId = this.activatedRoute.snapshot.queryParams.postId;
  }

  ngOnInit() {}

  async ionViewWillEnter() {
    this.member = await this.auth.getUser();
    await this.getPost();
    this.getComments();

    setTimeout(() => {
      if (this.post.datas?.length > 0) {
        this.swiperOptions();
      }
    }, 100);
  }

  // 게시물 상세 불러오기
  async getPost() {
    this.post = await this.communityService.getPostDetail(this.postId);
    this.post.datas.forEach((element) => {
      if (element.image.includes('mov') && !element.videoCheck) {
        element.videoCheck = true;
      }
    });
  }

  // 댓글 불러오기
  async getComments(ev?) {
    this.comments = await this.communityService.getComments(this.postId);
  }

  //댓글 쓰기
  async createComment() {
    this.communityService.comment.postId = this.postId;
    await this.communityService.createComments('comment', this.post.uuid.uuid);
    await this.getComments();
    this.communityService.comment.content = '';
    this.post.commentCount += 1;
  }

  //스와이퍼 옵션
  async swiperOptions() {
    this.swiper = new Swiper('.postDetaildSwiper', {
      modules: [Pagination, Autoplay],
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      spaceBetween: 10,

      on: {},
    });

    this.swiper.update();
  }

  // [...]
  more() {
    // 내가 쓴 경우
    if (this.member.uuid === this.post.uuid.uuid) {
      this.writeMeAction();
    } else {
      // 다른 사람이 쓴 경우
      this.writeYouAction();
    }
  }

  //내가 작성한 글인 경우
  async writeMeAction(comment?: Comment) {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: `게시물 수정`,
          handler: () => {
            this.navController.navigateForward('/post-write', {
              queryParams: { postId: this.postId },
            });
          },
        },
        {
          text: `게시물 삭제`,
          handler: () => {
            this.alertService
              .cancelOkBtn('게시물을 삭제하시겠어요?')
              .then(async (ok) => {
                if (ok) {
                  await this.db.updateAt(`posts/${this.postId}`, {
                    isDelete: true,
                  });
                  this.navController.pop();

                  this.alertService.presentToast(`게시물을 삭제했어요.`);
                }
              });
          },
        },
        {
          text: '취소',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });

    await actionSheet.present();
  }

  //내가 작성한 글이 아닌경우
  async writeYouAction() {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: `게시물 신고`,
          handler: () => {
            this.navController.navigateForward('/post-report', {
              queryParams: { type: 'post', postId: this.postId },
            });
          },
        },
        {
          text: '취소',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });

    await actionSheet.present();
  }

  // [좋아요]
  async like(post: Post) {
    if (!post['myLikeSwitch']) {
      await this.communityService.createLike(post.id);
      post.likeCount += 1;
      post['myLikeSwitch'] = true;
      this.heart = true;
    } else {
      await this.communityService.deleteLike(post.id);
      post.likeCount -= 1;
      post['myLikeSwitch'] = false;
    }
  }

  // 이미지디테일 모달
  imageDetail(type: 'profile' | 'data', idx: number) {
    if (type === 'data') {
      this.imageService.imageDetail(this.post.datas, 'data', idx);
    } else {
      if (this.post.uuid.profile) {
        this.imageService.imageDetail(this.post.uuid.profile, 'profile', idx);
      } else {
        return;
      }
    }
  }

  trackByFn(index) {
    return index;
  }
}
