// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.input-wrap .input-item {
  width: calc(100% - 1.25rem - 5.0625rem);
}
.input-wrap .line-btn {
  width: 5.0625rem;
  height: 2.5rem;
  border: 1px solid var(--main);
  border-radius: 6px;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--main);
  --color: var(--main);
}
.input-wrap .line-btn[disabled] {
  border: 1px solid #a4a9ad;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: #a4a9ad;
  --color: #a4a9ad;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/join-email/join-email.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AACF;AAAE;EACE,uCAAA;AAEJ;AACE;EACE,gBAAA;EACA,cAAA;EACA,6BAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,oBAAA;AACJ;AACI;EACE,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AACN","sourcesContent":[".input-wrap {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 1.25rem;\n  .input-item {\n    width: calc(100% - 1.25rem - 5.0625rem);\n  }\n\n  .line-btn {\n    width: 5.0625rem;\n    height: 2.5rem;\n    border: 1px solid var(--main);\n    border-radius: 6px;\n    font-size: 0.875rem;\n    line-height: 1.5rem;\n    font-weight: 700;\n    color: var(--main);\n    --color: var(--main);\n\n    &[disabled] {\n      border: 1px solid #a4a9ad;\n      font-size: 0.875rem;\n      line-height: 1.5rem;\n      font-weight: 700;\n      color: #a4a9ad;\n      --color: #a4a9ad;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
