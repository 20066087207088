import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { map, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { Notification } from 'src/app/core/models/notification.model';

@Component({
  selector: 'app-alarm-list',
  templateUrl: './alarm-list.component.html',
  styleUrls: ['./alarm-list.component.scss'],
})
export class AlarmListComponent implements OnInit {
  member: Member;
  notifications$: Observable<Notification[]>;
  subscription: Subscription;
  switch: boolean = false;
  ids = [];
  topLimit = 12;

  constructor(
    private navController: NavController,
    private alertService: AlertService,
    private auth: AuthService,
    private db: DbService
  ) {}

  ngOnInit() {}

  async ionViewWillEnter() {
    this.member = await this.auth.getUser();
    if (this.member) {
      this.loadNotifications();
    }
  }

  // 페이지 나갈때 구독해제
  ionViewWillLeave() {
    this.allReads();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.allReads();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // 탭에 뱃지 사라지게 하기위해서
  loadNotifications() {
    // this.notifications$ = this.db.collection$(`notifications`, (ref) =>
    //   ref.where('uuid', '==', this.member.uuid).orderBy('dateCreated', 'desc')
    // );
    this.notifications$ = this.db
      .collection$(`notifications`, (ref) =>
        ref.where('uuid', '==', this.member.uuid).orderBy('dateCreated', 'desc')
      )
      .pipe(
        map((notifications) => {
          if (this.member.isExpert) {
            // 전문가인 경우 필터링
            return notifications.filter(
              (n: any) =>
                n.title !== '전문가 승인' &&
                n.title !== '보험료 환불 승인' &&
                n.title !== '견적서 도착'
            );
          } else {
            // 회원인 경우 필터링
            return notifications.filter(
              (n: any) => n.title !== '견적서 요청' && n.title !== '리뷰 등록'
            );
          }
        })
      );

    this.subscription = this.notifications$.subscribe((data) => {
      this.ids = data.filter((e) => !e.checkSwitch).map((e) => e.id);

      if (this.ids.length && !this.switch) {
        this.allReads();
      }
    });
  }

  // 현재까지 온 모든 데이터를 읽음처리(탭용)
  async allReads() {
    if (!this.ids.length) {
      return;
    }

    this.switch = true;
    const promise = this.ids.map(async (id) => {
      await this.db.updateAt(`notifications/${id}`, {
        checkSwitch: true,
      });
    });
    await Promise.all(promise);
    this.switch = false;
  }

  //알림 상세
  async goDetailAlarm(item: Notification) {
    await this.db.updateAt(`notifications/${item.id}`, {
      readSwitch: true,
    });

    if (item.isAdminPush) {
      this.navController.navigateForward('/alarm-detail', {
        queryParams: {
          id: item.id,
        },
      });
    } else {
      this.navController.navigateForward(item.url);
    }
  }

  // [삭제]
  delete(id: Notification['id']) {
    this.alertService.cancelOkBtn('알림을 삭제하시겠어요?').then(async (ok) => {
      if (ok) {
        await this.db.delete(`notifications/${id}`);
        this.alertService.presentToast('삭제했습니다.');
      }
    });
  }

  // 인피니티 스크롤
  loadData(ev) {
    setTimeout(() => {
      this.topLimit += 12;
      ev.target.complete();
    }, 500);
  }
}
