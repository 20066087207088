import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { MemberService } from 'src/app/core/services/member.service';

@Component({
  selector: 'app-join-name',
  templateUrl: './join-name.component.html',
  styleUrls: ['./join-name.component.scss'],
})
export class JoinNameComponent implements OnInit {
  member: Member;
  memberForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(2)]),
  });

  appleName: string;

  constructor(
    private alertService: AlertService,
    private navController: NavController,
    private memberService: MemberService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.member = this.memberService.member;
    this.memberForm.get('name').setValue(this.member.name);
    // 소셜 회원가입일때
    let params = this.activatedRoute.snapshot.queryParams;

    if (params && params.loginType) {
      this.member.loginType = [params.loginType];
      this.member.email = params.email;
      if (params.loginType === 'apple') {
        this.appleName = sessionStorage.getItem('appleName');
        this.member.name = this.appleName || '';
        this.memberForm.get('name').setValue(this.appleName || '');
        this.appleName
          ? this.memberForm.get('name').disable()
          : this.memberForm.get('name').enable();
      }
    } else {
      this.member.loginType = ['email'];
    }
    console.log('memberForm.valid', this.memberForm.valid);
    console.log('memberForm.name.valid', this.memberForm.get('name').valid);
    console.log('member', this.member);
  }

  //뒤로 얼럿
  backAlert() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.memberService.clearData();
          localStorage.removeItem('confirmEmail');
          this.navController.pop();
        }
      });
  }

  //이메일 입력 페이지로
  goNextEmail() {
    // 값 저장
    const member = this.memberService.member;
    member.name = this.memberForm.get('name').value;
    const pageUrl = this.member.loginType.includes('email')
      ? '/join-email'
      : '/join-recommendation';
    this.navController.navigateForward(pageUrl);
  }
}
