// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-footer {
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);
}

.chat-inner-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.125rem;
  padding: 0 0.875rem;
}

ion-button {
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
}
ion-button.send-icon {
  --background: url('send.png') no-repeat center / 100%
    100%;
}

.chat-textarea-item {
  width: 100%;
  height: 1.5rem;
  max-height: 2.8125rem;
  overflow-y: scroll;
}
.chat-textarea-item ion-textarea {
  max-height: 0.2813rem;
}
.chat-textarea-item.item-has-value + .send-icon {
  --background: url('send-ac.png') no-repeat center/
    100% 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/reply-write/reply-write.component.scss"],"names":[],"mappings":"AAAA;EACE,4CAAA;AACF;;AACA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AAEF;;AAAA;EACE,aAAA;EACA,iBAAA;EACA,cAAA;AAGF;AADE;EACE;QAAA;AAIJ;;AAAA;EACE,WAAA;EACA,cAAA;EACA,qBAAA;EACA,kBAAA;AAGF;AAFE;EACE,qBAAA;AAIJ;AADI;EACE;aAAA;AAIN","sourcesContent":["ion-footer {\n  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);\n}\n.chat-inner-wrap {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 1.125rem;\n  padding: 0 0.875rem;\n}\nion-button {\n  width: 1.5rem;\n  min-width: 1.5rem;\n  height: 1.5rem;\n\n  &.send-icon {\n    --background: url(../../../../assets/imgs/send.png) no-repeat center / 100%\n      100%;\n  }\n}\n.chat-textarea-item {\n  width: 100%;\n  height: 1.5rem;\n  max-height: 2.8125rem;\n  overflow-y: scroll;\n  ion-textarea {\n    max-height: 0.2813rem;\n  }\n  &.item-has-value {\n    + .send-icon {\n      --background: url(../../../../assets/imgs/send-ac.png) no-repeat center/\n        100% 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
