import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { ExpertService } from 'src/app/core/services/expert.service';

@Component({
  selector: 'app-portfolio-list',
  templateUrl: './portfolio-list.component.html',
  styleUrls: ['./portfolio-list.component.scss'],
})
export class PortfolioListComponent implements OnInit {
  member: Member;
  data = [];
  origenPortfolio: Member['profile'];
  backAlertDisplayed = false; // 플래그 선언

  constructor(
    private alertService: AlertService,
    private navController: NavController,
    private auth: AuthService,
    private db: DbService,
    private expertService: ExpertService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    if (this.member.portfolio?.length > 0) {
      this.origenPortfolio = JSON.parse(JSON.stringify(this.member.portfolio)); // 복사
      this.data = [...this.member.portfolio];
    }
  }

  ionViewWillEnter() {
    const savedPortfolio = this.expertService.getPortfolio();

    // 새로 추가된 데이터 있는지
    savedPortfolio.forEach((portfolioItem) => {
      const index = this.data.findIndex((item) => item.id === portfolioItem.id);
      if (index !== -1) {
        this.data[index] = portfolioItem;
      } else {
        this.data.push(portfolioItem);
      }
    });
  }

  //뒤로 얼럿
  backAlert() {
    this.alertService.setBackAlertDisplayed(true);
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.expertService.clearData();
          this.navController.pop();
        }
      });
  }

  //추가하기
  goAddList(length: number) {
    if (length > 10) {
      this.alertService.okBtn('포트폴리오는 10개까지 등록 가능합니다.');
      return;
    }
    this.navController.navigateForward('/portfolio-add');
  }

  //수정하기
  goEditPortfolio(item, idx: number) {
    let updatedPortfolio = [...this.data];
    updatedPortfolio[idx] = item;

    // 업데이트된 포트폴리오 목록을 저장
    this.expertService.setPortfolioList(updatedPortfolio);
    this.navController.navigateForward('/portfolio-add', {
      queryParams: { item: JSON.stringify(item), idx },
    });
  }

  // [저장]
  async save() {
    await this.db.updateAt(`members/${this.member.uuid}`, {
      portfolio: this.data,
    });
    this.expertService.clearData();
    this.navController.pop();
    this.alertService.presentToast('포트폴리오를 업데이트 하였습니다.');
  }

  // 포트폴리오 삭제
  delete(idx: number) {
    this.alertService
      .cancelOkBtn('포트폴리오를 삭제하시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.data.splice(idx, 1);
          this.expertService.setPortfolioList(this.data);

          this.alertService.presentToast('포트폴리오를 삭제하였습니다.');
        }
      });
  }
}
