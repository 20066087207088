import { MbscModule } from '@mobiscroll/angular';
import localeKo from '@angular/common/locales/ko';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/// firebase ///

import { environment } from 'src/environments/environment';
import {
  FirestoreModule,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import {
  FirebaseAppModule,
  getApp,
  initializeApp,
  provideFirebaseApp,
} from '@angular/fire/app';
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth,
} from '@angular/fire/auth';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { Capacitor } from '@capacitor/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlarmListComponent } from './pages/common/alarm-list/alarm-list.component';
import { SettingComponent } from './pages/common/setting/setting.component';
import { IntroComponent } from './pages/common/intro/intro.component';
import { LoginComponent } from './pages/common/login/login.component';
import { AlarmDetailComponent } from './pages/common/alarm-detail/alarm-detail.component';
import { NoticeListComponent } from './pages/common/notice-list/notice-list.component';
import { FindPasswordComponent } from './pages/common/find-password/find-password.component';
import { JoinNameComponent } from './pages/common/join-name/join-name.component';
import { JoinEmailComponent } from './pages/common/join-email/join-email.component';
import { JoinPasswordComponent } from './pages/common/join-password/join-password.component';
import { JoinRecommendationComponent } from './pages/common/join-recommendation/join-recommendation.component';
import { JoinAgreeComponent } from './pages/common/join-agree/join-agree.component';
import { JoinCompleteComponent } from './pages/common/join-complete/join-complete.component';
import { NoticeDetailComponent } from './pages/common/notice-detail/notice-detail.component';
import { FaqListComponent } from './pages/common/faq-list/faq-list.component';
import { FaqDetailComponent } from './pages/common/faq-detail/faq-detail.component';
import { TermsComponent } from './pages/common/terms/terms.component';
import { PasswordEditComponent } from './pages/common/password-edit/password-edit.component';
import { WithdrawalReasonComponent } from './pages/common/withdrawal-reason/withdrawal-reason.component';
import { JoinExpertAddressComponent } from './pages/master/join-expert-address/join-expert-address.component';
import { SelectRegionComponent } from './pages/master/select-region/select-region.component';
import { JoinExpertAddInfoComponent } from './pages/master/join-expert-add-info/join-expert-add-info.component';
import { JoinExpertAddInfo02Component } from './pages/master/join-expert-add-info02/join-expert-add-info02.component';
import { JoinExpertCompleteComponent } from './pages/master/join-expert-complete/join-expert-complete.component';
import { SharedModule } from './pages/shared/shared.module';
import { SearchComponent } from './pages/user/search/search.component';
import { EstimateRequestComponent } from './pages/user/estimate-request/estimate-request.component';
import { ExpertDetailComponent } from './pages/user/expert-detail/expert-detail.component';
import { CategorySelectComponent } from './pages/user/category-select/category-select.component';
import { ApplicationAddressComponent } from './pages/user/application-address/application-address.component';
import { ApplicationTypeComponent } from './pages/user/application-type/application-type.component';
import { ApplicationDateComponent } from './pages/user/application-date/application-date.component';
import { CalendarPopComponent } from './pages/user/calendar-pop/calendar-pop.component';
import { ApplicationAreaComponent } from './pages/user/application-area/application-area.component';
import { ApplicationTextareaComponent } from './pages/user/application-textarea/application-textarea.component';
import { ApplicationImageComponent } from './pages/user/application-image/application-image.component';
import { ApplicationLastInquiryComponent } from './pages/user/application-last-inquiry/application-last-inquiry.component';
import { ApplicationCompleteComponent } from './pages/user/application-complete/application-complete.component';
import { ProfileDetailUserComponent } from './pages/user/profile-detail-user/profile-detail-user.component';
import { ProfileDetailMasterComponent } from './pages/master/profile-detail-master/profile-detail-master.component';
import { InsuranceListComponent } from './pages/user/insurance-list/insurance-list.component';
import { InsuranceDetailComponent } from './pages/user/insurance-detail/insurance-detail.component';

import { QnaListComponent } from './pages/common/qna-list/qna-list.component';
import { MyPostComponent } from './pages/common/my-post/my-post.component';
import { InsuranceRefundApplicationComponent } from './pages/user/insurance-refund-application/insurance-refund-application.component';
import { RefundTermComponent } from './pages/common/refund-term/refund-term.component';
import { MyReviewListComponent } from './pages/common/my-review-list/my-review-list.component';
import { ReviewWriteComponent } from './pages/user/review-write/review-write.component';
import { QnaWriteComponent } from './pages/common/qna-write/qna-write.component';
import { QnaDetailComponent } from './pages/common/qna-detail/qna-detail.component';
import { WithdrawalCheckComponent } from './pages/common/withdrawal-check/withdrawal-check.component';
import { FilterModalComponent } from './pages/user/filter-modal/filter-modal.component';
import { ServiceDetailComponent } from './pages/common/service-detail/service-detail.component';
import { BusinessInformationDetailComponent } from './pages/user/business-information-detail/business-information-detail.component';
import { PortfolioDetailComponent } from './pages/user/portfolio-detail/portfolio-detail.component';
import { ReviewDetailComponent } from './pages/common/review-detail/review-detail.component';
import { ExpertQnaDetailComponent } from './pages/common/expert-qna-detail/expert-qna-detail.component';
import { EstimateRequestDetailComponent } from './pages/common/estimate-request-detail/estimate-request-detail.component';
import { EstimateCheckDetailComponent } from './pages/common/estimate-check-detail/estimate-check-detail.component';
import { ChatDetailComponent } from './pages/common/chat-detail/chat-detail.component';
import { ReportComponent } from './pages/common/report/report.component';
import { InsurancePaymentComponent } from './pages/user/insurance-payment/insurance-payment.component';
import { InsuranceCompleteComponent } from './pages/user/insurance-complete/insurance-complete.component';
import { PostWriteComponent } from './pages/user/post-write/post-write.component';
import { PostDetailComponent } from './pages/common/post-detail/post-detail.component';
import { PostReportComponent } from './pages/common/post-report/post-report.component';
import { PointChargeComponent } from './pages/master/point-charge/point-charge.component';
import { MyEstimateComponent } from './pages/master/my-estimate/my-estimate.component';
import { MyEstimateDetailComponent } from './pages/master/my-estimate-detail/my-estimate-detail.component';
import { ReplyWriteComponent } from './pages/common/reply-write/reply-write.component';
import { PaymentListComponent } from './pages/master/payment-list/payment-list.component';
import { ExpertWriteComponent } from './pages/master/expert-write/expert-write.component';
import { ServiceSelectComponent } from './pages/master/service-select/service-select.component';
import { ServiceDetailWriteComponent } from './pages/master/service-detail-write/service-detail-write.component';
import { CareerSelectComponent } from './pages/master/career-select/career-select.component';
import { PortfolioListComponent } from './pages/master/portfolio-list/portfolio-list.component';
import { PortfolioAddComponent } from './pages/master/portfolio-add/portfolio-add.component';
import { ExpertQnaListComponent } from './pages/master/expert-qna-list/expert-qna-list.component';
import { LinkComponent } from './pages/master/link/link.component';
import { MyEstimateWriteComponent } from './pages/master/my-estimate-write/my-estimate-write.component';
import { TransactionListComponent } from './pages/master/transaction-list/transaction-list.component';
import { TransactionDetailComponent } from './pages/master/transaction-detail/transaction-detail.component';
import { SuspensionComponent } from './pages/common/suspension/suspension.component';
import { ExpertIntroductionWriteComponent } from './pages/master/expert-introduction-write/expert-introduction-write.component';
import { HomePopComponent } from './pages/common/home-pop/home-pop.component';
import { ApplicationComplete02Component } from './pages/common/application-complete02/application-complete02.component';
import { HttpClientModule } from '@angular/common/http';
import { JoinExpertComponent } from './pages/master/join-expert/join-expert.component';
import { PipesModule } from './core/pipes/pipes.module';
// import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
// import { File } from '@awesome-cordova-plugins/file/ngx';
import { ImageDetailComponent } from './pages/image-detail/image-detail.component';
import { MyLikeComponent } from './pages/common/my-like/my-like.component';
import { registerLocaleData } from '@angular/common';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  GoogleSigninButtonModule,
} from '@abacritt/angularx-social-login';
import { AutomaticChargingComponent } from './pages/master/automatic-charging/automatic-charging.component';
import { PaymentRegistComponent } from './pages/master/payment-regist/payment-regist.component';
import { AutoRechargeSettingComponent } from './pages/master/auto-recharge-setting/auto-recharge-setting.component';
import { AutomaticChargingChangeComponent } from './pages/master/automatic-charging-change/automatic-charging-change.component';
registerLocaleData(localeKo);

@NgModule({
  declarations: [
    AppComponent,
    AlarmListComponent,
    SettingComponent,
    IntroComponent,
    LoginComponent,
    AlarmDetailComponent,
    NoticeListComponent,
    FindPasswordComponent,
    JoinNameComponent,
    JoinEmailComponent,
    JoinPasswordComponent,
    JoinRecommendationComponent,
    JoinAgreeComponent,
    JoinCompleteComponent,
    JoinExpertComponent,
    NoticeDetailComponent,
    FaqListComponent,
    FaqDetailComponent,
    TermsComponent,
    PasswordEditComponent,
    WithdrawalReasonComponent,
    JoinExpertAddressComponent,
    SelectRegionComponent,
    JoinExpertAddInfoComponent,
    JoinExpertAddInfo02Component,
    JoinExpertCompleteComponent,
    SearchComponent,
    EstimateRequestComponent,
    ExpertDetailComponent,
    CategorySelectComponent,
    ApplicationAddressComponent,
    ApplicationTypeComponent,
    ApplicationDateComponent,
    CalendarPopComponent,
    ApplicationAreaComponent,
    ApplicationTextareaComponent,
    ApplicationImageComponent,
    ApplicationLastInquiryComponent,
    ApplicationCompleteComponent,
    ProfileDetailUserComponent,
    ProfileDetailMasterComponent,
    InsuranceListComponent,
    InsuranceDetailComponent,
    MyLikeComponent,
    QnaListComponent,
    MyPostComponent,
    InsuranceRefundApplicationComponent,
    RefundTermComponent,
    MyReviewListComponent,
    MyPostComponent,
    ReviewWriteComponent,
    QnaWriteComponent,
    QnaDetailComponent,
    WithdrawalReasonComponent,
    WithdrawalCheckComponent,
    FilterModalComponent,
    ServiceDetailComponent,
    BusinessInformationDetailComponent,
    PortfolioDetailComponent,
    ReviewDetailComponent,
    ExpertQnaDetailComponent,
    EstimateRequestDetailComponent,
    EstimateCheckDetailComponent,
    ChatDetailComponent,
    ReportComponent,
    InsurancePaymentComponent,
    InsuranceCompleteComponent,
    PostWriteComponent,
    PostDetailComponent,
    PostReportComponent,
    PointChargeComponent,
    MyEstimateComponent,
    MyEstimateDetailComponent,
    ReplyWriteComponent,
    PaymentListComponent,
    ExpertWriteComponent,
    ServiceSelectComponent,
    ServiceDetailWriteComponent,
    CareerSelectComponent,
    PortfolioListComponent,
    PortfolioAddComponent,
    ExpertQnaListComponent,
    LinkComponent,
    MyEstimateWriteComponent,
    TransactionListComponent,
    TransactionDetailComponent,
    SuspensionComponent,
    ExpertIntroductionWriteComponent,
    HomePopComponent,
    ApplicationComplete02Component,
    ImageDetailComponent,
    AutomaticChargingComponent,
    PaymentRegistComponent,
    AutoRechargeSettingComponent,
    AutomaticChargingChangeComponent,
  ],
  imports: [
    MbscModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios' }),
    AppRoutingModule,
    FirestoreModule,
    FirebaseAppModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    HttpClientModule,
    PipesModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    GoogleSigninButtonModule,
    // provideFunctions(() => getFunctions()),
  ],
  providers: [
    // MediaCapture,
    // File,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'ko-KR' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '919141513204-0p765vve2edg47rmtm6tbf2jrbh34ck5.apps.googleusercontent.com',
              { oneTapEnabled: false }
            ),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
