import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { NavController, Platform } from '@ionic/angular';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import { Subscription } from 'rxjs';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { MemberService } from 'src/app/core/services/member.service';
import { PushService } from 'src/app/core/services/push.service';
import { SocialService } from 'src/app/core/services/social.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { ValidationService } from 'src/app/core/services/validation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email: string = '';
  password: string = '';
  isWeb: boolean;

  constructor(
    public socialService: SocialService,
    public platform: Platform,
    private alertService: AlertService,
    private navController: NavController,
    private auth: AuthService,
    private memberService: MemberService,
    private db: DbService,
    private pushService: PushService,
    private authService: SocialAuthService,
    public validationService: ValidationService
  ) {}

  ngOnInit() {
    localStorage.removeItem('confirmEmail');
    if (!Capacitor.isNativePlatform()) {
      this.isWeb = true;
    } else {
      this.isWeb = false;
    }

    const sub: Subscription = this.authService.authState.subscribe((user) => {
      if (sub && user) {
        this.socialService.webGoogleLogin(user.email);
        sub.unsubscribe();
      }
    });
  }

  noMember() {
    this.navController.navigateForward('tabs/home-nomember');
  }

  // [로그인]
  async login() {
    const useEmail = await this.db.toCollection$('members', (ref) =>
      ref.where('status', '!=', 'exit').where('email', '==', this.email)
    );

    // 가입 된 이메일 주소가 아니라면 alert
    if (useEmail?.length == 0) {
      this.loginNotMatch();
      return;
    }

    this.auth
      .loginUser({ email: this.email, password: this.password })
      .then(async (data) => {
        // 이메일 인증이 되지 않은 경우
        if (data && !data.user.emailVerified) {
          this.alertService.okBtn(
            '이메일 인증이 완료되지 않았습니다.\n 확인 후 다시 입력해주세요.'
          );
          return;
        }

        const member: Member = await this.db.toDoc$(
          `members/${data.user?.uid}`
        );

        // 푸시 넣기
        const pushId = await this.pushService.getId();
        if (pushId && pushId.userId) {
          // 다른계정에 나의 푸시아이디가 들어가 있는경우 빼기
          const other = await this.db.toCollection$(`members`, (ref) =>
            ref.where('pushId', 'array-contains', pushId.userId)
          );

          if (other) {
            const promise = other.map((e) => {
              if (e.uid != data.user?.uid) {
                this.db.updateAt(`members/${e.uuid}`, {
                  pushId: arrayRemove(pushId.userId),
                });
              }
            });
            await Promise.all(promise);
          }

          await this.db.updateAt(`members/${data.user?.uid}`, {
            pushId: arrayUnion(pushId.userId),
          });
        }

        sessionStorage.removeItem('logoutUser');
        // 최초 로그인
        if (member.status !== 'suspend' && member.isFirstLogin) {
          this.firstLogin(member.uuid);
        }
        // 정상처리
        else if (member.status !== 'suspend' && !member.isFirstLogin) {
          this.loginMatch();
        }
        // 이용정지된 계정인 경우
        else {
          this.suspension();
          return;
        }
      })
      .catch(() => {
        // 비밀번호가 틀렸을 경우
        this.loginNotMatch();
      });
  }

  // 정상처리
  loginMatch() {
    this.navController.navigateRoot('/tabs/home', {
      animationDirection: 'forward',
    });
    this.alertService.presentToast('로그인 했어요.');
  }

  // 최초 로그인일 경우
  async firstLogin(uuid: Member['uuid']) {
    await this.db.updateAt(`members/${uuid}`, {
      isFirstLogin: false,
    });
    this.navController.navigateRoot('/join-complete', {
      animationDirection: 'forward',
    });
  }

  // 가입된 이메일 주소가 아니거나 비밀번호가 틀린 경우 alert
  loginNotMatch() {
    this.alertService.okBtn(
      '이메일 주소 또는 비밀번호가 틀립니다.\n확인 후 다시 입력해주세요.'
    );
  }

  // 이용정지된 계정인 경우
  suspension() {
    this.navController.navigateForward('suspension');
  }

  //비밀번호 재설정
  goFindPassword() {
    this.navController.navigateForward('/password-reset');
  }

  //회원가입
  goJoin() {
    this.navController.navigateForward('/join-name');
  }
}
