import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { ExpertService } from 'src/app/core/services/expert.service';
import { postcode } from 'src/assets/js/postcode.js';
@Component({
  selector: 'app-join-expert-address',
  templateUrl: './join-expert-address.component.html',
  styleUrls: ['./join-expert-address.component.scss'],
})
export class JoinExpertAddressComponent implements OnInit {
  //다음 api
  // @ViewChild('address_pop', { read: ElementRef, static: true })
  @ViewChild('popup', { static: true }) popup!: ElementRef;

  member: Member;
  // popup!: ElementRef;
  search: string = '';
  area: { si: ''; gu: ''; isDelete: false }; // 파람즈로 넘어온 선택한 지역
  selectAreas = []; // 여러개를 담을 지역 배열
  type: 'mypage' | 'join';
  si: string = '';
  gu: string = '';
  nationwide: boolean; // "전국"선택 유무

  addressForm = new FormGroup({
    address: new FormControl('', [Validators.required]),
    detailAddress: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
  });

  constructor(
    private renderer: Renderer2,
    private alertService: AlertService,
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private expertService: ExpertService,
    private auth: AuthService,
    private db: DbService
  ) {
    this.type = this.activatedRoute.snapshot.queryParams['type'];
  }

  async ngOnInit() {
    let data;
    if (this.type === 'join') {
      data = this.expertService.getSelectedServices();
    } else {
      this.member = await this.auth.getUser();
    }
    this.addressForm
      .get('address')
      .setValue(this.type === 'join' ? data.address : this.member.address);
    this.addressForm
      .get('detailAddress')
      .setValue(
        this.type === 'join' ? data.addressDetail : this.member.addressDetail
      );
    this.selectAreas =
      this.type === 'join' ? data.selectAreas || [] : this.member.areas || [];

    // 전국인경우
    const allArea = this.selectAreas.some(
      (data) => data.gu === 'all' && data.si === 'all'
    );
    if (allArea) {
      this.nationwide = true;
    }
  }

  ngOnDestroy() {
    if (this.type === 'join') {
      this.expertService.setSelectedServices({
        selectAreas: this.selectAreas,
        address: this.addressForm.get('address').value,
        addressDetail: this.addressForm.get('detailAddress').value,
        addressSiGu: { si: this.si, gu: this.gu },
      });
    }
  }

  ionViewWillEnter() {
    this.area = this.activatedRoute.snapshot.queryParams['area'];
    this.nationwide = this.activatedRoute.snapshot.queryParams['nationwide']; // 전국인경우

    if (this.area) {
      // 전국
      if (this.nationwide) {
        this.selectAreas.forEach((area) => {
          area.isDelete = true;
        });

        this.selectAreas = [this.area]; // '전국' 데이터만 남기기
      } else if (
        !this.selectAreas.some(
          (area) => area.si === this.area.si && area.gu === this.area.gu
        ) &&
        !this.area.isDelete
      ) {
        // 기존 selectAreas에 없는 항목이고 isDelete가 false인 경우에만 추가
        this.selectAreas.push(this.area);
      }

      // area 초기화
      this.area = null;
    }

    // si가 같고 gu가 'all'인 항목이 있는 경우, 같은 si를 가진 다른 항목들을 모두 삭제 처리
    this.selectAreas.forEach((area, index) => {
      if (area.gu === 'all') {
        this.selectAreas = this.selectAreas.map((a) => {
          if (a.si === area.si && a.gu !== 'all') {
            return { ...a, isDelete: true };
          }
          return a;
        });
      }
    });

    // 삭제된 항목들 제거
    this.selectAreas = this.selectAreas.filter((area) => !area.isDelete);
  }

  //주소찾기 api
  openDaumPopup() {
    if (this.popup && this.popup.nativeElement) {
      setTimeout(() => {
        this.getAddress().then((data) => {
          this.si = data.sido;
          this.gu = data.sigungu;
          this.addressForm.get('address').setValue(data.address);
        });
      }, 1000);
    }
  }
  getAddress(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      postcode(this.renderer, this.popup.nativeElement, (data) => {
        resolve(data);
      });
    });
  }

  //주소팝업 닫기
  closeAddressPopup() {
    this.renderer.setStyle(this.popup.nativeElement, 'display', 'none');
  }

  //지역선택
  goSelctAddress() {
    this.navController.navigateForward('/select-region');
  }

  // 지역삭제
  delArea(idx: number) {
    // 전국 데이터가 삭제된경우
    if (
      this.selectAreas.filter(
        (area) => area.si === 'all' && area.gu === 'all'
      ) &&
      this.nationwide
    ) {
      this.nationwide = false;
    }
    this.selectAreas[idx].isDelete = true;
    this.selectAreas.splice(idx, 1);
  }

  // 비활성화 조건
  disabled() {
    if (
      !this.addressForm.get('address').value ||
      !this.addressForm.get('detailAddress').value ||
      this.addressForm.get('detailAddress').value?.length < 2 ||
      this.selectAreas?.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  //전문가 추가정보 입력
  goAddInfo() {
    const areasToSave = this.selectAreas.filter((area) => !area.isDelete);
    this.expertService.setSelectedServices({
      selectAreas: areasToSave,
      address: this.addressForm.get('address').value,
      addressDetail: this.addressForm.get('detailAddress').value,
      addressSiGu: { si: this.si, gu: this.gu },
    });
    this.alertService.presentToast(
      '시공 가능 지역을 업데이트 하였습니다.',
      100
    );
    this.navController.navigateForward('/join-expert-add-info');
  }

  //저장
  async save() {
    const areasToSave = this.selectAreas.filter((area) => !area.isDelete);
    await this.db.updateAt(`members/${this.member.uuid}`, {
      areas: areasToSave,
      address: this.addressForm.get('address').value,
      addressDetail: this.addressForm.get('detailAddress').value,
      addressSiGu: { si: this.si, gu: this.gu },
    });

    this.navController.pop();
    this.alertService.presentToast('시공 가능 지역을 업데이트 하였습니다.');
  }

  //닫기
  backAlert() {
    if (this.type === 'join') {
      this.navController.pop();
    } else {
      this.alertService
        .cancelOkBtn(
          '입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?'
        )
        .then((ok) => {
          if (ok) {
            this.navController.pop();
          }
        });
    }
  }
}
