// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.footer-container .main-line-btn {
  width: 7rem;
  min-width: 7rem;
  height: 3.25rem;
  font-weight: 700;
}
.footer-container .main-btn {
  width: calc(100% - 7rem - 0.375rem);
}

.gender-item {
  width: 100%;
}
.gender-item ion-item {
  width: 100%;
  height: 3.5rem;
  border: 1px solid var(--gray-scale100);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 1rem;
}
.gender-item ion-item ion-label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale750);
  text-align: center;
  padding: 1rem;
}
.gender-item ion-item.item-radio-checked {
  border: 1px solid var(--main);
  --background: url('service-check.png') no-repeat
    center right 1rem / 1.5rem 1.5rem;
}
.gender-item ion-item.item-radio-checked ion-label {
  font-weight: 700;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/application-type/application-type.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,mCAAA;AAAJ;;AAGA;EACE,WAAA;AAAF;AACE;EACE,WAAA;EACA,cAAA;EACA,sCAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAAI;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,kBAAA;EACA,aAAA;AAEN;AACI;EACE,6BAAA;EACA;qCAAA;AAEN;AACM;EACE,gBAAA;EACA,kBAAA;AACR","sourcesContent":[".footer-container {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 0.75rem;\n\n  .main-line-btn {\n    width: 7rem;\n    min-width: 7rem;\n    height: 3.25rem;\n    font-weight: 700;\n  }\n\n  .main-btn {\n    width: calc(100% - 7rem - 0.375rem);\n  }\n}\n.gender-item {\n  width: 100%;\n  ion-item {\n    width: 100%;\n    height: 3.5rem;\n    border: 1px solid var(--gray-scale100);\n    border-radius: 6px;\n    overflow: hidden;\n    margin-bottom: 1rem;\n    ion-label {\n      height: 100%;\n      display: flex;\n      align-items: center;\n      justify-content: flex-start;\n      font-size: 1rem;\n      line-height: 1.5rem;\n      font-weight: 400;\n      color: var(--gray-scale750);\n      text-align: center;\n      padding: 1rem;\n    }\n\n    &.item-radio-checked {\n      border: 1px solid var(--main);\n      --background: url(../../../../assets/imgs/service-check.png) no-repeat\n        center right 1rem / 1.5rem 1.5rem;\n\n      ion-label {\n        font-weight: 700;\n        text-align: center;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
