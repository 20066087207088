// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alarm-btn {
  width: 2.75rem;
  height: 2.75rem;
  --background: url('alarm-icon.png') no-repeat center
    center/ 100% 100%;
}
.alarm-btn.badge {
  position: relative;
}
.alarm-btn.badge::after {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #ff6939;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  content: "";
}`, "",{"version":3,"sources":["webpack://./src/app/pages/shared/alarm-shared/alarm-shared.component.scss"],"names":[],"mappings":"AACA;EACE,cAAA;EACA,eAAA;EACA;qBAAA;AACF;AAEE;EACE,kBAAA;AAAJ;AACI;EACE,aAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,WAAA;AACN","sourcesContent":["//알림\n.alarm-btn {\n  width: 2.75rem;\n  height: 2.75rem;\n  --background: url(../../../../assets/imgs/alarm-icon.png) no-repeat center\n    center/ 100% 100%;\n\n  &.badge {\n    position: relative;\n    &::after {\n      width: 0.5rem;\n      height: 0.5rem;\n      border-radius: 50%;\n      background: #ff6939;\n      position: absolute;\n      top: 0.75rem;\n      right: 0.75rem;\n      content: \"\";\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
