// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-footer {
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);
}

.box {
  width: 100%;
  padding: 1rem;
  border-radius: 6px;
  background: #f4f4f4;
}
.box p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--gray-scale600);
}

.modal-close {
  width: 2.75rem;
  height: 2.75rem;
  --background: url('modal-close-icon.png') no-repeat
    center / 100% 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/master/link/link.component.scss"],"names":[],"mappings":"AAAA;EACE,4CAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;AACF;AAAE;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;AAEJ;;AACA;EACE,cAAA;EACA,eAAA;EACA;sBAAA;AAGF","sourcesContent":["ion-footer {\n  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);\n}\n\n.box {\n  width: 100%;\n  padding: 1rem;\n  border-radius: 6px;\n  background: #f4f4f4;\n  p {\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 400;\n    color: var(--gray-scale600);\n  }\n}\n.modal-close {\n  width: 2.75rem;\n  height: 2.75rem;\n  --background: url(../../../../assets/imgs/modal-close-icon.png) no-repeat\n    center / 100% 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
