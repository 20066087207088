// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-category-wrap {
  width: 100%;
}

ion-button {
  width: 100%;
  border: 1px solid var(--gray-scale100);
  border-radius: 6px;
  height: 3.5rem;
  margin-bottom: 1rem;
}
ion-button p {
  width: 100%;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-left: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 3.5rem;
}
ion-button.ion-activated {
  border: 1px solid var(--main);
  --background: url('main-check.png') no-repeat center
    right 1rem/ 1.5rem 1.5rem;
}
ion-button.ion-activated p {
  font-weight: 700;
  color: var(--main);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/category-select-expert/category-select-expert.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;EACA,sCAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,qBAAA;AAEJ;AACE;EACE,6BAAA;EACA;6BAAA;AAEJ;AAAI;EACE,gBAAA;EACA,kBAAA;AAEN","sourcesContent":[".small-category-wrap {\n  width: 100%;\n}\n\nion-button {\n  width: 100%;\n  border: 1px solid var(--gray-scale100);\n  border-radius: 6px;\n  height: 3.5rem;\n  margin-bottom: 1rem;\n  p {\n    width: 100%;\n    text-align: left;\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 400;\n    margin-left: 1rem;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    padding-right: 3.5rem;\n  }\n\n  &.ion-activated {\n    border: 1px solid var(--main);\n    --background: url(../../../../assets/imgs/main-check.png) no-repeat center\n      right 1rem/ 1.5rem 1.5rem;\n    p {\n      font-weight: 700;\n      color: var(--main);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
