// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-close {
  width: 2.75rem;
  height: 2.75rem;
  --background: url('modal-close-icon.png') no-repeat
    center / 100% 100%;
}

.qna-card {
  width: 100%;
  border-bottom: 1px solid var(--gray-scale100);
  padding: 1rem;
}
.qna-card:last-child {
  margin-bottom: 0;
}
.qna-card:not(:first-child) {
  border-top: 8px solid #f4f4f4;
}
.qna-card .content {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale750);
}

.qna-title {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.qna-title .icon {
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  background: url('question.png') no-repeat center/100% 100%;
}
.qna-title p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--gray-scale900);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.qna-list {
  width: 100%;
  padding: 0 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/expert-qna-detail/expert-qna-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA;sBAAA;AAEF;;AACA;EACE,WAAA;EACA,6CAAA;EACA,aAAA;AAEF;AAAE;EACE,gBAAA;AAEJ;AACE;EACE,6BAAA;AACJ;AACE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AACJ;;AAGA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AAAF;AAEE;EACE,aAAA;EACA,iBAAA;EACA,cAAA;EACA,0DAAA;AAAJ;AAIE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAFJ;;AAMA;EACE,WAAA;EACA,eAAA;AAHF","sourcesContent":[".modal-close {\n  width: 2.75rem;\n  height: 2.75rem;\n  --background: url(../../../../assets/imgs/modal-close-icon.png) no-repeat\n    center / 100% 100%;\n}\n.qna-card {\n  width: 100%;\n  border-bottom: 1px solid var(--gray-scale100);\n  padding: 1rem;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  &:not(:first-child) {\n    border-top: 8px solid #f4f4f4;\n  }\n  .content {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 400;\n    color: var(--gray-scale750);\n  }\n}\n\n.qna-title {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 0.25rem;\n\n  .icon {\n    width: 1.5rem;\n    min-width: 1.5rem;\n    height: 1.5rem;\n    background: url(../../../../assets/imgs/question.png) no-repeat center /\n      100% 100%;\n  }\n\n  p {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 700;\n    color: var(--gray-scale900);\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n}\n\n.qna-list {\n  width: 100%;\n  padding: 0 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
