import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-password-edit',
  templateUrl: './password-edit.component.html',
  styleUrls: ['./password-edit.component.scss'],
})
export class PasswordEditComponent implements OnInit {
  focusedControl: string | null = null;
  memberForm = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/),
      ]),

      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),

      confirmPassword: new FormControl('', [Validators.required]),
    },
    { validators: this.passwordMatchValidator }
  );

  private passwordMatchValidator(group: FormGroup) {
    const password = group.get('newPassword')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;

    return password === confirmPassword ? null : { mismatch: true };
  }
  constructor(
    private navController: NavController,
    private alertService: AlertService,
    private auth: AuthService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {}

  //뒤로 얼럿
  backAlert() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.navController.pop();
        }
      });
  }

  // 비활성화
  saveDisabled() {
    if (
      !this.memberForm.get('password').value ||
      !this.memberForm.get('newPassword').value ||
      !this.memberForm.get('confirmPassword').value ||
      this.memberForm.get('newPassword').hasError('minlength') ||
      this.memberForm.hasError('mismatch')
    ) {
      return true;
    } else {
      return false;
    }
  }

  //저장
  async save() {
    const member = await this.auth.getUser();
    this.alertService
      .cancelOkBtn('비밀번호를 변경하시겠어요?')
      .then(async (ok) => {
        if (ok) {
          await this.loadingService.load();
          await this.auth
            .loginUser({
              email: member.email,
              password: this.memberForm.get('password').value,
            })
            .then(async (data) => {
              await this.loadingService.load();
              if (data.user?.uid) {
                await this.auth.changePassword(
                  this.memberForm.get('confirmPassword').value
                );
                this.loadingService.hide();
                this.navController.pop();
                this.alertService.presentToast('비밀번호를 변경했습니다.');
              }
            })
            .catch((err) => {
              this.loadingService.hide();
              this.alertService.okBtn(
                '현재 비밀번호가 틀립니다. 확인 후 다시 시도해주세요.'
              );
            });
        }
      });
  }
}
