import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Notification } from 'src/app/core/models/notification.model';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-alarm-shared',
  templateUrl: './alarm-shared.component.html',
  styleUrls: ['./alarm-shared.component.scss'],
})
export class AlarmSharedComponent implements OnInit {
  @Input() notifications$: Observable<number>;
  @Input() noLogin: boolean;

  constructor(
    private navController: NavController,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  goAlarm() {
    if (this.noLogin) {
      this.alertService
        .cancelOkBtn('로그인 후 이용 가능한 서비스입니다. 로그인 하시겠습니까?')
        .then((res) => {
          if (res) {
            this.navController.navigateRoot('login');
          }
        });
      return;
    }
    this.navController.navigateForward('/alarm-list');
  }
}
