import { NavController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestPayParams, RequestPayResponse } from 'iamport-typings';

@Injectable({
  providedIn: 'root',
})
export class IamportService {
  imp: any;
  constructor(private http: HttpClient) {
    const { IMP } = window;
    this.imp = IMP;
    this.imp.init('imp30003650'); // 한 번만 초기화
  }

  /**
   * 결제 함수
   * @param amount 거래 금액
   * @param buyer_name 주문자 이름
   * @param buyer_tel 주문자 전화번호
   * @param buyer_email 주문자 이메일
   * @param type 전문가-포인트 결제 & 일반유저-보험료 결제
   * @returns
   */
  async payment(
    amount: number,
    buyer_name: string,
    buyer_tel: string,
    buyer_email: string,
    type: 'expert' | 'member',
    params?: { key: string; value: string }
  ) {
    return new Promise(async (resolve, reject) => {
      const protocol = window.location.protocol; // 'https:' 또는 'http:'
      const hostname = window.location.hostname;

      let baseUrl = `${protocol}//${hostname}`;

      let url =
        type === 'expert'
          ? `${baseUrl}/point-charge`
          : `${baseUrl}/insurance-payment`;
      if (params) {
        url + '?' + params.key + '=' + params.value;
      }

      const data: RequestPayParams = {
        pg: 'nice',
        pay_method: 'card',
        merchant_uid: `mid_${new Date().getTime()}`,
        name: type === 'expert' ? '포인트 결제' : '보험료 결제',
        buyer_email: buyer_email,
        buyer_name: buyer_name,
        buyer_tel: buyer_tel,
        m_redirect_url: url,
        amount: amount,
      };

      try {
        this.imp.request_pay(data, (rsp: RequestPayResponse) => {
          console.log('결제 응답: ', rsp);

          if (rsp.success) {
            // alert('결제 성공: ' + rsp.imp_uid);
            // 결제 성공 처리
            resolve(true);
          } else {
            // alert('결제 실패: ' + rsp.error_msg);
            // 결제 실패 또는 취소 처리
            console.error('결제 실패: ', rsp.error_msg);
            reject(false);
          }
        });
      } catch (error) {
        // alert('결제 에러: ' + error + '다시 시도해주세요.');
        console.error('결제 에러: ', error);
        reject(false);
      }
    });
  }

  // 토큰 받아오기
  getToken() {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          'https://us-central1-banseliin.cloudfunctions.net/iamportGetToken',
          {},
          {}
        )
        .toPromise()
        .then((result: any) => {
          if (result?.code === 0) {
            resolve(result.response.access_token);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
        });
    });
  }
}
