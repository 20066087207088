import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { lastValueFrom, take } from 'rxjs';
import { Member } from 'src/app/core/models/member.model';
import { Review } from 'src/app/core/models/review.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService, leftJoinDocument } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-review-detail',
  templateUrl: './review-detail.component.html',
  styleUrls: ['./review-detail.component.scss'],
})
export class ReviewDetailComponent implements OnInit {
  @Input() expertUuid: Member['uuid'];
  @Input() type: 'myLike';

  member: Member;
  reviews: Review[] | any;
  serviceArr: {
    name: string;
    id?: string;
    isChecked: boolean;
    imageCheck?: boolean;
  }[] = [];
  ratingDistribution;
  filteredReviews: any[] = []; // 필터 된 리뷰
  imageReviews: boolean = false; // 사진 리뷰만 필터
  options: string = '최신순';

  //리뷰 셀렉트
  reviewOption: any = {
    header: '셀렉트',
    translucent: true,
  };

  constructor(
    private modalController: ModalController,
    private auth: AuthService,
    private db: DbService
  ) {}

  ngOnInit() {}

  async ionViewWillEnter() {
    const expert = await this.db.toDoc$(`members/${this.expertUuid}`);
    this.member = this.type === 'myLike' ? expert : await this.auth.getUser();
    this.getReviews();
    this.filteredReviews = this.reviews;
    this.serviceName(this.member.services);
  }

  // 리뷰 불러오기
  async getReviews() {
    this.reviews = await lastValueFrom(
      this.db
        .collection$('reviews', (ref) =>
          ref
            .where('expertUuid', '==', this.member.uuid)
            .where('isDelete', '==', false)
            .orderBy('dateCreated', 'desc')
        )
        .pipe(
          leftJoinDocument(this.db.firestore, 'uuid', 'members'),
          leftJoinDocument(this.db.firestore, 'expertUuid', 'members'),
          take(1)
        )
    );

    this.reviewFilter({ name: '전체', isChecked: true, imageCheck: false });
    this.calculateRating();
  }

  // 평점 비율 구하기
  calculateRating() {
    // 기본값을 0으로 설정
    const count = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };

    if (this.reviews && this.reviews.length > 0) {
      this.reviews.forEach((review) => {
        const rating = review.rating;
        if (rating <= 1.5) {
          count[1]++;
        } else if (rating <= 2.5) {
          count[2]++;
        } else if (rating <= 3.5) {
          count[3]++;
        } else if (rating <= 4.5) {
          count[4]++;
        } else {
          count[5]++;
        }
      });
    }

    const totalReviews = this.reviews?.length || 1; // 리뷰가 없으면 1로 나누어 0%를 유지

    // 평점 비율 객체를 기본값으로 초기화
    this.ratingDistribution = {
      1: {
        count: count[1],
        percentage: (count[1] / totalReviews) * 100,
      },
      2: {
        count: count[2],
        percentage: (count[2] / totalReviews) * 100,
      },
      3: {
        count: count[3],
        percentage: (count[3] / totalReviews) * 100,
      },
      4: {
        count: count[4],
        percentage: (count[4] / totalReviews) * 100,
      },
      5: {
        count: count[5],
        percentage: (count[5] / totalReviews) * 100,
      },
    };

    if (this.filteredReviews?.length === 0) {
      this.ratingDistribution = {
        1: { count: 0, percentage: 0 },
        2: { count: 0, percentage: 0 },
        3: { count: 0, percentage: 0 },
        4: { count: 0, percentage: 0 },
        5: { count: 0, percentage: 0 },
      };
    }
  }

  // 제공서비스 id => 이름 변환
  async serviceName(arr) {
    this.serviceArr = [{ name: '전체', isChecked: true, imageCheck: false }];
    for (const v of arr) {
      try {
        const sub = await this.db.toDoc$(`subCategories/${v}`);
        if (sub) {
          this.serviceArr.push({
            name: sub.name,
            id: sub.id,
            isChecked: false,
            imageCheck: false,
          });
        }
      } catch (error) {
        console.error(`에러가 발생했습니다.`, error);
      }
    }
  }

  // 서비스 항목에 따른 리뷰 필터
  async reviewFilter(item, index?: number, isImageCheck?: boolean) {
    if (item.name === '전체' && item.isChecked) {
      // '전체' 항목이 선택된 경우
      if (isImageCheck) {
        // 전체 데이터에서 이미지 리뷰만 필터링
        this.filteredReviews = this.reviews.filter(
          (review) => review.images?.length > 0
        );
      } else {
        // 모든 리뷰를 표시
        this.filteredReviews = this.reviews;
      }

      // '전체'를 제외한 다른 항목들 해제
      this.serviceArr.forEach((serviceItem) => {
        if (serviceItem.name !== '전체') {
          serviceItem.isChecked = false;
          serviceItem.imageCheck = false; // 이미지 체크 해제
        }
      });
    } else if (item.name !== '전체' && item.isChecked) {
      // '부분' 항목이 선택된 경우 다른 항목을 해제
      this.serviceArr.forEach((serviceItem, i) => {
        if (i !== index) {
          serviceItem.isChecked = false;
          serviceItem.imageCheck = false; // 이미지 체크 해제
        }
      });

      // 선택된 서비스로 필터링
      let filteredByService = this.reviews.filter((review) => {
        return review.subCategoryId
          ? review.subCategoryId === item.id
          : review.expertUuid?.services?.includes(item.id);
      });

      // 이미지 리뷰 체크 상태로 필터링
      this.filteredReviews = filteredByService.filter((review) => {
        return isImageCheck || this.imageReviews
          ? review.images?.length > 0
          : true;
      });

      // 선택된 항목 상태를 업데이트
      item.imageCheck = isImageCheck;

      // 선택된 서비스의 상태를 유지
      this.serviceArr.forEach((serviceItem) => {
        if (serviceItem.id === item.id) {
          serviceItem.isChecked = true;
          serviceItem.imageCheck = item.imageCheck;
        }
      });
    } else if (!item.isChecked) {
      // 선택이 해제된 경우, 필터를 다시 적용
      this.serviceArr.forEach((serviceItem) => {
        if (serviceItem.id === item.id) {
          serviceItem.imageCheck = false;
        }
      });

      // 선택된 서비스가 없는 경우 필터링
      const anyChecked = this.serviceArr.some(
        (serviceItem) => serviceItem.isChecked
      );

      if (!anyChecked) {
        if (this.imageReviews) {
          // 이미지 리뷰만 필터링
          this.filteredReviews = this.reviews.filter(
            (review) => review.images?.length > 0
          );
        } else {
          // 모든 리뷰를 표시
          this.filteredReviews = this.reviews;
        }
      }
    }
    // 정렬
    this.sortFilter();
  }

  getSelectedService() {
    const selectedService = this.serviceArr.find(
      (serviceItem) => serviceItem.isChecked
    );

    if (selectedService) {
      // 선택된 서비스 항목을 반환하고, 이미지 체크 상태를 추가
      return { ...selectedService, imageCheck: this.imageReviews };
    }

    return {
      name: '전체',
      isChecked: false,
      id: null,
      imageCheck: this.imageReviews,
    };
  }

  // 정렬
  sortFilter() {
    if (this.options === '최신순') {
      this.filteredReviews?.sort((a, b) =>
        b.dateCreated < a.dateCreated
          ? -1
          : b.dateCreated > a.dateCreated
          ? 1
          : 0
      );
    } else if (this.options === '평점높은순') {
      this.filteredReviews?.sort((a, b) => b.rating - a.rating);
    } else if (this.options === '평점낮은순') {
      this.filteredReviews?.sort((a, b) => a.rating - b.rating);
    }
  }

  // 모달 닫기
  closeModal() {
    this.modalController.dismiss();
  }
}
