import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { lastValueFrom, take } from 'rxjs';
import { FavoriteEstimate } from 'src/app/core/models/favorite-estimate.model';
import { Member } from 'src/app/core/models/member.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService, leftJoinDocument } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-my-estimate',
  templateUrl: './my-estimate.component.html',
  styleUrls: ['./my-estimate.component.scss'],
})
export class MyEstimateComponent implements OnInit {
  topLimit = 12;
  favoriteEstimates: FavoriteEstimate[] | any;
  member: Member;
  type: 'write' | 'mypage';

  constructor(
    private navController: NavController,
    private db: DbService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.type = this.activatedRoute.snapshot.queryParams['type'];
  }

  async ionViewWillEnter() {
    this.member = await this.auth.getUser();
    await this.getData();
  }

  // 즐겨찾기 견적 데이터 불러오기
  async getData() {
    this.favoriteEstimates = await lastValueFrom(
      this.db
        .collection$('favoriteEstimates', (ref) =>
          ref
            .where('uuid', '==', this.member.uuid)
            .orderBy('dateCreated', 'desc')
        )
        .pipe(
          leftJoinDocument(this.db.firestore, 'estimateId', 'estimates'),
          take(1)
        )
    );
  }

  //상세
  goDetail(id: FavoriteEstimate['id']) {
    this.navController.navigateForward('/my-estimate-detail', {
      queryParams: {
        id,
        type: this.type,
      },
    });
  }

  //즐겨찾기 견적 추가
  goWrite() {
    this.navController.navigateForward('/my-estimate-write');
  }

  // 인피니티 스크롤
  loadData(ev) {
    setTimeout(() => {
      this.topLimit += 12;
      ev.target.complete();
    }, 500);
  }
}
