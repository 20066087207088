// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: transparent !important;
  background: transparent !important;
}
ion-content::part(background) {
  --background: transparent !important;
  background: transparent !important;
}

.btn-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.375rem;
}

.inner-container {
  padding: 0;
}
.inner-container .padding-container {
  width: 100%;
  min-height: -moz-fit-content;
  min-height: fit-content;
  border-radius: 8px 8px 0 0;
  background: #fff;
  padding: 0;
}
.inner-container .padding-container .calendar-wrap {
  padding: 0 1rem 3.625rem;
}

.calendar-info {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 400;
  color: var(--gray-scale900);
  margin-bottom: 1.5rem;
}
.calendar-info span {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 600;
  color: var(--gray-scale900);
}

.calendar-box {
  border: 1px solid var(--gray-scale100);
  border-radius: 6px;
  padding: 1rem 1.0938rem;
}

.modal-close {
  width: 2.75rem;
  height: 2.75rem;
  --background: url('modal-close-icon.png') no-repeat
    center / 100% 100%;
}

#custom-header {
  color: var(--gray-scale900);
}

.footer {
  padding: 1rem 1rem 2rem;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/calendar-pop/calendar-pop.component.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,kCAAA;AACF;AAAE;EACE,oCAAA;EACA,kCAAA;AAEJ;;AAEA;EACE,WAAA;EACA,aAAA;EACA,yBAAA;EACA,iBAAA;AACF;;AACA;EACE,UAAA;AAEF;AAAE;EACE,WAAA;EACA,4BAAA;EAAA,uBAAA;EACA,0BAAA;EACA,gBAAA;EACA,UAAA;AAEJ;AAAI;EACE,wBAAA;AAEN;;AAGA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,2BAAA;EACA,qBAAA;AAAF;AACE;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,2BAAA;AACJ;;AAGA;EACE,sCAAA;EACA,kBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,cAAA;EACA,eAAA;EACA;sBAAA;AACF;;AAGA;EACE,2BAAA;AAAF;;AAGA;EACE,uBAAA;EACA,WAAA;AAAF","sourcesContent":["ion-content {\n  --background: transparent !important;\n  background: transparent !important;\n  &::part(background) {\n    --background: transparent !important;\n    background: transparent !important;\n  }\n}\n\n.btn-wrap {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n  padding: 0.375rem;\n}\n.inner-container {\n  padding: 0;\n\n  .padding-container {\n    width: 100%;\n    min-height: fit-content;\n    border-radius: 8px 8px 0 0;\n    background: #fff;\n    padding: 0;\n\n    .calendar-wrap {\n      padding: 0 1rem 3.625rem;\n    }\n  }\n}\n\n.calendar-info {\n  font-size: 1.125rem;\n  line-height: 1.625rem;\n  font-weight: 400;\n  color: var(--gray-scale900);\n  margin-bottom: 1.5rem;\n  span {\n    font-size: 1.125rem;\n    line-height: 1.625rem;\n    font-weight: 600;\n    color: var(--gray-scale900);\n  }\n}\n\n.calendar-box {\n  border: 1px solid var(--gray-scale100);\n  border-radius: 6px;\n  padding: 1rem 1.0938rem;\n}\n\n.modal-close {\n  width: 2.75rem;\n  height: 2.75rem;\n  --background: url(../../../../assets/imgs/modal-close-icon.png) no-repeat\n    center / 100% 100%;\n}\n\n#custom-header {\n  color: var(--gray-scale900);\n}\n\n.footer {\n  padding: 1rem 1rem 2rem;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
