import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MemberService } from 'src/app/core/services/member.service';
import { PushService } from 'src/app/core/services/push.service';

@Component({
  selector: 'app-suspension',
  templateUrl: './suspension.component.html',
  styleUrls: ['./suspension.component.scss'],
})
export class SuspensionComponent implements OnInit {
  member: Member;

  constructor(
    private navController: NavController,
    private alertService: AlertService,
    private auth: AuthService,
    private loadingService: LoadingService,
    private pushService: PushService,
    private memberService: MemberService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    const params = this.activatedRoute.snapshot.queryParams['social'];
    this.member = params ? params : await this.auth.getUser();
  }

  // 로그아웃
  goLogout() {
    this.alertService.cancelOkBtn('로그아웃 하시겠어요?').then(async (ok) => {
      if (ok) {
        await this.loadingService.load();
        // push 제거
        const pushId = await this.pushService.getId();
        if (pushId && this.member?.pushId.includes(pushId.userId)) {
          await this.memberService.removePush(this.member.uuid, pushId.userId);
        }

        localStorage.removeItem('appleName');
        this.auth.logoutUser();
        this.loadingService.hide();
        sessionStorage.setItem('logoutUser', 'logoutUser');
        this.navController.navigateRoot(['/login'], {
          animationDirection: 'forward',
        });
        this.alertService.presentToast('로그아웃 했어요.');
      }
    });
  }

  // 탈퇴하기
  goWithdrawal() {
    this.navController.navigateForward('/withdrawal-reason', {
      queryParams: {
        type: 'suspend',
      },
    });
  }

  // 1:1 문의
  goQna() {
    this.navController.navigateForward('/qna-list');
  }
}
