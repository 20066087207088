import { Injectable } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular';
import { Storage, ref, uploadBytes, uploadString } from '@angular/fire/storage';
// import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
// import { File } from '@awesome-cordova-plugins/file/ngx';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { LoadingService } from './loading.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  constructor(
    private actionSheetController: ActionSheetController,
    private loadingService: LoadingService,
    private platform: Platform,
    // private mediaCapture: MediaCapture,
    // private file: File,
    private storage: Storage
  ) {}

  async selectMedia(parentPath: string): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      const actionSheet = await this.actionSheetController.create({
        cssClass: 'actionsheet-custom',
        buttons: [
          {
            text: '카메라',
            handler: async () => {
              const cameraUrl = await this.captureFromCamera(parentPath);
              resolve([cameraUrl]);
            },
          },
          {
            text: '갤러리',
            handler: () => {
              this.selectFromGallery(parentPath).then(resolve).catch(reject);
            },
          },
          {
            text: '취소',
            role: 'cancel',
          },
        ],
      });

      await actionSheet.present();
    });
  }

  // 카메라
  async captureFromCamera(parentPath: string): Promise<string> {
    try {
      const capturedImage = await Camera.getPhoto({
        quality: 60,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
      });

      const base64DataUrl = capturedImage.dataUrl;
      const fileName = `${this.generateFilename()}.jpeg`;
      const storageRef = ref(this.storage, `${parentPath}/${fileName}`);
      await uploadString(storageRef, base64DataUrl, 'data_url');
      const downloadUrl = `https://storage.googleapis.com/${storageRef.bucket}/${storageRef.fullPath}`;
      return downloadUrl;
    } catch (error) {
      console.error('Error capturing from camera:', error);
      throw error;
    }
  }

  // 갤러리
  async selectFromGallery(parentPath: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.accept = 'image/*,video/*';
      inputElement.multiple = true;

      inputElement.onchange = async (event: any) => {
        const files = event.target.files;
        const urls: string[] = [];

        for (const file of files) {
          const fileName =
            this.generateFilename() + '.' + file.name.split('.').pop();

          const storageRef = ref(this.storage, `${parentPath}/${fileName}`);

          const fileSnapshot = await uploadBytes(storageRef, file);

          const url = `https://storage.googleapis.com/${fileSnapshot.metadata.bucket}/${fileSnapshot.metadata.fullPath}`;
          urls.push(url);
        }

        resolve(urls);
      };

      inputElement.click();
    });
  }

  // async getMediaGallery(parentPath: string): Promise<any[]> {
  //   try {
  //     const result = await FilePicker.pickFiles({
  //       types: ['image/*', 'video/*'],
  //       multiple: true,
  //     });

  //     const files = result.files;
  //     const uploadPromises = files.map(async (fileData) => {
  //       try {
  //         const path = this.platform.is('ios')
  //           ? fileData.path
  //           : 'file://' + fileData.path;
  //         const fileEntry: any = await this.file.resolveLocalFilesystemUrl(
  //           path
  //         );

  //         return new Promise((resolve, reject) => {
  //           fileEntry.file(
  //             async (file: any) => {
  //               let blob: any;

  //               if (file.type.startsWith('video')) {
  //                 if (this.platform.is('android')) {
  //                   const blobParts = [];
  //                   const chunkSize = 1024 * 1024 * 10;
  //                   let offset = 0;

  //                   while (offset < file.size) {
  //                     const chunk = await this.readChunk(
  //                       file,
  //                       offset,
  //                       chunkSize
  //                     );
  //                     blobParts.push(chunk);
  //                     offset += chunkSize;
  //                   }

  //                   blob = new Blob(blobParts, { type: file.type });
  //                 }

  //                 if (this.platform.is('ios')) {
  //                   blob = await this.fileTobase64(file);
  //                 }
  //                 const fileName = this.generateFilename();
  //                 const storageRef = ref(
  //                   this.storage,
  //                   `${parentPath}/${fileName}` + '.mp4'
  //                 );

  //                 const metadata = { contentType: 'video/mp4' };

  //                 const snapshot = this.platform.is('ios')
  //                   ? await uploadString(storageRef, blob, 'data_url', metadata)
  //                   : await uploadBytes(storageRef, blob, metadata);

  //                 resolve({
  //                   type: 'video',
  //                   url: `https://storage.googleapis.com/${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`,
  //                 });
  //               } else {
  //                 const reader = new FileReader();
  //                 reader.onloadend = async () => {
  //                   const base64 = reader.result as string;
  //                   const fileName = this.generateFilename();
  //                   const storageRef = ref(
  //                     this.storage,
  //                     `${parentPath}/${fileName}` +
  //                       '.' +
  //                       file.type.split('/')[1]
  //                   );
  //                   const snapshot = await uploadString(
  //                     storageRef,
  //                     base64,
  //                     'data_url'
  //                   );
  //                   resolve({
  //                     type: 'image',
  //                     url: `https://storage.googleapis.com/${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`,
  //                   });
  //                 };
  //                 reader.readAsDataURL(file);
  //               }
  //             },
  //             (error) => {
  //               console.error('Error reading file:', error);
  //               reject(error);
  //             }
  //           );
  //         });
  //       } catch (error) {
  //         console.error('Error resolving file system URL:', error);
  //         return { type: 'error', error };
  //       }
  //     });

  //     return Promise.all(uploadPromises);
  //   } catch (error) {
  //     console.error('Error picking files:', error);
  //     return [];
  //   }
  // }

  // async getVideo(parentPath: string): Promise<string> {
  //   const videoData = await this.mediaCapture.captureVideo({
  //     quality: 0.5,
  //     limit: 1,
  //   });
  //   const path = this.platform.is('android')
  //     ? videoData[0].fullPath
  //     : 'file://' + videoData[0].fullPath;
  //   const fileEntry: any = await this.file.resolveLocalFilesystemUrl(path);

  //   return new Promise(async (resolve) => {
  //     fileEntry.file(async (file: any) => {
  //       const blobParts = [];
  //       const chunkSize = 1024 * 1024 * 10;
  //       let offset = 0;

  //       while (offset < file.size) {
  //         const chunk = await this.readChunk(file, offset, chunkSize);
  //         blobParts.push(chunk);
  //         offset += chunkSize;
  //       }

  //       const blob = new Blob(blobParts, { type: file.type });
  //       const fileName = this.generateFilename();
  //       const storageRef = ref(
  //         this.storage,
  //         `${parentPath}/${fileName}` + '.mp4'
  //       );
  //       const metadata = { contentType: 'video/mp4' };
  //       const snapshot = await uploadBytes(storageRef, blob, metadata);

  //       resolve(
  //         `https://storage.googleapis.com/${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`
  //       );
  //     });
  //   });
  // }

  // readChunk(file: any, offset: number, length: number): Promise<Blob> {
  //   return new Promise<Blob>((resolve) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const blob = new Blob([reader.result as ArrayBuffer]);
  //       resolve(blob);
  //     };
  //     const blob = file.slice(offset, offset + length);
  //     reader.readAsArrayBuffer(blob);
  //   });
  // }

  // fileTobase64(file): Promise<string> {
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result as string);
  //     reader.readAsDataURL(file);
  //   });
  // }

  generateFilename(): string {
    const length = 10;
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let text = '';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text + new Date().valueOf();
  }
}
