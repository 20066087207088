import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'chatDateFormat',
})
export class ChatDateFormatPipe implements PipeTransform {
  transform(date: string): string {
    const today = dayjs().startOf('day'); // 오늘 날짜의 시작 시간(00:00:00)
    const targetDate = dayjs(date).startOf('day'); // 비교할 날짜의 시작 시간(00:00:00)

    // 오늘과 같은 날인지 확인
    if (today.isSame(targetDate, 'day')) {
      return dayjs(date).format('HH:mm'); // 시간만 표시
    }
    // 어제인지 확인
    else if (today.subtract(1, 'day').isSame(targetDate, 'day')) {
      return '어제';
    }
    // 오늘도 어제도 아닌 경우
    else {
      return dayjs(date).format('MM월 DD일'); // 날짜만 표시
    }
  }
}
