// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-item {
  border: 1px solid var(--gray-scale100);
  border-radius: 6px;
  overflow: hidden;
  --inner-padding-end: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.check-item .check-box {
  --size: 1.5rem;
  --border-color: transparent;
  --border-color-checked: transparent !important;
  --background-checked: url('service-check.png')
    no-repeat center center / 100% 100%;
  --checkmark-width: 0;
}
.check-item ion-label {
  height: 3.5rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale750);
  padding: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 50px);
}
.check-item.item-checkbox-checked {
  border: 1px solid var(--main);
}
.check-item.item-checkbox-checked ion-label {
  color: var(--gray-scale750);
  font-weight: 700;
}

.search-wrap {
  margin-bottom: 1.5rem;
}

.footer-container {
  background: #ffffff;
}

ion-footer {
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);
}

.inner-container {
  padding-bottom: 2.3125rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/master/join-expert/join-expert.component.scss"],"names":[],"mappings":"AAAA;EACE,sCAAA;EACA,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;AACF;AACE;EACE,cAAA;EACA,2BAAA;EACA,8CAAA;EACA;uCAAA;EAEA,oBAAA;AACJ;AAEE;EACE,cAAA;EACA,WAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,wBAAA;AAAJ;AAGE;EACE,6BAAA;AADJ;AAEI;EACE,2BAAA;EACA,gBAAA;AAAN;;AAKA;EACE,qBAAA;AAFF;;AAKA;EACE,mBAAA;AAFF;;AAKA;EACE,4CAAA;AAFF;;AAKA;EACE,yBAAA;AAFF","sourcesContent":[".check-item {\n  border: 1px solid var(--gray-scale100);\n  border-radius: 6px;\n  overflow: hidden;\n  --inner-padding-end: 1rem;\n  margin-bottom: 1rem;\n  width: 100%;\n\n  .check-box {\n    --size: 1.5rem;\n    --border-color: transparent;\n    --border-color-checked: transparent !important;\n    --background-checked: url(../../../../assets/imgs/service-check.png)\n      no-repeat center center / 100% 100%;\n    --checkmark-width: 0;\n  }\n\n  ion-label {\n    height: 3.5rem;\n    width: 100%;\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 400;\n    color: var(--gray-scale750);\n    padding: 1rem;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    width: calc(100% - 50px);\n  }\n\n  &.item-checkbox-checked {\n    border: 1px solid var(--main);\n    ion-label {\n      color: var(--gray-scale750);\n      font-weight: 700;\n    }\n  }\n}\n\n.search-wrap {\n  margin-bottom: 1.5rem;\n}\n\n.footer-container {\n  background: #ffffff;\n}\n\nion-footer {\n  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);\n}\n\n.inner-container {\n  padding-bottom: 2.3125rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
