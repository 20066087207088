// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper {
  width: 100%;
  height: calc(61.5dvh + 1.75rem);
}
.swiper .swiper-wrapper {
  width: 100%;
  height: 61.5dvh;
}
.swiper .swiper-wrapper .swiper-slide {
  width: 100%;
  height: 100%;
}
.swiper .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inner {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  /* Status bar height on iOS 11.0 */
  padding-top: constant(safe-area-inset-top);
  /* Status bar height on iOS 11+ */
  padding-top: env(safe-area-inset-top);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/intro/intro.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,+BAAA;AACF;AACE;EACE,WAAA;EACA,eAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAAM;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAER;;AAIA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kCAAA;EACA,0CAAA;EACA,iCAAA;EACA,qCAAA;AADF","sourcesContent":[".swiper {\n  width: 100%;\n  height: calc(61.5dvh + 1.75rem);\n\n  .swiper-wrapper {\n    width: 100%;\n    height: 61.5dvh;\n\n    .swiper-slide {\n      width: 100%;\n      height: 100%;\n      img {\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n      }\n    }\n  }\n}\n\n.inner {\n  width: 100%;\n  height: 100%;\n  padding-top: 20px;\n  /* Status bar height on iOS 11.0 */\n  padding-top: constant(safe-area-inset-top);\n  /* Status bar height on iOS 11+ */\n  padding-top: env(safe-area-inset-top);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
