import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class InAppService {
  store?: CdvPurchase.Store;
  private orderState: boolean = false;
  private orderProduct: any;
  private paymentSuccessCallback: (
    membership: any,
    orderId: string
  ) => Promise<void>;
  products: CdvPurchase.Product[] = [];
  points = [];

  constructor(
    private platform: Platform,

    private loadingService: LoadingService
  ) {}

  restore() {
    this.store.restorePurchases();
  }

  async init(): Promise<void> {
    try {
      this.store = CdvPurchase.store;

      if (!this.store) {
        console.error('Store is not available');
        return;
      }

      this.store.ready(() => {
        this.products = this.store.products;
      });
      this.storeSetting();
      this.storeListener();
    } catch (error) {
      console.error('Error initializing store:', error);
    }
  }

  // 스토어 상품 세팅
  storeSetting() {
    try {
      this.store.verbosity = 4;
      // 각 상품에 대한 내용을 등록하기 위해서

      this.store.register([
        //* 애플 /
        // 포인트 결제
        {
          id: 'com.bansellin.60000cash.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.APPLE_APPSTORE,
          group: 'default',
        },
        {
          id: 'com.bansellin.80000cash.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.APPLE_APPSTORE,
          group: 'default',
        },
        {
          id: 'com.bansellin.100000cash.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.APPLE_APPSTORE,
          group: 'default',
        },
        // 보험료
        {
          id: 'com.bansellin.200orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE, // 소모품인 경우
          platform: CdvPurchase.Platform.APPLE_APPSTORE,
          group: 'default',
        },
        {
          id: 'com.bansellin.500orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.APPLE_APPSTORE,
          group: 'default',
        },
        {
          id: 'com.bansellin.700orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.APPLE_APPSTORE,
          group: 'default',
        },
        {
          id: 'com.bansellin.1000orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.APPLE_APPSTORE,
          group: 'default',
        },
        {
          id: 'com.bansellin.1300orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.APPLE_APPSTORE,
          group: 'default',
        },
        {
          id: 'com.bansellin.1500orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.APPLE_APPSTORE,
          group: 'default',
        },
        //* 구글 /
        {
          id: 'com.bansellin.60000cash.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.GOOGLE_PLAY,
          group: 'default',
        },
        {
          id: 'com.bansellin.80000cash.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.GOOGLE_PLAY,
          group: 'default',
        },
        {
          id: 'com.bansellin.100000cash.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.GOOGLE_PLAY,
          group: 'default',
        },
        // 보험료
        {
          id: 'com.bansellin.200orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE, // 소모품인 경우
          platform: CdvPurchase.Platform.GOOGLE_PLAY,
          group: 'default',
        },
        {
          id: 'com.bansellin.500orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.GOOGLE_PLAY,
          group: 'default',
        },
        {
          id: 'com.bansellin.700orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.GOOGLE_PLAY,
          group: 'default',
        },
        {
          id: 'com.bansellin.1000orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.GOOGLE_PLAY,
          group: 'default',
        },
        {
          id: 'com.bansellin.1300orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.GOOGLE_PLAY,
          group: 'default',
        },
        {
          id: 'com.bansellin.1500orless.krw',
          type: CdvPurchase.ProductType.CONSUMABLE,
          platform: CdvPurchase.Platform.GOOGLE_PLAY,
          group: 'default',
        },
      ]);
    } catch (err) {}
  }

  storeListener() {
    try {
      this.store
        .when()
        .receiptsReady(() => {
          CdvPurchase.store.get('com.bansellin.200orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.500orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.700orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.1000orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.1300orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.1500orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.60000cash.krw').owned;
          CdvPurchase.store.get('com.bansellin.80000cash.krw').owned;
          CdvPurchase.store.get('com.bansellin.100000cash.krw').owned;
        })
        .receiptsVerified(() => {
          CdvPurchase.store.get('com.bansellin.200orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.500orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.700orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.1000orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.1300orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.1500orless.krw').owned;
          CdvPurchase.store.get('com.bansellin.60000cash.krw').owned;
          CdvPurchase.store.get('com.bansellin.80000cash.krw').owned;
          CdvPurchase.store.get('com.bansellin.100000cash.krw').owned;
        })
        .approved((transaction) => {
          if (
            transaction.products &&
            transaction.products[0] &&
            transaction.products[0].id === 'com.banSellin.knc'
          ) {
            transaction.finish();
            return;
          }

          // 일반 결제 처리
          if (transaction.state === 'approved') {
            transaction.verify();
          }
        })
        .productUpdated((product) => this.updateUI(product))
        .verified((receipt) => receipt.finish())
        .finished(async (transaction: CdvPurchase.Transaction) => {
          let state: CdvPurchase.TransactionState = transaction.state;

          if (state === 'cancelled') {
            this.setState = false;
            this.loadingService.hide();
          }
          if (
            (state === 'approved' || state === 'finished') &&
            transaction.products &&
            transaction.products[0] &&
            transaction.products[0].id !== 'com.banSellin.knc'
          ) {
            let membership = this.points.find((e) => {
              return e.priceInfo.productId === transaction.products[0].id;
            });
            if (this.paymentSuccessCallback) {
              await this.paymentSuccessCallback(
                membership,
                transaction.transactionId
              );
            }
            this.store.restorePurchases();
          }
        });

      this.store.initialize();
    } catch (err) {}
  }

  // 결제 성공시 콜백 설정
  setPaymentSuccessCallback(callback: (data: any) => Promise<void>) {
    this.paymentSuccessCallback = callback;
  }

  get isDesktop(): boolean {
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      return true;
    } else {
      return false;
    }
  }

  get isAndroid(): boolean {
    return this.platform.is('android');
  }

  updateUI(product) {}

  get getState(): boolean {
    return this.orderState;
  }

  set setState(state: boolean) {
    this.orderState = state;
  }

  get getProduct(): any {
    return this.orderProduct;
  }

  set setProduct(product: any) {
    this.orderProduct = product;
  }
}
