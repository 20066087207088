// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choice {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 400;
  color: var(--gray-scale500);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/join-recommendation/join-recommendation.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,2BAAA;AACF","sourcesContent":[".choice {\n  font-size: 1.125rem;\n  line-height: 1.625rem;\n  font-weight: 400;\n  color: var(--gray-scale500);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
