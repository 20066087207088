import { Injectable } from '@angular/core';
const address = require('./address.json');

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  addressData: any;

  constructor() {
    this.addressData = Object.values(address);
  }

  // 시 데이터 불러오기
  getSi() {
    return this.addressData.map((v) => Object.values(v)[0]);
  }

  // 구 데이터 불러오기
  getGu(siData: string) {
    const result = this.addressData
      .filter((val: any) => val.si === siData)
      .map((v: any) => v.gu);
    return result[0].split(',');
  }
}
