import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { MemberService } from 'src/app/core/services/member.service';

@Component({
  selector: 'app-join-email',
  templateUrl: './join-email.component.html',
  styleUrls: ['./join-email.component.scss'],
})
export class JoinEmailComponent implements OnInit {
  member: Member;
  memberForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
    ]),
  });

  confirmEmailSwitch: boolean = false; // 중복검사 완료여부
  isconfirm: boolean = false;

  constructor(
    private alertService: AlertService,
    private navController: NavController,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.member = this.memberService.member;
    this.memberForm.get('email').setValue(this.member.email);
  }

  ionViewWillEnter() {
    this.isconfirm = false;
    const confirmChecking = localStorage.getItem('confirmEmail'); // 이메일 중복확인 했었는지
    if (confirmChecking) {
      this.isconfirm = true;
    }
  }

  //중복확인
  async confirmAlert() {
    const member = await this.memberService.emailCheck(
      this.memberForm.get('email').value
    ); // 중복 이메일 있는지 확인
    const emailOverlap = member?.length > 0; //중복검사 통과여부

    // 중복 이메일인 경우
    if (emailOverlap) {
      this.alertService.okBtn(
        '이미 가입된 계정이에요.\n 확인 후 다시 시도해주세요.'
      );
      this.confirmEmailSwitch = true;
      return;
    } else {
      this.alertService.okBtn('사용 가능한 이메일 주소 입니다.');
      this.confirmEmailSwitch = true;
      this.isconfirm = true;
      localStorage.setItem('confirmEmail', 'isconfirm');
    }
  }

  // [다음]
  goNexPassword() {
    // 값 저장
    const member = this.memberService.member;
    member.email = this.memberForm.get('email').value;
    this.navController.navigateForward('/join-password');
  }

  // 이메일 입력 값이 변경되는 경우
  onEmailChange() {
    this.confirmEmailSwitch = false;
    this.isconfirm = false;
    localStorage.removeItem('confirmEmail');
  }

  // [이전]
  prev() {
    // 값 저장
    const member = this.memberService.member;
    member.email = this.memberForm.get('email').value;
    this.navController.pop();
  }
}
