import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SubCategory } from 'src/app/core/models/category.model';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-service-select',
  templateUrl: './service-select.component.html',
  styleUrls: ['./service-select.component.scss'],
})
export class ServiceSelectComponent implements OnInit {
  member: Member;
  subCategories: SubCategory[];
  copyCategories: SubCategory[];
  keyword: string = '';
  selectedServices = {};

  isAnyCheckboxSelected() {
    return Object.values(this.selectedServices).includes(true);
  }

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private auth: AuthService,
    private db: DbService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    await this.getData();
    this.services();
  }

  // 선택된 서비스가 있다면 체크처리
  services() {
    this.selectedServices = {};
    if (this.member.services) {
      this.member.services.forEach((serviceId) => {
        this.selectedServices[serviceId] = true;
      });
    }
  }

  // 선택된 값의 id만
  trueIds(services: any): string[] {
    return Object.keys(services).filter((key) => services[key] === true);
  }

  // 서브카테고리 데이터 불러오기
  async getData() {
    this.subCategories = this.copyCategories = await this.db.toCollection$(
      'subCategories',
      (ref) =>
        ref.where('deleteSwitch', '==', false).orderBy('dateCreated', 'asc')
    );
  }

  // 검색
  goSearch(keyword: string) {
    this.subCategories = this.copyCategories.filter((val) =>
      val?.name.includes(keyword)
    );
  }
  //모달 닫기
  closeModal() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.modalController.dismiss();
        }
      });
  }

  // 체크된게 하나도 없으면 저장 못하도록 처리
  disabled(): boolean {
    return Object.values(this.selectedServices).some((value) => value === true);
  }

  //저장
  async save() {
    const serviceArr = this.trueIds(this.selectedServices);
    await this.db.updateAt(`members/${this.member.uuid}`, {
      services: serviceArr,
    });
    this.modalController.dismiss({
      serviceArr: serviceArr,
    });
    this.alertService.presentToast('제공 서비스를 업데이트 하였습니다.');
  }
}
