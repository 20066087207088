import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { Popup } from 'src/app/core/models/admin.model';

@Component({
  selector: 'app-home-pop',
  templateUrl: './home-pop.component.html',
  styleUrls: ['./home-pop.component.scss'],
})
export class HomePopComponent implements OnInit {
  popup: Popup;
  constructor(
    private dialogRef: MatDialogRef<HomePopComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Popup
  ) {}

  ngOnInit() {
    this.popup = this.data;
  }

  // [오늘 그만보기] / [닫기]
  close(close?: Boolean) {
    // 내일 오전 0시 구하기
    const noon = dayjs().add(1, 'day').startOf('day');

    if (close)
      localStorage.setItem(
        'popUpTime',
        dayjs(noon).format('YYYY-MM-DD HH:mm:ss')
      );
    this.dialogRef.close();
  }
}
