import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { increment } from 'firebase/firestore';
import { Post } from 'src/app/core/models/post.model';
import { CommunityService } from 'src/app/core/services/community.service';
import { DbService } from 'src/app/core/services/db.service';
import { Comment } from 'src/app/core/models/comment.model';

@Component({
  selector: 'app-my-post',
  templateUrl: './my-post.component.html',
  styleUrls: ['./my-post.component.scss'],
})
export class MyPostComponent implements OnInit {
  topLimit = 10;
  posts: Post[];
  comments: Comment[] | any;
  segmentValue = 'post';

  constructor(
    private navController: NavController,
    private db: DbService,
    private communityService: CommunityService
  ) {}

  ngOnInit() {}

  async ionViewWillEnter() {
    this.posts = await this.communityService.getMyPosts();
    this.comments = await this.communityService.getMyComments();
  }

  //게시글 상세로 이동
  async goPostDetail(postId: Post['id']) {
    // 조회수 증가
    await this.db.updateAt(`posts/${postId}`, {
      viewCount: increment(1),
    });
    this.navController.navigateForward('/post-detail', {
      queryParams: { postId },
    });
  }

  // 인피니티 스크롤
  loadData(ev) {
    setTimeout(() => {
      this.topLimit += 10;
      ev.target.complete();
    }, 500);
  }
}
