// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.input-wrap ion-input {
  width: calc(100% - 1.25rem - 5rem);
}

.main-line-btn {
  width: 5.0625rem;
  min-width: 5.0625rem;
  height: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.footer-container .main-line-btn {
  width: 7rem;
  min-width: 7rem;
  height: 3.25rem;
}
.footer-container .main-btn {
  width: calc(100% - 7rem - 0.375rem);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/application-address/application-address.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AACF;AAAE;EACE,kCAAA;AAEJ;;AACA;EACE,gBAAA;EACA,oBAAA;EACA,cAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AAEF;;AACA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AAEF;AAAE;EACE,WAAA;EACA,eAAA;EACA,eAAA;AAEJ;AACE;EACE,mCAAA;AACJ","sourcesContent":[".input-wrap {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 1.25rem;\n  ion-input {\n    width: calc(100% - 1.25rem - 5rem);\n  }\n}\n.main-line-btn {\n  width: 5.0625rem;\n  min-width: 5.0625rem;\n  height: 2.5rem;\n  font-size: 0.875rem;\n  line-height: 1.5rem;\n  font-weight: 700;\n}\n\n.footer-container {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 0.75rem;\n\n  .main-line-btn {\n    width: 7rem;\n    min-width: 7rem;\n    height: 3.25rem;\n  }\n\n  .main-btn {\n    width: calc(100% - 7rem - 0.375rem);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
