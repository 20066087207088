import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { lastValueFrom, map, take } from 'rxjs';
import { SubCategory, TopCategory } from 'src/app/core/models/category.model';
import { Member } from 'src/app/core/models/member.model';
import { PopularService } from 'src/app/core/models/popular-service.model';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  ArrayleftJoinDocument,
  DbService,
  leftJoinDocument,
} from 'src/app/core/services/db.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  member: Member;
  popularServices: PopularService[] | any;
  categories: TopCategory[] | any;
  searchCategories: TopCategory[];
  keyword: string = '';
  isSearch: boolean = false; // 검색 했는지 안했는지

  constructor(
    private navController: NavController,
    private db: DbService,
    private auth: AuthService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {}

  async ionViewWillEnter() {
    this.keyword = '';
    this.isSearch = false;
    this.member = await this.auth.getUser();
    await this.getData();
    await this.getServices();
  }

  // 인기 서비스 데이터 불러오기
  async getData() {
    this.popularServices = await lastValueFrom(
      this.db
        .collection$('popularServices', (ref) => ref.orderBy('index', 'asc'))
        .pipe(
          leftJoinDocument(this.db.firestore, 'topCategoryId', 'topCategories'),
          leftJoinDocument(this.db.firestore, 'subCategoryId', 'subCategories'),
          take(1)
        )
    );
  }

  // 대/소 카테고리 데이터 불러오기
  async getServices() {
    this.categories = await lastValueFrom(
      this.db
        .collection$('topCategories', (ref) =>
          ref.orderBy('dateCreated', 'desc')
        )
        .pipe(
          ArrayleftJoinDocument(this.db.firestore, 'subIds', 'subCategories'),
          map((categories: TopCategory[]) => {
            return categories.filter(
              (category: TopCategory) =>
                category.name !== '전체보기' &&
                category.name !== '인기 인테리어'
            );
          }),
          take(1)
        )
    );
  }

  // 검색
  async goSearch(keyword: string) {
    keyword = keyword?.toLowerCase().trim();
    if (keyword) {
      await this.loadingService.load();

      // 초기화
      this.searchCategories = [];
      this.isSearch = true;

      // 검색 필터
      this.categories.forEach((val: any) => {
        // 서브 카테고리 이름이 키워드에 포함되어 있으면 추가
        if (val.subIds) {
          val.subIds
            .filter(
              (v) => !v.deleteSwitch && v.name.toLowerCase().includes(keyword)
            )
            .forEach((sub) => {
              this.searchCategories.push({
                ...sub,
                type: 'sub',
                parentName: val.name,
              });
            });
        }
      });

      // 내림차순으로 정렬
      this.searchCategories.sort((a, b) => {
        return (
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
        );
      });

      // 검색결과 없을 시
      if (this.searchCategories?.length === 0) {
        this.loadingService.hide();
      }
      this.loadingService.hide();
    }
  }

  //요청서 작성으로
  goApplycation(topId: TopCategory['id'], subId: SubCategory['id']) {
    this.navController.navigateForward('/application-address', {
      queryParams: { topId, subId, requestType: 'search' },
    });
  }

  // 이전
  goBack() {
    this.navController.pop();
  }
}
