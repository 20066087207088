// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  width: 100%;
  padding: 1.5rem 1rem;
}

.no-list {
  height: calc(100vh - 9.375rem - 3rem);
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/my-like/my-like.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,oBAAA;AACF;;AAEA;EACE,qCAAA;EACA,WAAA;AACF","sourcesContent":[".list {\n  width: 100%;\n  padding: 1.5rem 1rem;\n}\n\n.no-list {\n  height: calc(100vh - 9.375rem - 3rem);\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
