import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss'],
})
export class ServiceDetailComponent implements OnInit {
  @Input() text: Member['serviceDetail'];
  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  //모달 닫기
  closeModal() {
    this.modalController.dismiss();
  }
}
