import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { lastValueFrom, map, of, switchMap, take } from 'rxjs';
import { SubCategory, TopCategory } from 'src/app/core/models/category.model';
import { ArrayJoin, DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-category-select-expert',
  templateUrl: './category-select-expert.component.html',
  styleUrls: ['./category-select-expert.component.scss'],
})
export class CategorySelectExpertComponent implements OnInit {
  topCategoryId: TopCategory['id'] = 'wWvbDPT4s48BZWpzt8Tp';
  categoryName: TopCategory['name'];
  categories: TopCategory[] | any;
  copyCategories: SubCategory[];
  keyword: string = '';

  expertUuid: string = '';

  constructor(
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private db: DbService
  ) {}

  async ngOnInit() {
    await this.getData();
  }

  // 넘어온 id에 해당하는 카테고리 불러오기
  async getData() {
    this.expertUuid = this.activatedRoute.snapshot.queryParams['expertUuid'];

    let services = (await this.db.toDoc$(`members/${this.expertUuid}`))
      ?.services;

    let categories = await this.db.toCollection$('subCategories', (ref) =>
      ref.where('deleteSwitch', '==', false).orderBy('dateCreated', 'desc')
    );

    this.categories = categories.filter((e) => services.includes(e.id));
  }

  // 검색
  goSearch() {
    this.navController.navigateForward('/search');
  }

  // 소카테고리 선택
  async goSelectApllication(sub: any) {
    this.navController.navigateForward('/application-address', {
      queryParams: {
        requestType: 'estimate',
        expertUuid: this.expertUuid,

        topId: sub.topCategoryId,
        subId: sub.id,
      },
    });
  }
}
