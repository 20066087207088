import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-join-complete',
  templateUrl: './join-complete.component.html',
  styleUrls: ['./join-complete.component.scss'],
})
export class JoinCompleteComponent implements OnInit {
  constructor(private navController: NavController) {}

  ngOnInit() {}

  //전문가 신청하기
  goJoinExpert() {
    this.navController.navigateForward('/join-expert');
  }

  //홈으로
  goHome() {
    this.navController.navigateRoot(['/tabs/home'], {
      animationDirection: 'forward',
    });
  }
}
