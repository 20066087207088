import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { lastValueFrom, map, of, switchMap, take } from 'rxjs';
import { SubCategory, TopCategory } from 'src/app/core/models/category.model';
import { ArrayJoin, DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss'],
})
export class CategorySelectComponent implements OnInit {
  topCategoryId: TopCategory['id'];
  categoryName: TopCategory['name'];
  categories: TopCategory[] | any;
  copyCategories: SubCategory[];
  keyword: string = '';

  constructor(
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private db: DbService
  ) {}

  async ngOnInit() {
    await this.getData();
  }

  // 넘어온 id에 해당하는 카테고리 불러오기
  async getData() {
    this.topCategoryId = this.activatedRoute.snapshot.queryParams['categoryId'];

    // 전체보기인 경우
    if (this.topCategoryId === 'wWvbDPT4s48BZWpzt8Tp') {
      this.categories = await this.db.toCollection$('subCategories', (ref) =>
        ref.where('deleteSwitch', '==', false).orderBy('dateCreated', 'desc')
      );
      this.categories.subIds = this.categories;
    } else {
      this.categories = await lastValueFrom(
        this.db.doc$(`topCategories/${this.topCategoryId}`).pipe(
          map((doc) => {
            // subIds가 없는경우
            if (!doc.subIds || doc.subIds.length === 0) {
              return { name: doc.name, categories: [] };
            }
            return doc;
          }),
          switchMap((result) => {
            if (result.categories) {
              return of(result);
            } else {
              return ArrayJoin(
                this.db.firestore,
                'subIds',
                'subCategories'
              )(of(result));
            }
          }),
          take(1)
        )
      );
    }
  }

  // 검색
  goSearch() {
    this.navController.navigateForward('/search');
  }

  // 소카테고리 선택
  async goSelectApllication(subId: SubCategory['id']) {
    // 전체보기 & 인기 인테리어로 진입한 경우
    if (
      this.topCategoryId === 'wWvbDPT4s48BZWpzt8Tp' ||
      this.topCategoryId === '78WLVKxIGXXgVGmmUzqg'
    ) {
      const data = await this.db.toDoc$(`subCategories/${subId}`);
      this.topCategoryId = data.topCategoryId;
    }

    this.navController.navigateForward('/application-address', {
      queryParams: {
        topId: this.topCategoryId,
        subId,
        requestType: 'home',
      },
    });
  }
}
