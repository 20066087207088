import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { MemberService } from 'src/app/core/services/member.service';

@Component({
  selector: 'app-join-password',
  templateUrl: './join-password.component.html',
  styleUrls: ['./join-password.component.scss'],
})
export class JoinPasswordComponent implements OnInit {
  member: Member;
  memberForm = new FormGroup({
    passwordGroup: new FormGroup(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      { validators: this.passwordMatchValidator }
    ),
  });

  //비밀번호 확인
  private passwordMatchValidator(group: FormGroup) {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    let result = password === confirmPassword ? null : { mismatch: true };

    return result;
  }

  constructor(
    private alertService: AlertService,
    private navController: NavController,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.member = this.memberService.member;
    this.memberForm
      .get('passwordGroup.password')
      .setValue(this.member.password);
    this.memberForm
      .get('passwordGroup.confirmPassword')
      .setValue(this.member.conrimePwd);
  }

  //추천인코드로 이동
  goNexRecommend() {
    // 값 저장
    const member = this.memberService.member;
    member.password = this.memberForm.get('passwordGroup.password').value;
    member.conrimePwd = this.memberForm.get(
      'passwordGroup.confirmPassword'
    ).value;

    this.navController.navigateForward('/join-recommendation');
  }

  // [이전]
  prev() {
    // 값 저장
    const member = this.memberService.member;
    member.password = this.memberForm.get('passwordGroup.password').value;
    member.conrimePwd = this.memberForm.get(
      'passwordGroup.confirmPassword'
    ).value;

    this.navController.pop();
  }
}
