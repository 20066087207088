// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.padding-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 5.25rem;
  height: 5.25rem;
  margin-bottom: 1.25rem;
}
.icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h2 {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 700;
  color: var(--gray-scale900);
  margin-bottom: 0.5rem;
}

p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--gray-scale750);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/master/join-expert-complete/join-expert-complete.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AACA;EACE,cAAA;EACA,eAAA;EACA,sBAAA;AAEF;AADE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAGJ;;AACA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,2BAAA;EACA,qBAAA;AAEF;;AACA;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;AAEF","sourcesContent":[".padding-container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.icon {\n  width: 5.25rem;\n  height: 5.25rem;\n  margin-bottom: 1.25rem;\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n\nh2 {\n  font-size: 1.125rem;\n  line-height: 1.625rem;\n  font-weight: 700;\n  color: var(--gray-scale900);\n  margin-bottom: 0.5rem;\n}\n\np {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 400;\n  color: var(--gray-scale750);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
