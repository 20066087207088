import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-shared',
  templateUrl: './accordion-shared.component.html',
  styleUrls: ['./accordion-shared.component.scss'],
})
export class AccordionSharedComponent implements OnInit {
  @Input() admin: any;
  constructor() {}

  ngOnInit() {}
}
