import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MemberService } from 'src/app/core/services/member.service';
import { PushService } from 'src/app/core/services/push.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit {
  member: Member;
  appAlarmSwitch: Member['appAlarmSwitch'];
  marketingSwitch: Member['marketingSwitch'];

  constructor(
    private navController: NavController,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private pushService: PushService,
    private auth: AuthService,
    private db: DbService,
    private memberService: MemberService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    this.appAlarmSwitch = this.member.appAlarmSwitch;
    this.marketingSwitch = this.member.marketingSwitch;
  }

  // 토글 변경
  async changeToggle(type: 'marketing' | 'alarm') {
    if (type === 'alarm') {
      await this.db.updateAt(`members/${this.member.uuid}`, {
        appAlarmSwitch: this.appAlarmSwitch ? true : false,
      });
    } else {
      await this.db.updateAt(`members/${this.member.uuid}`, {
        marketingSwitch: this.marketingSwitch ? true : false,
      });
    }
  }

  //개인정보처리방침 & 서비스 이용약관
  goTerms(type) {
    this.navController.navigateForward('/terms', {
      queryParams: { type },
    });
  }

  //비밀번호 변경
  goPassChange() {
    this.navController.navigateForward('/password-edit');
  }

  //로그아웃얼럿
  logout() {
    this.alertService.cancelOkBtn('로그아웃 하시겠어요?').then(async (ok) => {
      if (ok) {
        await this.loadingService.load();
        // push 제거
        const pushId = await this.pushService.getId();
        if (pushId && this.member?.pushId.includes(pushId.userId)) {
          await this.memberService.removePush(this.member.uuid, pushId.userId);
        }

        localStorage.removeItem('appleName');
        this.auth.logoutUser().then(() => {
          this.loadingService.hide();
          sessionStorage.setItem('logoutUser', 'logoutUser');
          setTimeout(() => {
            this.navController.navigateRoot(['/login'], {
              animationDirection: 'forward',
            });
            this.alertService.presentToast('로그아웃 했어요.');
          }, 200);
        });
      }
    });
  }

  //회원탈퇴 이유
  goWithdrawal() {
    this.navController.navigateForward('/withdrawal-reason', {
      queryParams: {
        type: 'setting',
      },
    });
  }
}
