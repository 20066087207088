import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { NavController, Platform } from '@ionic/angular';
import * as dayjs from 'dayjs';
import { increment } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { Point } from 'src/app/core/models/admin.model';
import { Banner } from 'src/app/core/models/banner.model';
import { Member } from 'src/app/core/models/member.model';
import { PointHistory } from 'src/app/core/models/point-history.model';
import { PointPayment } from 'src/app/core/models/point-payment.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { CommunityService } from 'src/app/core/services/community.service';
import { DbService } from 'src/app/core/services/db.service';
import { IamportService } from 'src/app/core/services/iamport.service';
import { InAppService } from 'src/app/core/services/in-app.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { SwiperService } from 'src/app/core/services/swiper.service';

@Component({
  selector: 'app-point-charge',
  templateUrl: './point-charge.component.html',
  styleUrls: ['./point-charge.component.scss'],
})
export class PointChargeComponent implements OnInit {
  store?: CdvPurchase.Store;
  isWeb: boolean;

  member: Member;
  member$: Observable<Member>;
  banners: Banner[];
  points = [];
  paymentInfo;

  constructor(
    private communityService: CommunityService,
    private db: DbService,
    private swiperService: SwiperService,
    public platform: Platform,
    private auth: AuthService,
    private commonService: CommonService,
    private navController: NavController,
    private inAppService: InAppService,
    private loadingService: LoadingService,
    private iamportService: IamportService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.store = CdvPurchase.store;
    if (!Capacitor.isNativePlatform()) {
      this.isWeb = true;
    } else {
      this.isWeb = false;
    }
  }

  async ionViewWillEnter() {
    this.member = await this.auth.getUser();
    this.member$ = this.auth.user$;
    await this.getBanners();
    const admin = await this.db.toDoc$(`admin/management`);

    if (this.isWeb) {
      this.points = admin.point;
    } else {
      this.points = admin.point.filter((val) => !val.id.includes('onlypg'));
    }

    // 자동결제 설정 금액
    if (this.member.autoChargeId) {
      const point = admin.point;
      this.paymentInfo = point.filter((v) => v.id === this.member.autoChargeId);
    }

    const params = this.activatedRoute.snapshot.queryParams;
    if (params && params.imp_success) {
      let id = localStorage.getItem('payId');
      let item = this.points.find((e) => e.id === id);
      await this.savePaymentData(item);
      localStorage.removeItem('payId');
    }

    setTimeout(async () => {
      if (this.banners && this.banners.length > 0) {
        await this.swiperService.destroyBannerSwiper();
        this.swiperService.bannerSwiperOptions('.pointSwiper');
      }
    }, 100);
  }

  // 배너 데이터 불러오기
  async getBanners() {
    this.banners = await this.communityService.getBanners('point', 'expert');
  }

  // 결제하기
  async payment(item: Point) {
    await this.loadingService.load();

    // 인앱결제
    if (!this.isWeb) {
      this.inAppService.setState = true;
      let productId = item.id;

      let product: CdvPurchase.Product = this.inAppService.products.find(
        (e) => e.id === productId && e.canPurchase
      );

      if (product) {
        // 결제 성공 후 처리할 콜백 함수 정의

        // inappCharge ,inappBonus
        this.inAppService.setPaymentSuccessCallback(async (data) => {
          try {
            let payment: PointPayment = {
              id: this.commonService.generateFilename(),
              uuid: this.member.uuid,
              dateCreated: new Date().toISOString(),
              point: item.inappCharge,
              bonusPoint: item.inappBonus,
              price:
                this.platform.is('ios') || this.platform.is('android')
                  ? item.inapp
                  : item.pg,
              method: '인앱 결제',
            };

            let bonus: PointHistory = {
              id: this.commonService.generateFilename(),
              uuid: this.member.uuid,
              paymentId: payment.id,
              dateCreated: new Date().toISOString(),
              point: 0,
              bonusPoint: item.inappBonus,
              usePoint: 0,
              price: item.inapp,
              type: '충전 보너스 포인트',
              acceptUuid: '',
            };

            // 포인트 내역 업데이트
            let pointHistory: PointHistory = {
              id: this.commonService.generateFilename(),
              uuid: this.member.uuid,
              paymentId: payment.id,
              dateCreated: new Date().toISOString(),
              point: item.inappCharge,
              bonusPoint: 0,
              usePoint: 0,
              price: item.inappBonus,
              type: '포인트 충전',
              acceptUuid: '',
            };

            // 관리자 대시보드 통계값 업데이트
            const id = dayjs().format('YYYYMMDD');
            const statisticsData = await this.db.toDoc$(`statistics/${id}`);

            await Promise.all([
              this.db.updateAt(`pointPayments/${payment.id}`, payment),
              this.db.updateAt(`members/${this.member.uuid}`, {
                point: increment(item.inappCharge),
                bonusPoint: increment(item.inappBonus),
              }),
              this.db.updateAt(`pointHistories/${bonus.id}`, bonus),
              this.db.updateAt(
                `pointHistories/${pointHistory.id}`,
                pointHistory
              ),
              this.db.updateAt(`statistics/${id}`, {
                id,
                date: {
                  year: dayjs().format('YYYY'),
                  month: dayjs().format('MM'),
                  date: dayjs().format('DD'),
                },
                joinMembersCount: statisticsData
                  ? statisticsData.joinMembersCount
                  : 0,
                exitMembersCount: statisticsData
                  ? statisticsData.exitMembersCount
                  : 0,
                pointPaymentCount: increment(item.inapp),
                pointRefundCount: statisticsData
                  ? statisticsData.pointRefundCount
                  : 0,
                insurancePaymentCount: statisticsData
                  ? statisticsData.insurancePaymentCount
                  : 0,
                insuranceRefundCount: statisticsData
                  ? statisticsData.insuranceRefundCount
                  : 0,
              }),
            ]);

            this.loadingService.hide();
            this.navController.pop();
          } catch (error) {
            this.loadingService.hide();
          }
        });

        try {
          this.loadingService.hide();
          await this.store.order(product.offers[0]);
        } catch (error) {
          this.loadingService.hide();
          if (error.isError) {
            console.log('결제실패');
          }
        }
      }
    }
    // PG결제
    else {
      try {
        localStorage.setItem('payId', item.id);
        const pay = await this.iamportService.payment(
          Number(item.pg),
          this.member.name,
          this.member.phone,
          this.member.email,
          'expert'
        );

        if (pay === true) {
          this.savePaymentData(item);
        } else {
          this.loadingService.hide();
        }
      } catch (error) {
        console.log('PG 결제실패', error);
        this.loadingService.hide();
      }
    }
  }

  // 포인트 pg결제 함수
  async savePaymentData(item: Point) {
    try {
      let payment: PointPayment = {
        id: this.commonService.generateFilename(),
        uuid: this.member.uuid,
        dateCreated: new Date().toISOString(),
        point: item.charge,
        bonusPoint: item.bonus,
        price: item.pg,
        method: 'PG 결제',
      };

      // 포인트 내역 업데이트
      let pointHistory: PointHistory = {
        id: this.commonService.generateFilename(),
        uuid: this.member.uuid,
        paymentId: payment.id,
        dateCreated: new Date().toISOString(),
        point: item.charge,
        bonusPoint: 0,
        usePoint: 0,
        price: item.pg,
        type: '포인트 충전',
        acceptUuid: '',
      };

      let bonus: PointHistory = {
        id: this.commonService.generateFilename(),
        uuid: this.member.uuid,
        paymentId: payment.id,
        dateCreated: new Date().toISOString(),
        point: 0,
        bonusPoint: item.bonus,
        usePoint: 0,
        price: item.pg,
        type: '충전 보너스 포인트',
        acceptUuid: '',
      };

      // 관리자 대시보드 통계값 업데이트
      const id = dayjs().format('YYYYMMDD');
      const statisticsData = await this.db.toDoc$(`statistics/${id}`);
      await Promise.all([
        this.db.updateAt(`pointPayments/${payment.id}`, payment),
        this.db.updateAt(`members/${this.member.uuid}`, {
          point: increment(item.charge),
          bonusPoint: increment(item.bonus),
        }),
        this.db.updateAt(`pointHistories/${bonus.id}`, bonus),
        this.db.updateAt(`pointHistories/${pointHistory.id}`, pointHistory),
        this.db.updateAt(`statistics/${id}`, {
          id,
          date: {
            year: dayjs().format('YYYY'),
            month: dayjs().format('MM'),
            date: dayjs().format('DD'),
          },
          joinMembersCount: statisticsData
            ? statisticsData.joinMembersCount
            : 0,
          exitMembersCount: statisticsData
            ? statisticsData.exitMembersCount
            : 0,
          pointPaymentCount: increment(item.pg),
          pointRefundCount: statisticsData
            ? statisticsData.pointRefundCount
            : 0,
          insurancePaymentCount: statisticsData
            ? statisticsData.insurancePaymentCount
            : 0,
          insuranceRefundCount: statisticsData
            ? statisticsData.insuranceRefundCount
            : 0,
        }),
      ]);

      this.loadingService.hide();
      this.navController.navigateRoot('/');
    } catch (error) {
      this.loadingService.hide();
    }
  }

  // 배너 url 이동
  goUrl(url: string) {
    if (url) {
      Browser.open({ url });
    }
  }

  //자동충전 설정 금액-자동 충전 금액 설정 안했을 시
  autoCharge() {
    this.navController.navigateForward('/automatic-charging');
  }

  //자동충전 설정 금액-자동 충전 금액 설정 했을 시
  autoChargeSet() {
    this.navController.navigateForward('/auto-recharge-setting');
  }
}
