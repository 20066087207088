// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-close {
  width: 2.75rem;
  height: 2.75rem;
  --background: url('modal-close-icon.png') no-repeat
    center / 100% 100%;
}

.title-sub {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--gray-scale500);
}

.career-select {
  width: 100%;
  border-bottom: 1px solid #e7e8e9;
  padding: 0.625rem 0.5rem;
  --background: url('select-picker-icon.png') no-repeat
    center right 0.5rem / 1.5rem 1.5rem;
  text-align: left !important;
}
.career-select p {
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale500);
  text-align: left !important;
}
.career-select p.blk {
  color: var(--gray-scale900);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/master/career-select/career-select.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA;sBAAA;AAEF;;AACA;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;AAEF;;AACA;EACE,WAAA;EACA,gCAAA;EACA,wBAAA;EACA;uCAAA;EAEA,2BAAA;AAEF;AADE;EACE,WAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,2BAAA;AAGJ;AADI;EACE,2BAAA;AAGN","sourcesContent":[".modal-close {\n  width: 2.75rem;\n  height: 2.75rem;\n  --background: url(../../../../assets/imgs/modal-close-icon.png) no-repeat\n    center / 100% 100%;\n}\n.title-sub {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 400;\n  color: var(--gray-scale500);\n}\n\n.career-select {\n  width: 100%;\n  border-bottom: 1px solid #e7e8e9;\n  padding: 0.625rem 0.5rem;\n  --background: url(../../../../assets/imgs/select-picker-icon.png) no-repeat\n    center right 0.5rem / 1.5rem 1.5rem;\n  text-align: left !important;\n  p {\n    width: 100%;\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 400;\n    color: var(--gray-scale500);\n    text-align: left !important;\n\n    &.blk {\n      color: var(--gray-scale900);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
