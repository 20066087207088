import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import * as dayjs from 'dayjs';
import { increment } from 'firebase/firestore';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MemberService } from 'src/app/core/services/member.service';
import { PushService } from 'src/app/core/services/push.service';

@Component({
  selector: 'app-withdrawal-check',
  templateUrl: './withdrawal-check.component.html',
  styleUrls: ['./withdrawal-check.component.scss'],
})
export class WithdrawalCheckComponent implements OnInit {
  withdrawalValue: string = '';
  content: string = '';

  memberForm = new FormGroup({
    passwordGroup: new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    }),
  });
  constructor(
    private alertService: AlertService,
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private db: DbService,
    private pushService: PushService,
    private memberService: MemberService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.withdrawalValue =
      this.activatedRoute.snapshot.queryParams.withdrawalValue;
    this.content = this.activatedRoute.snapshot.queryParams.content;
  }

  //회원탈퇴
  async withdrawalAlert() {
    const member = await this.auth.getUser();

    //1. 탈퇴성공
    this.alertService.cancelOkBtn('회원탈퇴를 진행합니다.').then((ok) => {
      if (ok) {
        this.auth
          .loginUser({
            email: member.email,
            password: this.memberForm.get('passwordGroup.password').value,
          })
          .then(async () => {
            await this.loadingService.load();

            // 푸시 제거
            const pushId = await this.pushService.getId();
            if (pushId && member.pushId.includes(pushId.userId)) {
              await this.memberService.removePush(member.uuid, pushId.userId);
            }

            // 관리자 대시보드 통계값 업데이트
            const id = dayjs().format('YYYYMMDD');
            const statisticsData = await this.db.toDoc$(`statistics/${id}`);
            await this.db.updateAt(`statistics/${id}`, {
              id,
              date: {
                year: dayjs().format('YYYY'),
                month: dayjs().format('MM'),
                date: dayjs().format('DD'),
              },
              joinMembersCount: statisticsData
                ? statisticsData.joinMembersCount
                : 0,
              exitMembersCount: increment(1),
              pointPaymentCount: statisticsData
                ? statisticsData.pointPaymentCount
                : 0,
              pointRefundCount: statisticsData
                ? statisticsData.pointRefundCount
                : 0,
              insurancePaymentCount: statisticsData
                ? statisticsData.insurancePaymentCount
                : 0,
              insuranceRefundCount: statisticsData
                ? statisticsData.insuranceRefundCount
                : 0,
            });

            // 탈퇴처리
            await this.db.updateAt(`members/${member.uuid}`, {
              exitReason: {
                category: this.withdrawalValue,
                detail: this.content,
              },
              exitDate: new Date().toISOString(),
              pushId: [],
              status: 'exit',
            });

            await this.auth.exitUser();
            this.loadingService.hide();
            this.navController.navigateRoot(['/login'], {
              animationDirection: 'forward',
            });
            this.alertService.presentToast('회원탈퇴 했습니다.');
          })
          .catch((err) => {
            if (err) {
              this.alertService.okBtn(
                '비밀번호가 일치하지 않습니다. 확인 후 다시 입력해주세요.'
              );
            }
          });
      }
    });
  }
}
