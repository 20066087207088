// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDsh3KXA3_uQuQb4nqVj3uYT0EpE23P8vU',
    authDomain: 'banseliin.firebaseapp.com',
    projectId: 'banseliin',
    storageBucket: 'banseliin.appspot.com',
    messagingSenderId: '919141513204',
    appId: '1:919141513204:web:adf9ee58df8920b75e2ba1',
    measurementId: 'G-PFGMFS4MKQ',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
