// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: #f4f4f4;
}

.no-list p {
  color: #7a8186;
}

.inner-container {
  width: 100%;
}

.transaction-item {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  padding: 1rem;
}
.transaction-item .name {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--gray-scale900);
  margin-bottom: 0.75rem;
}
.transaction-item .txt {
  width: 100%;
  padding-top: 0.75rem;
  border-top: 1px solid var(--gray-scale100);
}

.row-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row-flex .tit {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--gray-scale500);
}
.row-flex .con {
  text-align: right;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: var(--gray-scale750);
  width: 60%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/master/transaction-list/transaction-list.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAGE;EACE,cAAA;AAAJ;;AAIA;EACE,WAAA;AADF;;AAGA;EACE,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;AAAF;AACE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,sBAAA;AACJ;AAEE;EACE,WAAA;EACA,oBAAA;EACA,0CAAA;AAAJ;;AAIA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AADF;AAEE;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;AAAJ;AAGE;EACE,iBAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;EACA,UAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AADJ","sourcesContent":["ion-content {\n  --background: #f4f4f4;\n}\n\n.no-list {\n  p {\n    color: #7a8186;\n  }\n}\n\n.inner-container {\n  width: 100%;\n}\n.transaction-item {\n  width: 100%;\n  background-color: #fff;\n  border-radius: 6px;\n  padding: 1rem;\n  .name {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 700;\n    color: var(--gray-scale900);\n    margin-bottom: 0.75rem;\n  }\n\n  .txt {\n    width: 100%;\n    padding-top: 0.75rem;\n    border-top: 1px solid var(--gray-scale100);\n  }\n}\n\n.row-flex {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  .tit {\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 400;\n    color: var(--gray-scale500);\n  }\n\n  .con {\n    text-align: right;\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 500;\n    color: var(--gray-scale750);\n    width: 60%;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
