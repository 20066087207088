import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Faq } from 'src/app/core/models/faq.model';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-faq-detail',
  templateUrl: './faq-detail.component.html',
  styleUrls: ['./faq-detail.component.scss'],
})
export class FaqDetailComponent implements OnInit {
  faq: Faq;

  constructor(private activatedRoute: ActivatedRoute, private db: DbService) {}

  // 공지사항 데이터 불러오기
  async ngOnInit() {
    const id = this.activatedRoute.snapshot.queryParams['id'];
    this.faq = await this.db.toDoc$(`faqs/${id}`);
  }
}
