import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Chat } from 'src/app/core/models/chat.model';
import { Member } from 'src/app/core/models/member.model';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.scss'],
})
export class ChatCardComponent implements OnInit {
  @Input() item: Chat;
  member: Member;

  constructor(
    private navController: NavController,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
  }

  goChatDetail(chatId: Chat['id']) {
    this.navController.navigateForward('/chat-detail', {
      queryParams: {
        chatId: chatId,
      },
    });
  }
}
