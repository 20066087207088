import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { MemberService } from 'src/app/core/services/member.service';

@Component({
  selector: 'app-join-recommendation',
  templateUrl: './join-recommendation.component.html',
  styleUrls: ['./join-recommendation.component.scss'],
})
export class JoinRecommendationComponent implements OnInit {
  member: Member;
  memberForm = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });
  constructor(
    private navContoller: NavController,
    private alertService: AlertService,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.member = this.memberService.member;
    this.memberForm.get('code').setValue(this.member.recommendCode);
  }

  //약관 동의 페이지로
  async goAgree() {
    // 존재하는 코드인지 확인처리
    const codeCheck = await this.memberService.codeCheck(
      this.memberForm.get('code').value
    );

    if (!this.memberForm.get('code').value) {
      this.navContoller.navigateForward('/join-agree');
    }

    if (this.memberForm.get('code').value?.trim() && codeCheck.length === 0) {
      this.alertService.okBtn(
        '일치한 추천인 코드가 없습니다.\n확인 후 다시 입력해주세요.'
      );
      return;
    } else {
      // 값 저장
      const member = this.memberService.member;
      member.recommendCode = this.memberForm.get('code').value;
      this.navContoller.navigateForward('/join-agree');
    }
  }

  // [이전]
  prev() {
    // 값 저장
    const member = this.memberService.member;
    member.recommendCode = this.memberForm.get('code').value;
    this.member.loginType.includes('email')
      ? this.navContoller.pop()
      : this.navContoller.navigateForward('/join-name');
  }
}
