import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { lastValueFrom, take } from 'rxjs';
import { Inquiry } from 'src/app/core/models/inquiry.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { DbService, docJoin } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-qna-detail',
  templateUrl: './qna-detail.component.html',
  styleUrls: ['./qna-detail.component.scss'],
})
export class QnaDetailComponent implements OnInit {
  inquiry: Inquiry;
  constructor(
    private alertService: AlertService,
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private db: DbService
  ) {}

  async ngOnInit() {
    await this.getInquiry();
  }

  // 문의 데이터 불러오기
  async getInquiry() {
    const id = this.activatedRoute.snapshot.queryParams['id'];
    this.inquiry = await lastValueFrom(
      this.db
        .doc$(`inquiries/${id}`)
        .pipe(docJoin(this.db.firestore, 'uuid', 'members'), take(1))
    );
  }

  //삭제
  deleteQnaAlert() {
    this.alertService.cancelOkBtn('문의를 삭제하시겠어요?').then(async (ok) => {
      if (ok) {
        await this.db.delete(`inquiries/${this.inquiry.id}`);
        this.navController.pop();
      }
    });
  }
}
