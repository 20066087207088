// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-container {
  width: 100%;
  height: 35dvh;
}
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/faq-detail/faq-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAEJ","sourcesContent":[".img-container {\n  width: 100%;\n  height: 35dvh;\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
