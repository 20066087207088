// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-close {
  width: 2.75rem;
  height: 2.75rem;
  --background: url('modal-close-icon.png') no-repeat
    center / 100% 100%;
}

.swiper {
  width: 100%;
  height: 29vh;
  margin-bottom: 1.5rem;
}
.swiper .swiper-wrapper,
.swiper .swiper-slide {
  width: 100%;
  height: 100%;
}
.swiper .swiper-slide {
  border-radius: 6px;
  overflow: hidden;
}
.swiper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  padding-bottom: 0.75rem;
  width: 100%;
  border-bottom: 1px solid var(--gray-scale100);
  margin-bottom: 1rem;
}
.title h3 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--gray-scale900);
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale900);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/portfolio-detail/portfolio-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA;sBAAA;AAEF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,qBAAA;AACF;AAAE;;EAEE,WAAA;EACA,YAAA;AAEJ;AACE;EACE,kBAAA;EACA,gBAAA;AACJ;AAAI;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAEN;;AAGA;EACE,uBAAA;EACA,WAAA;EACA,6CAAA;EACA,mBAAA;AAAF;AAEE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AAAJ;;AAIA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AADF","sourcesContent":[".modal-close {\n  width: 2.75rem;\n  height: 2.75rem;\n  --background: url(../../../../assets/imgs/modal-close-icon.png) no-repeat\n    center / 100% 100%;\n}\n\n.swiper {\n  width: 100%;\n  height: 29vh;\n  margin-bottom: 1.5rem;\n  .swiper-wrapper,\n  .swiper-slide {\n    width: 100%;\n    height: 100%;\n  }\n\n  .swiper-slide {\n    border-radius: 6px;\n    overflow: hidden;\n    img {\n      width: 100%;\n      height: 100%;\n      object-fit: cover;\n    }\n  }\n}\n\n.title {\n  padding-bottom: 0.75rem;\n  width: 100%;\n  border-bottom: 1px solid var(--gray-scale100);\n  margin-bottom: 1rem;\n\n  h3 {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 700;\n    color: var(--gray-scale900);\n  }\n}\n\np {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  font-weight: 400;\n  color: var(--gray-scale900);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
