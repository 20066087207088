import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { MemberService } from 'src/app/core/services/member.service';

@Component({
  selector: 'app-find-password',
  templateUrl: './find-password.component.html',
  styleUrls: ['./find-password.component.scss'],
})
export class FindPasswordComponent implements OnInit {
  memberForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
    ]),
  });
  constructor(
    private alertService: AlertService,
    private navController: NavController,
    private memberService: MemberService,
    private auth: AuthService
  ) {}

  ngOnInit() {}

  //비밀번호 재설정 확인
  async confirmAlert() {
    const useUser = await this.memberService.emailCheck(
      this.memberForm.get('email').value
    );

    if (useUser?.length === 0) {
      this.alertService.okBtn(
        '일치하는 이메일 계정 정보가 없습니다.\n다시 한번 확인 후 입력해 주세요.'
      );
      return;
    } else {
      this.auth.sendPasswordReset(this.memberForm.get('email').value);
      this.alertService
        .okBtn(
          '입력한 이메일로 비밀번호 재설정 링크를 전송했습니다.\n 받지 못한 경우 스팸함을 확인해주세요.',
          '',
          '확인(로그인 화면으로 이동)'
        )
        .then((ok) => {
          if (ok) {
            this.navController.navigateForward('/login');
          }
        });
    }
  }
}
