// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.padding-container {
  padding: 1.5rem 1rem;
}
.padding-container p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale900);
  word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/terms/terms.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAAE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,qBAAA;AAEJ","sourcesContent":[".padding-container {\n  padding: 1.5rem 1rem;\n  p {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 400;\n    color: var(--gray-scale900);\n    word-break: break-all;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
