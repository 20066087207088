import { Injectable } from '@angular/core';
import { Request } from '../models/request.model';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  request: Request; // 견적 요청 정보
  // 데이터 초기화
  initRequest: Request = {
    id: '',
    dateCreated: '',
    uuid: '',
    topCategoryId: '',
    topCategoryName: '',
    subCategoryId: '',
    address: '',
    addressDetail: '',
    addressSiGu: { si: '', gu: '' },
    buildingType: '',
    hopeDate: '',
    pyeong: null,
    part: '',
    images: [],
    question: '',
    isDesignate: false,
    estimateCount: 0,
    expertUuid: '',
    estimateId: '',
    dealStatus: '거래 전',
    isDelete: false,
    senders: [],
    estimateStatus: '상담 진행 대기중',
    deleteExperts: [],
    receiveExpertsUuid: [],
    confirmDealDate: '',
  };

  constructor() {
    this.request = JSON.parse(JSON.stringify(this.initRequest));
  }

  // 등록된 데이터를 서비스 내에서 지우기 위해서
  clearData() {
    this.request = JSON.parse(JSON.stringify(this.initRequest));
  }
}
