import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DbService } from 'src/app/core/services/db.service';
import { ExpertService } from 'src/app/core/services/expert.service';
import { ImageService } from 'src/app/core/services/image.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { postcode } from 'src/assets/js/postcode.js';
import { Notification } from 'src/app/core/models/notification.model';

@Component({
  selector: 'app-join-expert-add-info02',
  templateUrl: './join-expert-add-info02.component.html',
  styleUrls: ['./join-expert-add-info02.component.scss'],
})
export class JoinExpertAddInfo02Component implements OnInit {
  //다음 api
  @ViewChild('address_pop', { read: ElementRef, static: true })
  popup!: ElementRef;
  sido: string = '';
  sigungu: string = '';
  member: Member;
  type: 'mypage' | 'join';
  businessLicense: string;
  data: any;
  memberForm = new FormGroup({
    tradeName: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),

    ownerName: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),

    businessNumber: new FormControl('', [Validators.required]),

    businessAddress: new FormControl('', [Validators.required]),
    detailAddress: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
  });

  constructor(
    private renderer: Renderer2,
    private alertService: AlertService,
    private navController: NavController,
    private imgService: ImageService,
    private modalController: ModalController,
    private expertService: ExpertService,
    private auth: AuthService,
    private db: DbService,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService
  ) {
    this.type = activatedRoute.snapshot.queryParams['type'];
  }

  async ngOnInit() {
    if (this.type === 'mypage') {
      this.member = await this.auth.getUser();
    } else {
      this.data = this.expertService.getSelectedServices();
    }

    this.memberForm
      .get('tradeName')
      .setValue(
        this.type === 'mypage'
          ? this.member.expertInfo.companyName
          : this.data.tradeName
      );
    this.memberForm
      .get('ownerName')
      .setValue(
        this.type === 'mypage'
          ? this.member.expertInfo.ceo
          : this.data.ownerName
      );
    this.memberForm
      .get('businessNumber')
      .setValue(
        this.type === 'mypage'
          ? this.member.expertInfo.businessNumber
          : this.data.businessNumber
      );
    this.memberForm
      .get('businessAddress')
      .setValue(
        this.type === 'mypage'
          ? this.member.expertInfo.companyAddress
          : this.data.businessAddress
      );
    this.memberForm
      .get('detailAddress')
      .setValue(
        this.type === 'mypage'
          ? this.member.expertInfo.companyAddressDetail
          : this.data.detailAddress
      );
    this.businessLicense =
      this.type === 'mypage'
        ? this.member.expertInfo.businessLicense
        : this.data.image;
  }

  ngOnDestroy() {
    if (this.type != 'mypage') {
      this.expertService.setSelectedServices({
        tradeName: this.memberForm.get('tradeName').value,
        ownerName: this.memberForm.get('ownerName').value,
        businessNumber: this.memberForm.get('businessNumber').value,
        businessAddress: this.memberForm.get('businessAddress').value,
        detailAddress: this.memberForm.get('detailAddress').value,
        image: this.businessLicense,
        sido: this.sido,
        sigungu: this.sigungu,
      });
    }
  }

  //모달닫기
  closeModal() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.modalController.dismiss();
        }
      });
  }

  // 사업자등록번호 벨리데이션
  isBusinessNumberInvalid(): boolean {
    const businessNumber = this.memberForm.get('businessNumber')?.value || '';
    return (
      businessNumber.length > 0 &&
      (businessNumber.length < 10 || businessNumber.length > 10)
    );
  }

  //주소찾기 api 열기
  openDaumPopup() {
    setTimeout(() => {
      this.getAddress().then((data) => {
        this.memberForm.get('businessAddress').setValue(data.address);
        this.sido = data.sido;
        this.sigungu = data.sigungu;
      });
    }, 1000);
  }
  getAddress(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      postcode(this.renderer, this.popup.nativeElement, (data) => {
        resolve(data);
      });
    });
  }

  //주소팝업 닫기
  closeAddressPopup() {
    this.renderer.setStyle(this.popup.nativeElement, 'display', 'none');
  }

  //지역선택
  goSelctAddress() {
    this.navController.navigateForward('/select-region');
  }

  //사진선택
  async addImg() {
    const imgUrl = await this.imgService.selectCamera('businessLicense');
    this.businessLicense = imgUrl;
  }

  // 비활성화 조건
  disabled() {
    if (
      !this.memberForm.get('tradeName').value ||
      this.memberForm.get('tradeName').hasError('minlength') ||
      !this.memberForm.get('ownerName').value ||
      this.memberForm.get('ownerName').hasError('minlength') ||
      !this.memberForm.get('businessNumber').value ||
      !this.memberForm.get('businessAddress').value ||
      !this.memberForm.get('detailAddress').value ||
      this.memberForm.get('detailAddress').hasError('minlength') ||
      !this.businessLicense ||
      this.isBusinessNumberInvalid()
    ) {
      return true;
    } else {
      return false;
    }
  }

  // 저장
  async save() {
    await this.db.updateAt(`members/${this.member.uuid}`, {
      expertInfo: {
        companyName: this.memberForm.get('tradeName').value,
        ceo: this.memberForm.get('ownerName').value,
        businessNumber: this.memberForm.get('businessNumber').value,
        companyAddress: this.memberForm.get('businessAddress').value,
        companyAddressDetail: this.memberForm.get('detailAddress').value,
        businessLicense: this.businessLicense,
        sido: this.sido,
        sigungu: this.sigungu,
      },
    });
    this.modalController.dismiss({
      expertInfo: {
        companyName: this.memberForm.get('tradeName').value,
        ceo: this.memberForm.get('ownerName').value,
        businessNumber: this.memberForm.get('businessNumber').value,
        companyAddress: this.memberForm.get('businessAddress').value,
        companyAddressDetail: this.memberForm.get('detailAddress').value,
        businessLicense: this.businessLicense,
        sido: this.sido,
        sigungu: this.sigungu,
      },
    });
    this.alertService.presentToast('사업자등록증을 업데이트 하였습니다.');
  }

  //전문가 신청하기
  async applyAlert() {
    const member = await this.auth.getUser();
    this.alertService
      .cancelOkBtn(
        '전문가 신청하시겠습니까?.\n 전문가 신청 시 관리자 승인 후 로그인 가능합니다.'
      )
      .then(async (ok) => {
        if (ok) {
          await this.loadingService.load();
          await this.db.updateAt(`members/${member.uuid}`, {
            applicationDate: new Date().toISOString(),
            gender: this.data.gender,
            phone: this.data.phone,
            services: this.data.subIds,
            address: this.data.address,
            addressDetail: this.data.addressDetail,
            addressSiGu: {
              si: this.data.addressSiGu.si,
              gu: this.data.addressSiGu.gu,
            },
            expertInfo: {
              companyName: this.memberForm.get('tradeName').value,
              ceo: this.memberForm.get('ownerName').value,
              businessNumber: this.memberForm.get('businessNumber').value,
              companyAddress: this.memberForm.get('businessAddress').value,
              companyAddressDetail: this.memberForm.get('detailAddress').value,
              businessLicense: this.businessLicense,
              sido: this.sido,
              sigungu: this.sigungu,
            },
            areas: this.data.selectAreas,
          });
          this.sendAlarm();
          this.expertService.clearData();
          this.loadingService.hide();
          this.navController.navigateForward('/join-expert-complete');
        }
      });
  }

  // 관리자에게 알림 전송
  async sendAlarm() {
    // 알림
    let notifications: Notification = {
      id: this.commonService.generateFilename(),
      uuid: 'admin',
      dateCreated: new Date().toISOString(),
      readSwitch: false,
      title: '전문가 등록',
      content: '전문가 등록이 도착했습니다.',
      url: '/expert-approval-list',
      checkSwitch: false,
      isAdminPush: false,
    };
    await this.db.updateAt(`notifications/${notifications.id}`, notifications);
  }

  //닫기
  backAlert() {
    if (this.type != 'mypage') {
      this.navController.pop();
    } else {
      this.alertService
        .cancelOkBtn(
          '입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?'
        )
        .then((ok) => {
          if (ok) {
            this.modalController.dismiss();
          }
        });
    }
  }
}
