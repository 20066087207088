import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-automatic-charging',
  templateUrl: './automatic-charging.component.html',
  styleUrls: ['./automatic-charging.component.scss'],
})
export class AutomaticChargingComponent implements OnInit {
  member: Member;
  points = [];
  isWeb: boolean;
  selectId: string = ''; // 선택한 값의 Id

  constructor(
    private navController: NavController,
    private auth: AuthService,
    private db: DbService
  ) {}

  ngOnInit() {
    if (!Capacitor.isNativePlatform()) {
      this.isWeb = true;
    } else {
      this.isWeb = false;
    }
  }

  async ionViewWillEnter() {
    this.member = await this.auth.getUser();
    const admin = await this.db.toDoc$(`admin/management`);

    if (this.isWeb) {
      this.points = admin.point;
    } else {
      this.points = admin.point.filter((val) => !val.id.includes('onlypg'));
    }
  }

  //다음 클릭 시 결제 카드 등록으로 이동
  goPayment() {
    this.navController.navigateForward('/payment-regist', {
      queryParams: {
        selectId: this.selectId,
      },
    });
  }
}
