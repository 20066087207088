// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-btn {
  width: 3rem;
  height: 2.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale500);
  --color: var(--gray-scale500);
}

.qna-container {
  width: 100%;
  padding: 1.5rem 1rem;
}

.category-wrap {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 1.25rem;
}
.category-wrap .category {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--gray-scale500);
}
.category-wrap .icon {
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  background: url('qna-icon.png') no-repeat center/100% 100%;
}
.category-wrap .select {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: var(--gray-scale750);
}

.title-wrap {
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e7e8e9;
  margin-bottom: 1rem;
}
.title-wrap p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--gray-scale900);
}

.content {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale750);
}

.padding-bottom {
  padding-bottom: 5rem;
}

.no-list {
  height: 40vh;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/qna-detail/qna-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,6BAAA;AACF;;AAEA;EACE,WAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,sBAAA;AACF;AAAE;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;AAEJ;AACE;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,0DAAA;AACJ;AAGE;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;AADJ;;AAKA;EACE,uBAAA;EACA,gCAAA;EACA,mBAAA;AAFF;AAGE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AADJ;;AAKA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AAFF;;AAKA;EACE,oBAAA;AAFF;;AAKA;EACE,YAAA;AAFF","sourcesContent":[".delete-btn {\n  width: 3rem;\n  height: 2.75rem;\n  font-size: 1rem;\n  line-height: 1.5rem;\n  font-weight: 400;\n  color: var(--gray-scale500);\n  --color: var(--gray-scale500);\n}\n\n.qna-container {\n  width: 100%;\n  padding: 1.5rem 1rem;\n}\n\n.category-wrap {\n  display: flex;\n  align-items: center;\n  gap: 0.25rem;\n  margin-bottom: 1.25rem;\n  .category {\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 400;\n    color: var(--gray-scale500);\n  }\n\n  .icon {\n    width: 1.25rem;\n    min-width: 1.25rem;\n    height: 1.25rem;\n    background: url(../../../../assets/imgs/qna-icon.png) no-repeat center /\n      100% 100%;\n  }\n\n  .select {\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 500;\n    color: var(--gray-scale750);\n  }\n}\n\n.title-wrap {\n  padding-bottom: 0.75rem;\n  border-bottom: 1px solid #e7e8e9;\n  margin-bottom: 1rem;\n  p {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 700;\n    color: var(--gray-scale900);\n  }\n}\n\n.content {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  font-weight: 400;\n  color: var(--gray-scale750);\n}\n\n.padding-bottom {\n  padding-bottom: 5rem;\n}\n\n.no-list {\n  height: 40vh;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
