/** @format */

import {
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  sendPasswordResetEmail,
  authState,
  sendEmailVerification,
  onAuthStateChanged,
} from '@angular/fire/auth';

import { DbService } from './db.service';
import { AlertService } from './alert.service';
import {
  Observable,
  Subscription,
  lastValueFrom,
  of,
  switchMap,
  take,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<any>;
  userSubscription: Subscription;

  constructor(
    public alertService: AlertService,
    private alertCtrl: AlertController,
    private db: DbService,
    public auth: Auth,
    private loadingService: LoadingService,
    private navController: NavController
  ) {
    this.user$ = authState(auth).pipe(
      switchMap((user) =>
        user ? this.db.doc$(`members/${user.uid}`) : of(null)
      )
    );
    this.user$.subscribe((user) => {
      // 이용정지 회원 일 경우 Alert
      if (user && user.status === 'suspend') {
        this.navController.navigateRoot(['/suspension'], {
          animationDirection: 'forward',
        });
      }
    });
  }

  // email + password 가입
  registerUser(value) {
    return new Promise<any>((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, value.email, value.password)
        .then((success) => resolve(success))
        .catch((error) => {
          let code = error['code'];
          this.loadingService.hide();
          this.showErrorMessage(code);
          reject(code);
        });
    });
  }

  // email + password 로그인
  loginUser(value) {
    return new Promise<any>((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, value.email, value.password)
        .then((success) => resolve(success))
        .catch((error) => {
          let code = error['code'];
          // this.showErrorMessage(code);
          reject(code);
        });
    });
  }

  // 가입한 회원에게 이메일 인증 메일 발송
  sendEmailVerificationUser() {
    return new Promise((resolve, reject) => {
      sendEmailVerification(this.auth.currentUser)
        .then((success) => resolve(success))
        .catch((error) => {
          let code = error['code'];
          reject(code);
        });
    });
  }

  // 현재 currentUser 로그아웃
  logoutUser() {
    return new Promise((resolve, reject) => {
      signOut(this.auth).then(
        (success) => {
          resolve(true);
        },
        (error) => {
          let code = error['code'];
          this.showErrorMessage(code);
          reject(code);
        }
      );
    });
  }

  // 현재 currentUser 회원탈퇴 (주의사항 : currentUser가 최신이 아닐 경우 재 로그인 필요)
  exitUser() {
    return new Promise((resolve, reject) => {
      this.auth.currentUser
        .delete()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          let code = error['code'];
          this.showErrorMessage(code);

          reject(code);
        });
    });
  }

  // currentUser 정보 받아오기
  async getUser() {
    return await lastValueFrom(this.user$.pipe(take(1)));
  }

  // currentUser uid 받아오기
  uid(): string {
    return this.auth.currentUser?.uid;
  }

  // currentUser 비밀번호 변경
  async changePassword(newPassword) {
    return new Promise((resolve, reject) => {
      updatePassword(this.auth.currentUser, newPassword)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          let code = error['code'];
          this.showErrorMessage(code);

          reject(code);
        });
    });
  }

  // 비밀번호 재설정 메일 발송
  sendPasswordReset(email) {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(this.auth, email)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          let code = error['code'];
          this.showErrorMessage(code);

          reject(code);
        });
    });
  }

  // 로그인했는지 여부
  checkLoggedIn(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async showErrorMessage(code$: any) {
    let code = await code$;
    switch (code) {
      // Firebase Error Messages
      case 'auth/email-already-exists':
        this.okBtn('이미 있는 계정입니다.', '회원 가입 오류');
        break;
      case 'auth/invalid-credential':
        this.okBtn('로그인 인증에 오류가 발생했습니다.', '인증 오류');
        break;
      case 'auth/operation-not-allowed':
        this.okBtn(
          '로그인 과정에서 오류가 발생했습니다. 관리자에게 문의하시길 바랍니다.',
          '로그인 실패'
        );
        break;

      case 'auth/user-not-found':
        this.okBtn(
          '가입된 이메일 주소가 아닙니다. 다시 확인하시기 바랍니다.',
          '이메일 없음'
        );
        break;
      case 'auth/invalid-login-credentials':
        this.okBtn(
          '이메일 주소 또는 비밀번호가 틀립니다.\n 확인 후 다시 입력해주세요.',
          '로그인 오류'
        );
        break;
      case 'auth/invalid-email':
        this.okBtn(
          '이메일 주소 또는 비밀번호가 틀립니다.\n 확인 후 다시 입력해주세요.',
          '로그인 오류'
        );
        break;
      case 'auth/invalid-password':
        this.okBtn('비밀번호는 6자 이상이여야합니다.', '회원가입 오류');
        break;
      case 'auth/id-token-expired':
        this.okBtn('제공된 로그인 토큰이 만료되었습니다.', '인증만료');
        break;
      case 'auth/too-many-requests':
        this.okBtn(
          '로그인 요청 횟수가 초과 되었습니다. 잠시 후 다시 시도하시기 바랍니다.',
          '요청 횟수 초과'
        );
        break;

      default:
        this.okBtn('로그인 인증에 오류가 발생했습니다.', '관리자 문의');
    }
  }

  async okBtn(
    message: string,
    header?: string,
    okText = '확인',
    role = 'ok'
  ): Promise<any> {
    return new Promise(async (resolve: any) => {
      const alert = await this.alertCtrl.create({
        cssClass: 'ok-alert',
        header,
        message,
        buttons: [
          {
            text: okText,
            role,
            handler: () => {
              resolve(true);
            },
          },
        ],
      });

      await alert.present();
    });
  }
}
