// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-line-btn {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 8.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--main);
  --color: var(--main);
  overflow: hidden;
}
.main-line-btn[disabled] {
  border: 1px solid var(--gray-scale100);
  color: var(--gray-scale300);
  --color: var(--gray-scale300);
}

.join-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin: 0 auto 1.25rem;
  width: 5.25rem;
  height: 5.25rem;
}
.icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bold {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 700;
  color: var(--gray-scale900);
  text-align: center;
  margin-bottom: 0.5rem;
}

p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--gray-scale750);
  text-align: center;
}
p span {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: var(--main);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/join-complete/join-complete.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,cAAA;EACA,aAAA;EACA,cAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,oBAAA;EACA,gBAAA;AACF;AAAE;EACE,sCAAA;EACA,2BAAA;EACA,6BAAA;AAEJ;;AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,sBAAA;EACA,cAAA;EACA,eAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAEJ;;AAEA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,2BAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;EACA,kBAAA;AACF;AAAE;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,kBAAA;AAEJ","sourcesContent":[".main-line-btn {\n  display: flex;\n  justify-content: center;\n  margin: 0 auto;\n  width: 8.5rem;\n  height: 2.5rem;\n  font-size: 0.875rem;\n  line-height: 1.5rem;\n  font-weight: 700;\n  color: var(--main);\n  --color: var(--main);\n  overflow: hidden;\n  &[disabled] {\n    border: 1px solid var(--gray-scale100);\n    color: var(--gray-scale300);\n    --color: var(--gray-scale300);\n  }\n}\n\n.join-container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.icon {\n  margin: 0 auto 1.25rem;\n  width: 5.25rem;\n  height: 5.25rem;\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n\n.bold {\n  font-size: 1.125rem;\n  line-height: 1.625rem;\n  font-weight: 700;\n  color: var(--gray-scale900);\n  text-align: center;\n  margin-bottom: 0.5rem;\n}\n\np {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 400;\n  color: var(--gray-scale750);\n  text-align: center;\n  span {\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 700;\n    color: var(--main);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
