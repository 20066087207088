import { Injectable } from '@angular/core';
import Swiper, { Autoplay, Pagination } from 'swiper';

@Injectable({
  providedIn: 'root',
})
export class SwiperService {
  swiper;
  expertSwiper;

  // 배너 슬라이드 옵션
  // 최소한의 지연을 사용하여 데이터 처리 이후에 실행되도록 합니다.
  async bannerSwiperOptions(
    swiperName:
      | '.comuSwiper'
      | '.mypageSwiper'
      | '.homeSwiper'
      | '.listSwiper'
      | '.pointSwiper'
  ) {
    const slidesCount = document.querySelectorAll(
      `${swiperName} .swiper-slide`
    ).length;
    const loopEnabled = slidesCount > 1;

    this.swiper = new Swiper(`${swiperName}`, {
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 500,
      modules: [Autoplay, Pagination],
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      loop: loopEnabled,
      loopAdditionalSlides: slidesCount,
    });
  }

  // 홈 > 도배서비스 스와이퍼
  async expertServiceSwiperOptions(swiperName: '.cardSwiper') {
    this.expertSwiper = new Swiper(`${swiperName}`, {
      modules: [Pagination, Autoplay],
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },

      spaceBetween: 10,
    });
  }

  // 메인 스와이퍼 생성 되어있는 swiper를 제거하기
  destroyBannerSwiper() {
    if (this.swiper && this.swiper.length > 0) {
      const destroyPromises = this.swiper.map((swiper) =>
        this.destroySwiper(swiper)
      );
      return Promise.all(destroyPromises);
    }
    if (this.swiper) {
      return this.destroySwiper(this.swiper);
    }
    return Promise.resolve();
  }

  // 홈 > 도배서비스 스와이퍼 스와이퍼 생성 되어있는 swiper를 제거하기
  experrDestroyBannerSwiper() {
    if (this.expertSwiper && this.expertSwiper.length > 0) {
      const destroyPromises = this.expertSwiper.map((expertSwiper) =>
        this.destroySwiper(expertSwiper)
      );
      return Promise.all(destroyPromises);
    }
    if (this.expertSwiper) {
      return this.destroySwiper(this.expertSwiper);
    }
    return Promise.resolve();
  }

  private destroySwiper(swiper: Swiper | Swiper[]) {
    if (Array.isArray(swiper)) {
      const destroyPromises = swiper.map((s) => this.destroySwiperInstance(s));
      return Promise.all(destroyPromises);
    } else {
      return this.destroySwiperInstance(swiper);
    }
  }

  private destroySwiperInstance(swiper: Swiper) {
    return new Promise<void>((resolve) => {
      swiper && swiper.destroy(true, true);
      resolve();
    });
  }
}
