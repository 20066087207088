import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'count',
})
export class CountPipe implements PipeTransform {
  // 최대표시갯수 보다 뱃지 카운트가 높을경우 최대표시개수 표시
  transform(value: string, maxlength): unknown {
    const count = [...new Intl.Segmenter().segment(value)].map(
      (x) => x.segment
    );
    return count?.length > maxlength ? maxlength : count?.length;
  }
}
