import { Component, OnInit } from '@angular/core';
import { ActionSheetController, NavController } from '@ionic/angular';
import { Inquiry } from 'src/app/core/models/inquiry.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DbService } from 'src/app/core/services/db.service';
import { Notification } from 'src/app/core/models/notification.model';

@Component({
  selector: 'app-qna-write',
  templateUrl: './qna-write.component.html',
  styleUrls: ['./qna-write.component.scss'],
})
export class QnaWriteComponent implements OnInit {
  selectedCategory: string = '카테고리를 선택해주세요.';
  title: string = '';
  content: string = '';

  constructor(
    private actionSheetController: ActionSheetController,
    private alertService: AlertService,
    private navController: NavController,
    private db: DbService,
    private auth: AuthService,
    private commonService: CommonService
  ) {}

  ngOnInit() {}

  //카테고리 선택
  async selectAction() {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: '일반 문의',
          handler: () => {
            this.selectedCategory = '일반 문의';
          },
        },
        {
          text: '불만 접수',
          handler: () => {
            this.selectedCategory = '불만 접수';
          },
        },
        {
          text: '취소',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });

    await actionSheet.present();
  }

  //1:1문의하기
  async goQnaList() {
    // 조건 충족 못했을 경우
    if (
      this.selectedCategory === '카테고리를 선택해주세요.' ||
      !this.title?.trim() ||
      !this.content?.trim() ||
      this.title?.length < 2 ||
      this.content?.length < 10
    ) {
      this.alertService.okBtn(
        '카테고리 선택 및 제목 2자,내용 10자 이상 입력해주세요.'
      );
      return;
    }
    const member = await this.auth.getUser();
    const userType = member.isExpert ? 'expert' : 'member';
    let inquiry: Inquiry = {
      id: this.commonService.generateFilename(),
      dateCreated: new Date().toISOString(),
      title: this.title,
      content: this.content,
      uuid: member.uuid,
      answer: '',
      answerTitle: '',
      answerDate: '',
      userType: userType,
      type: this.selectedCategory === '일반 문의' ? 'basic' : 'complain',
    };
    this.sendAlarm(userType);
    await this.db.updateAt(`inquiries/${inquiry.id}`, inquiry);
    this.navController.pop();
    this.alertService.presentToast('문의글이 작성됐어요.');
  }

  // 관리자에게 알림 전송
  async sendAlarm(userType: 'member' | 'expert') {
    // 알림
    let notifications: Notification = {
      id: this.commonService.generateFilename(),
      uuid: 'admin',
      dateCreated: new Date().toISOString(),
      readSwitch: false,
      title: '1:1 문의',
      content: '1:1 문의가 도착했습니다.',
      url: userType === 'member' ? '/inquiry-user' : '/inquiry',
      checkSwitch: false,
      isAdminPush: false,
    };
    await this.db.updateAt(`notifications/${notifications.id}`, notifications);
  }

  //뒤로 얼럿
  backAlert() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.navController.pop();
        }
      });
  }
}
