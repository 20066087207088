import { Component, Input, OnInit } from '@angular/core';
import { Estimate } from 'src/app/core/models/estimate.model';

@Component({
  selector: 'app-estimate-shared02',
  templateUrl: './estimate-shared02.component.html',
  styleUrls: ['./estimate-shared02.component.scss'],
})
export class EstimateShared02Component implements OnInit {
  CounselingSwitch;
  @Input() item: Estimate | any;
  @Input() type;

  constructor() {}

  ngOnInit() {}

  // 전문가 이미지는 4개만 보여주기
  limitedSenders() {
    return this.item?.requestId?.senders.slice(0, 4) || [];
  }
}
