import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { RequestService } from 'src/app/core/services/request.service';

@Component({
  selector: 'app-application-area',
  templateUrl: './application-area.component.html',
  styleUrls: ['./application-area.component.scss'],
})
export class ApplicationAreaComponent implements OnInit {
  rangeValue: number = 1;

  constructor(
    private navController: NavController,
    private requestService: RequestService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // 저장된 데이터가 있다면 불러오기
    const reservation = this.requestService.request;
    if (reservation.pyeong) {
      this.rangeValue = reservation.pyeong;
    }
  }

  //이전
  goBack() {
    const reservation = this.requestService.request;
    reservation.pyeong = this.rangeValue;
    this.navController.pop();
  }

  //요청서 작성
  goRequestText() {
    const reservation = this.requestService.request;
    reservation.pyeong = this.rangeValue;

    const topId = this.activatedRoute.snapshot.queryParams['topId'];
    const subId = this.activatedRoute.snapshot.queryParams['subId'];
    const requestType = this.activatedRoute.snapshot.queryParams['requestType'];
    const expertUuid = this.activatedRoute.snapshot.queryParams['expertUuid'];
    this.navController.navigateForward('/application-textarea', {
      queryParams: {
        topId,
        subId,
        requestType,
        expertUuid,
      },
    });
  }
}
