import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import Swiper, { Autoplay, Pagination } from 'swiper';
import { ImageDetailComponent } from '../../image-detail/image-detail.component';

@Component({
  selector: 'app-portfolio-detail',
  templateUrl: './portfolio-detail.component.html',
  styleUrls: ['./portfolio-detail.component.scss'],
})
export class PortfolioDetailComponent implements OnInit {
  @Input() portfolio: Member['portfolio'];
  swiper: Swiper;
  viewSwitch: boolean = false;
  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  ionViewDidEnter() {
    this.swiperOptions();
  }

  //스와이퍼 옵션
  async swiperOptions() {
    this.swiper = new Swiper('.portfolidSwiper', {
      modules: [Pagination, Autoplay],
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      spaceBetween: 10,

      on: {},
    });

    this.swiper.update();
  }

  // 이미지 상세
  async imageDetail(item, idx?: number) {
    const items = item.map((item) => ({
      image: item,
      videoCheck: false,
    }));

    const modal = await this.modalController.create({
      component: ImageDetailComponent,
      componentProps: { item: items, idx },
    });

    await modal.present();
  }

  //모달 닫기
  closeModal() {
    this.modalController.dismiss();
  }
}
