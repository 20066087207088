import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TopCategory } from 'src/app/core/models/category.model';
import { CommunityService } from 'src/app/core/services/community.service';

@Component({
  selector: 'app-estimate-request',
  templateUrl: './estimate-request.component.html',
  styleUrls: ['./estimate-request.component.scss'],
})
export class EstimateRequestComponent implements OnInit {
  topCategories: TopCategory[];
  keyword: string = '';

  constructor(
    private navController: NavController,
    private communityService: CommunityService
  ) {}

  // 카테고리 데이터 불러오기
  async ngOnInit() {
    this.topCategories = await this.communityService.getTopCategories('noHome');
  }

  // 검색
  goSearch() {
    this.navController.navigateForward('/search');
  }

  //카테고리선택
  goCategorySelect(categoryId: TopCategory['id']) {
    this.navController.navigateForward('/category-select', {
      queryParams: {
        categoryId,
      },
    });
  }
}
