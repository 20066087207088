import { Component, Input, OnInit } from '@angular/core';
import { Member } from 'src/app/core/models/member.model';
import { Request } from 'src/app/core/models/request.model';

@Component({
  selector: 'app-estimate-shared',
  templateUrl: './estimate-shared.component.html',
  styleUrls: ['./estimate-shared.component.scss'],
})
export class EstimateSharedComponent implements OnInit {
  @Input() item: Request;
  @Input() serviceName: string;
  @Input() type:
    | 'request'
    | 'detail'
    | 'home'
    | 'request-list'
    | 'estimate-detail'; // 받은견적 > 요청서 상세
  @Input() expertName: Member['activityName'];
  constructor() {}

  ngOnInit() {}
}
