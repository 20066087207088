import { Component, OnInit } from '@angular/core';
import { ModalController, PickerController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-career-select',
  templateUrl: './career-select.component.html',
  styleUrls: ['./career-select.component.scss'],
})
export class CareerSelectComponent implements OnInit {
  member: Member;
  selectedCareer: string = '총 경력 기간을 선택해주세요.';

  constructor(
    private pickerController: PickerController,
    private modalController: ModalController,
    private alertService: AlertService,
    private auth: AuthService,
    private db: DbService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    if (this.member.career) {
      this.selectedCareer = this.member.career;
    }
  }

  //경력 셀렉트
  async selectPickerCareer() {
    const picker = await this.pickerController.create({
      animated: true,
      cssClass: 'career-picker',
      buttons: [
        {
          text: '취소',
          role: 'cancel',
        },
        {
          text: '확인',
          handler: (value) => {
            this.selectedCareer = value.year.value;
          },
        },
      ],
      columns: [
        {
          name: 'year',
          options: [
            {
              text: '1년',
              value: '1년',
            },
            {
              text: '2년',
              value: '2년',
            },
            {
              text: '3년',
              value: '3년',
            },
            {
              text: '4년',
              value: '4년',
            },
            {
              text: '5년',
              value: '5년',
            },
            {
              text: '6년',
              value: '6년',
            },
            {
              text: '7년',
              value: '7년',
            },
            {
              text: '8년',
              value: '8년',
            },
            {
              text: '9년',
              value: '9년',
            },
            {
              text: '10년',
              value: '10년',
            },
            {
              text: '15년',
              value: '15년',
            },
            {
              text: '20년',
              value: '20년',
            },
            {
              text: '20년 이상',
              value: '20년 이상',
            },
          ],
        },
      ],

      mode: 'ios',
    });
    picker.present();
  }

  // 뒤로가기
  closeModal() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.modalController.dismiss();
        }
      });
  }

  //저장
  async save() {
    await this.db.updateAt(`members/${this.member.uuid}`, {
      career: this.selectedCareer,
    });
    this.modalController.dismiss({
      career: this.selectedCareer,
    });
    this.alertService.presentToast('경력 기간을 업데이트 하였습니다.');
  }
}
