import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionSheetController, NavController } from '@ionic/angular';
import * as dayjs from 'dayjs';
import { increment } from 'firebase/firestore';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MemberService } from 'src/app/core/services/member.service';
import { PushService } from 'src/app/core/services/push.service';

@Component({
  selector: 'app-withdrawal-reason',
  templateUrl: './withdrawal-reason.component.html',
  styleUrls: ['./withdrawal-reason.component.scss'],
})
export class WithdrawalReasonComponent implements OnInit {
  member: Member;
  selectedReason: string = '탈퇴 사유를 선택해주세요.';
  content: string = '';
  type: 'suspend' | 'setting';

  constructor(
    private actionSheetController: ActionSheetController,
    private navController: NavController,
    private alertService: AlertService,
    private pushService: PushService,
    private db: DbService,
    private auth: AuthService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private memberService: MemberService
  ) {
    this.type = this.activatedRoute.snapshot.queryParams['type'];
  }

  async ngOnInit() {
    this.member = await this.auth.getUser();
  }

  //카테고리 선택
  async selectAction() {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: '자주 이용하지 않아요.',
          handler: () => {
            this.selectedReason = '자주 이용하지 않아요';
          },
        },
        {
          text: '서비스 이용이 불편해요.',
          handler: () => {
            this.selectedReason = '서비스 이용이 불편해요.';
          },
        },
        {
          text: '개인정보 유출이 우려돼요.',
          handler: () => {
            this.selectedReason = '개인정보 유출이 우려돼요.';
          },
        },
        {
          text: '기타',
          handler: () => {
            this.selectedReason = '기타';
          },
        },
        {
          text: '취소',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });

    await actionSheet.present();
  }

  //좀더이용할게요
  goHome() {
    this.navController.navigateRoot(['/tabs/home'], {
      animationDirection: 'back',
    });
  }

  //회원탈퇴
  withdrawalAlert() {
    // 소셜 유저면 탈퇴처리
    if (this.member.loginType[0] !== 'email') {
      this.socialWithdrawal();
      return;
    } else {
      // 일반 유저 탈퇴
      this.navController.navigateForward('/withdrawal-check', {
        queryParams: {
          withdrawalValue: this.selectedReason,
          content: this.content,
        },
      });
    }
  }

  // 소셜유저 탈퇴하기
  async socialWithdrawal() {
    this.alertService.cancelOkBtn('회원탈퇴를 진행합니다.').then(async (ok) => {
      if (ok) {
        await this.loadingService.load();
        const uuid = this.member.uuid;

        // 푸시 제거
        const pushId = await this.pushService.getId();
        if (pushId && this.member.pushId.includes(pushId.userId)) {
          await this.memberService.removePush(uuid, pushId.userId);
        }

        //  관리자 대시보드 통계값 업데이트
        const id = dayjs().format('YYYYMMDD');
        const statisticsData = await this.db.toDoc$(`statistics/${id}`);
        await this.db.updateAt(`statistics/${id}`, {
          id,
          date: {
            year: dayjs().format('YYYY'),
            month: dayjs().format('MM'),
            date: dayjs().format('DD'),
          },
          joinMembersCount: statisticsData
            ? statisticsData.joinMembersCount
            : 0,
          exitMembersCount: increment(1),
          pointPaymentCount: statisticsData
            ? statisticsData.pointPaymentCount
            : 0,
          pointRefundCount: statisticsData
            ? statisticsData.pointRefundCount
            : 0,
          insurancePaymentCount: statisticsData
            ? statisticsData.insurancePaymentCount
            : 0,
          insuranceRefundCount: statisticsData
            ? statisticsData.insuranceRefundCount
            : 0,
        });

        localStorage.clear();
        await this.auth.logoutUser();

        this.http
          .post(
            'https://us-central1-banseliin.cloudfunctions.net/socialFunctions/deleteUser',
            {
              uid: uuid,
              withdrawalValue: this.selectedReason,
              content: this.content,
            }
          )
          .subscribe(() => {
            this.loadingService.hide();
            this.navController.navigateRoot(['/login'], {
              animationDirection: 'forward',
            });
            this.alertService.presentToast('회원탈퇴 했습니다.');
          });
      }
    });
  }
}
