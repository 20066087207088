// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-close {
  width: 2.75rem;
  height: 2.75rem;
  --background: url('modal-close-icon.png') no-repeat
    center / 100% 100%;
}

.textarea-item::part(native) {
  flex-direction: column;
}
.textarea-item ion-textarea {
  min-height: 10rem;
  max-height: 10rem;
}
.textarea-item ion-textarea .textarea-wrapper .sc-ion-textarea-ios {
  height: 100%;
}

.val {
  position: absolute;
  top: 13.125rem;
  left: 0;
  display: flex;
  gap: 0.25rem;
}
.val .icon {
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  background: url('val-icon.png') no-repeat center/100% 100%;
}
.val p {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 400;
  color: #e63010;
}

.item-wrap {
  position: relative;
}

.input-item-container .val {
  position: absolute;
  top: 3.25rem;
  left: 0;
}

.num {
  text-align: right;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 400;
  color: var(--gray-scale500);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/master/expert-introduction-write/expert-introduction-write.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA;sBAAA;AAEF;;AAEE;EACE,sBAAA;AACJ;AACE;EACE,iBAAA;EACA,iBAAA;AACJ;AAAI;EACE,YAAA;AAEN;;AAEA;EACE,kBAAA;EACA,cAAA;EACA,OAAA;EACA,aAAA;EACA,YAAA;AACF;AAAE;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,0DAAA;AAEJ;AACE;EACE,mBAAA;EACA,sBAAA;EACA,gBAAA;EACA,cAAA;AACJ;;AAEA;EACE,kBAAA;AACF;;AAGE;EACE,kBAAA;EACA,YAAA;EACA,OAAA;AAAJ;;AAGA;EACE,iBAAA;EACA,WAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;EACA,2BAAA;AAAF","sourcesContent":[".modal-close {\n  width: 2.75rem;\n  height: 2.75rem;\n  --background: url(../../../../assets/imgs/modal-close-icon.png) no-repeat\n    center / 100% 100%;\n}\n.textarea-item {\n  &::part(native) {\n    flex-direction: column;\n  }\n  ion-textarea {\n    min-height: 10rem;\n    max-height: 10rem;\n    .textarea-wrapper .sc-ion-textarea-ios {\n      height: 100%;\n    }\n  }\n}\n.val {\n  position: absolute;\n  top: 13.125rem;\n  left: 0;\n  display: flex;\n  gap: 0.25rem;\n  .icon {\n    width: 1.25rem;\n    min-width: 1.25rem;\n    height: 1.25rem;\n    background: url(../../../../assets/imgs/val-icon.png) no-repeat center /\n      100% 100%;\n  }\n  p {\n    font-size: 0.875rem;\n    line-height: 1.3125rem;\n    font-weight: 400;\n    color: #e63010;\n  }\n}\n.item-wrap {\n  position: relative;\n}\n\n.input-item-container {\n  .val {\n    position: absolute;\n    top: 3.25rem;\n    left: 0;\n  }\n}\n.num {\n  text-align: right;\n  width: 100%;\n  font-size: 0.875rem;\n  line-height: 1.3125rem;\n  font-weight: 400;\n  color: var(--gray-scale500);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
