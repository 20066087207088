import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlarmSharedComponent } from './alarm-shared/alarm-shared.component';
import { CommonCommunityCardComponent } from './common-community-card/common-community-card.component';
import { GradeCardComponent } from './grade-card/grade-card.component';
import { SmallInfoCardComponent } from './small-info-card/small-info-card.component';
import { AccordionSharedComponent } from './accordion-shared/accordion-shared.component';
import { ChatCardComponent } from './chat-card/chat-card.component';
import { CategoryItemComponent } from './category-item/category-item.component';
import { ReviewItemComponent } from './review-item/review-item.component';
import { EstimateSharedComponent } from './estimate-shared/estimate-shared.component';
import { EstimateShared02Component } from './estimate-shared02/estimate-shared02.component';
import { CommentSharedComponent } from '../common/comment-shared/comment-shared.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [
    AlarmSharedComponent,
    CommonCommunityCardComponent,
    GradeCardComponent,
    SmallInfoCardComponent,
    AccordionSharedComponent,
    ChatCardComponent,
    CategoryItemComponent,
    ReviewItemComponent,
    EstimateSharedComponent,
    EstimateShared02Component,
    CommentSharedComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
  ],
  exports: [
    AlarmSharedComponent,
    CommonCommunityCardComponent,
    GradeCardComponent,
    SmallInfoCardComponent,
    AccordionSharedComponent,
    ChatCardComponent,
    CategoryItemComponent,
    ReviewItemComponent,
    EstimateSharedComponent,
    EstimateShared02Component,
    CommentSharedComponent,
  ],
})
export class SharedModule {}
