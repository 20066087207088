// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap {
  width: 100%;
  border: 1px solid var(--gray-scale100);
  border-radius: 6px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.wrap .thumbnail {
  width: 5rem;
  min-width: 5rem;
  height: 5rem;
}
.wrap .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wrap .text {
  padding: 1rem;
  width: calc(100% - 5rem);
}
.wrap .text .category {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: var(--gray-scale900);
  margin-bottom: 0.25rem;
}
.wrap .text .store {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: var(--gray-scale750);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/shared/category-item/category-item.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,sCAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;EACA,eAAA;EACA,YAAA;AACJ;AAAI;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAEN;AAEE;EACE,aAAA;EACA,wBAAA;AAAJ;AACI;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,sBAAA;AACN;AAEI;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;AAAN","sourcesContent":[".wrap {\n  width: 100%;\n  border: 1px solid var(--gray-scale100);\n  border-radius: 6px;\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n\n  .thumbnail {\n    width: 5rem;\n    min-width: 5rem;\n    height: 5rem;\n    img {\n      width: 100%;\n      height: 100%;\n      object-fit: cover;\n    }\n  }\n\n  .text {\n    padding: 1rem;\n    width: calc(100% - 5rem);\n    .category {\n      font-size: 1rem;\n      line-height: 1.5rem;\n      font-weight: 600;\n      color: var(--gray-scale900);\n      margin-bottom: 0.25rem;\n    }\n\n    .store {\n      font-size: 0.875rem;\n      line-height: 1.25rem;\n      font-weight: 500;\n      color: var(--gray-scale750);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
