import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { arrayUnion } from 'firebase/firestore';
import { lastValueFrom, take } from 'rxjs';
import { Comment } from 'src/app/core/models/comment.model';
import { Post } from 'src/app/core/models/post.model';
import { Report } from 'src/app/core/models/report.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DbService, docJoin } from 'src/app/core/services/db.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { Notification } from 'src/app/core/models/notification.model';
import { Chat } from 'src/app/core/models/chat.model';
import { ChatService } from 'src/app/core/services/chat.service';
import { Member } from 'src/app/core/models/member.model';

@Component({
  selector: 'app-post-report',
  templateUrl: './post-report.component.html',
  styleUrls: ['./post-report.component.scss'],
})
export class PostReportComponent implements OnInit {
  commentId: Comment['id'];
  postId: Post['id'];
  chatId: Chat['id'];
  data: Post | Comment | any;

  type: 'comment' | 'post' | 'expert' | 'member';
  select: string = ''; // 선택한 신고사유

  textForm = new FormGroup({
    detail: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(500),
    ]),
  });

  constructor(
    private navController: NavController,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private commonService: CommonService,
    private db: DbService,
    private auth: AuthService,
    private chatService: ChatService
  ) {
    const params = this.activatedRoute.snapshot.queryParams;
    this.type = params.type;
    this.postId = params.postId;
    this.commentId = params.commentId;
    this.chatId = params.chatId;
  }

  async ngOnInit() {
    if (this.type === 'post') {
      this.data = await lastValueFrom(
        this.db
          .doc$(`posts/${this.postId}`)
          .pipe(docJoin(this.db.firestore, 'uuid', 'members'), take(1))
      );
    } else if (this.type === 'comment') {
      this.data = await lastValueFrom(
        this.db
          .doc$(`comments/${this.commentId}`)
          .pipe(docJoin(this.db.firestore, 'uuid', 'members'), take(1))
      );
    } else {
      this.data = await lastValueFrom(
        this.db.doc$(`chats/${this.chatId}`).pipe(take(1))
      );
    }
  }

  //뒤로 얼럿
  backAlert() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.navController.pop();
        }
      });
  }

  // 선택한 신고사유
  selectReason(event) {
    this.select = event.detail.value;
  }

  //신고하기
  async reportAlert() {
    let partner: Member['uuid'];
    const member = await this.auth.getUser();
    if (this.type === 'member' || this.type === 'expert') {
      partner = this.data.uuid.find((id) => id !== member.uuid);
    }

    const updateCol =
      this.type === 'post'
        ? `posts/${this.postId}`
        : `comments/${this.commentId}`;

    this.alertService
      .cancelOkBtn(
        this.type === 'comment' || this.type === 'post'
          ? '신고 후 취소할 수 없습니다. 신고하시겠어요?'
          : `${
              this.type === 'member' ? '고객을' : '전문가를'
            } 신고하시겠습니까? ${
              this.type === 'member' ? '고객' : '전문가'
            } 신고 시 즉시 차단 됩니다.`
      )
      .then(async (ok) => {
        if (ok) {
          await this.loadingService.load();
          let report: Report = {
            id: this.commonService.generateFilename(),
            dateCreated: new Date().toISOString(),
            type:
              this.type === 'post'
                ? 'post'
                : this.type === 'comment'
                ? 'comment'
                : this.type === 'member'
                ? 'member'
                : 'expert',
            reason: this.select,
            reasonDetail: this.textForm.get('detail').value,
            reportedUuid:
              this.type === 'comment' || this.type === 'post'
                ? this.data.uuid.uuid
                : partner,
            uuid: member.uuid,
            collection: this.data.id,
          };

          await Promise.all([
            this.db.updateAt(`reports/${report.id}`, report), // 신고 db 업데이트
            this.db.updateAt(updateCol, {
              reportUser: arrayUnion(member.uuid),
            }),
          ]);

          // 커뮤니티 신고인 경우 관리자에게 알림 보내기
          if (this.type === 'comment' || this.type === 'post') {
            this.sendAlarm();
          }

          // 채팅에서 신고하면 차단처리
          if (this.type === 'expert' || this.type === 'member') {
            await this.chatService.exitChat(this.chatId, true);
            await this.db.updateAt(`members/${member.uuid}`, {
              blockUser: arrayUnion(partner),
            });
          }
          this.loadingService.hide();

          const navParms =
            this.type === 'comment' || this.type === 'post'
              ? 'tabs/community'
              : 'tabs/chat-list';
          const toastMsg =
            this.type === 'comment' || this.type === 'post'
              ? '신고를 완료했습니다.'
              : `${
                  this.type === 'member' ? '고객을' : '전문가를'
                } 신고 및 차단하였습니다.`;
          this.navController.navigateForward(navParms);
          this.alertService.presentToast(toastMsg);
        }
      });
  }

  // 관리자에게 알림 전송
  async sendAlarm() {
    // 알림
    let notifications: Notification = {
      id: this.commonService.generateFilename(),
      uuid: 'admin',
      dateCreated: new Date().toISOString(),
      readSwitch: false,
      title: '신고 접수',
      content: '커뮤니티 신고가 접수 되었습니다.',
      url: '/report-user',
      checkSwitch: false,
      isAdminPush: false,
    };
    await this.db.updateAt(`notifications/${notifications.id}`, notifications);
  }
}
