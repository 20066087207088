// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--gray-scale900);
  letter-spacing: -0.2px;
}

.accordion-collapsed .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.accordion-collapsed .icon img {
  width: 100%;
  height: 100%;
}

ion-item {
  --background: #fff;
  --inner-padding-top: 1rem;
  --inner-padding-bottom: 1rem;
  --inner-padding-start: 1rem;
  --inner-padding-end: 1rem;
}
ion-item ion-label {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: var(--gray-scale900);
}
ion-item .icon {
  width: 1.5rem;
  height: 1.5rem;
}
ion-item .icon img {
  width: 100%;
  height: 100%;
}

.all-acount-list {
  padding: 0 1rem 1rem;
}
.all-acount-list p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale750);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/shared/accordion-shared/accordion-shared.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;EACA,sBAAA;AACF;;AAGE;EACE,aAAA;EACA,cAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;AAAN;;AAKA;EACE,kBAAA;EACA,yBAAA;EACA,4BAAA;EACA,2BAAA;EACA,yBAAA;AAFF;AAIE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AAFJ;AAIE;EACE,aAAA;EACA,cAAA;AAFJ;AAII;EACE,WAAA;EACA,YAAA;AAFN;;AAOA;EACE,oBAAA;AAJF;AAKE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AAHJ","sourcesContent":["p {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 400;\n  color: var(--gray-scale900);\n  letter-spacing: -0.2px;\n}\n\n.accordion-collapsed {\n  .icon {\n    width: 1.5rem;\n    height: 1.5rem;\n\n    img {\n      width: 100%;\n      height: 100%;\n    }\n  }\n}\n\nion-item {\n  --background: #fff;\n  --inner-padding-top: 1rem;\n  --inner-padding-bottom: 1rem;\n  --inner-padding-start: 1rem;\n  --inner-padding-end: 1rem;\n\n  ion-label {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 500;\n    color: var(--gray-scale900);\n  }\n  .icon {\n    width: 1.5rem;\n    height: 1.5rem;\n\n    img {\n      width: 100%;\n      height: 100%;\n    }\n  }\n}\n\n.all-acount-list {\n  padding: 0 1rem 1rem;\n  p {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 400;\n    color: var(--gray-scale750);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
