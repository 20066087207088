import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { InsuranceDetailComponent } from '../insurance-detail/insurance-detail.component';
import { InsurancePayment } from 'src/app/core/models/insurance-payment.model';
import { InsuranceRefund } from 'src/app/core/models/insurance-refund.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService, leftJoinDocument } from 'src/app/core/services/db.service';
import { lastValueFrom, map, take } from 'rxjs';
import { Member } from 'src/app/core/models/member.model';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-insurance-list',
  templateUrl: './insurance-list.component.html',
  styleUrls: ['./insurance-list.component.scss'],
})
export class InsuranceListComponent implements OnInit {
  topLimit = 12;
  lineSegment = 'pay';
  insurancePayments: InsurancePayment[] | any; // 결제내역
  insuranceRefunds: InsuranceRefund[] | any;
  member: Member;

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private auth: AuthService,
    private db: DbService
  ) {}

  ngOnInit() {}

  async ionViewWillEnter() {
    this.member = await this.auth.getUser();
    await this.getPayments();
    await this.getRefunds();
  }

  // 결제데이터
  async getPayments() {
    this.insurancePayments = await lastValueFrom(
      this.db
        .collection$('insurancePayments', (ref) =>
          ref
            .where('uuid', '==', this.member.uuid)
            .where('isRefund', '==', false)
            .orderBy('dateCreated', 'desc')
        )
        .pipe(
          leftJoinDocument(this.db.firestore, 'uuid', 'members'),
          leftJoinDocument(this.db.firestore, 'expertUuid', 'members'),
          take(1)
        )
    );
  }

  // 환불데이터
  async getRefunds() {
    this.insuranceRefunds = await lastValueFrom(
      this.db
        .collection$('insuranceRefunds', (ref) =>
          ref
            .where('uuid', '==', this.member.uuid)
            .orderBy('dateCreated', 'desc')
        )
        .pipe(
          leftJoinDocument(this.db.firestore, 'uuid', 'members'),
          leftJoinDocument(this.db.firestore, 'paymentId', 'insurancePayments'),
          map((refunds: InsuranceRefund[]) =>
            refunds.sort((a, b) => {
              const refundA = dayjs(a.dateCreated).format('YYYYMMDD');
              const refundB = dayjs(b.dateCreated).format('YYYYMMDD');
              // 날짜가 같으면 "환불대기" 우선정렬
              if (refundA == refundB) {
                return a.isConfirm === b.isConfirm ? 0 : a.isConfirm ? 1 : -1;
              } else {
                return b.dateCreated < a.dateCreated
                  ? -1
                  : b.dateCreated > a.dateCreated
                  ? 1
                  : 0;
              }
            })
          ),
          take(1)
        )
    );
  }

  //상세 내역 모달
  async openDetail(
    id: InsurancePayment['id'] | InsuranceRefund['id'],
    listType: 'payment' | 'refund'
  ) {
    const modal = await this.modalController.create({
      component: InsuranceDetailComponent,
      componentProps: { id, listType },
    });

    await modal.present();
  }

  //환불신청
  goApplyRefund(paymentId: InsurancePayment['id']) {
    this.navController.navigateForward('/insurance-refund-application', {
      queryParams: { paymentId },
    });
  }

  // 인피니티 스크롤
  loadData(ev) {
    setTimeout(() => {
      this.topLimit += 12;
      ev.target.complete();
    }, 500);
  }
}
