import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-expert-write',
  templateUrl: './expert-write.component.html',
  styleUrls: ['./expert-write.component.scss'],
})
export class ExpertWriteComponent implements OnInit {
  member: Member;
  path: 'mypage'; // 마이페이지에서 전문가 전환하기 토글 클릭 후 활동명 입력 안되었을 경우
  memberForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(2)]),
  });

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private auth: AuthService,
    private db: DbService,
    private activatedRoute: ActivatedRoute,
    private navController: NavController
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    this.path = this.activatedRoute.snapshot.queryParams['path'];
    if (this.member.activityName) {
      this.memberForm.get('name').setValue(this.member.activityName);
    }
  }

  ionViewWillLeave() {
    if (this.path && !this.memberForm.get('name').value) {
      this.db.updateAt(`members/${this.member.uuid}`, { isExpert: false });
    }
  }

  // 뒤로가기
  closeModal() {
    if (!this.path) {
      this.alertService
        .cancelOkBtn(
          '입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?'
        )
        .then((ok) => {
          if (ok) {
            this.modalController.dismiss();
          }
        });
    } else {
      // 입력 안하고 나가려고 하면
      if (!this.memberForm.get('name').value) {
        this.alertService.okBtn('최초 전문가 전환 시 전문가명을 기입해주세요.');
      } else {
        this.navController.pop();
      }
    }
  }

  // [저장]
  async save() {
    await this.db.updateAt(`members/${this.member.uuid}`, {
      activityName: this.memberForm.get('name').value,
    });
    if (this.path === 'mypage') {
      this.navController.pop();
    } else {
      this.modalController.dismiss({
        activityName: this.memberForm.get('name').value,
      });
    }

    this.alertService.presentToast('전문가명을 업데이트 하였습니다.');
  }
}
