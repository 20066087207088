import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Observable, map } from 'rxjs';
import { Inquiry } from 'src/app/core/models/inquiry.model';
import { Member } from 'src/app/core/models/member.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-qna-list',
  templateUrl: './qna-list.component.html',
  styleUrls: ['./qna-list.component.scss'],
})
export class QnaListComponent implements OnInit {
  topLimit = 10; // 인피니티 스크롤
  member: Member;
  inquiries$: Observable<Inquiry[]>;
  basicInquiries$: Observable<any[]>;
  complainInquiries$: Observable<any[]>;
  lineSegment = 'basic';

  constructor(
    private navController: NavController,
    private db: DbService,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    this.inquiries$ = this.getInquiries();
    this.basicInquiries$ = this.inquiries$.pipe(
      map((inquiries) =>
        inquiries.filter((inquiry) => inquiry.type === 'basic')
      )
    );
    this.complainInquiries$ = this.inquiries$.pipe(
      map((inquiries) =>
        inquiries.filter((inquiry) => inquiry.type === 'complain')
      )
    );
  }

  // 1:1문의 데이터 불러오기
  getInquiries() {
    const type = this.member.isExpert ? 'expert' : 'member';
    return this.db.collection$(`inquiries`, (ref) =>
      ref
        .where('uuid', '==', this.member.uuid)
        .where('userType', '==', type)
        .orderBy('dateCreated', 'desc')
    );
  }

  //문의 작성
  goWrite() {
    this.navController.navigateForward('/qna-write');
  }

  //상세
  goDetail(id: Inquiry['id']) {
    this.navController.navigateForward('/qna-detail', {
      queryParams: {
        id,
      },
    });
  }

  segmentChanged(event: any) {
    this.lineSegment = event.detail.value;
  }

  // 인피니티 스크롤
  loadData(ev) {
    setTimeout(() => {
      this.topLimit += 10;
      ev.target.complete();
    }, 500);
  }
}
