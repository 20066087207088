import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ImageService } from 'src/app/core/services/image.service';
import { RequestService } from 'src/app/core/services/request.service';

@Component({
  selector: 'app-application-image',
  templateUrl: './application-image.component.html',
  styleUrls: ['./application-image.component.scss'],
})
export class ApplicationImageComponent implements OnInit {
  images: string[] = Array(6).fill(null);
  imageLength: number = 0; // 등록 된 이미지 갯수

  constructor(
    private navController: NavController,
    private imgService: ImageService,
    private requestService: RequestService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // 저장된 데이터가 있다면 불러오기
    const reservation = this.requestService.request;
    if (reservation.images) {
      this.images = reservation.images.slice(0, 6);
      if (this.images.length < 6) {
        this.images = [
          ...this.images,
          ...Array(6 - this.images.length).fill(null),
        ];
      }
      this.imageLength = this.emptyImages()?.length;
    }
  }

  // 이미지 추가
  async addImg(index: number) {
    const imgUrl = await this.imgService.selectCamera('request');
    this.images[index] = imgUrl;
    this.imageLength = this.emptyImages()?.length;
  }

  // 이미지 삭제
  removeImg(index: number) {
    this.images[index] = null;
    this.imageLength = this.emptyImages()?.length;
  }

  //이전
  goBack() {
    const images = this.emptyImages();
    const reservation = this.requestService.request;
    reservation.images = images;
    this.navController.pop();
  }

  //추가 문의사항으로
  goLastAddInquiry() {
    const images = this.emptyImages();
    const reservation = this.requestService.request;
    reservation.images = images;

    const topId = this.activatedRoute.snapshot.queryParams['topId'];
    const subId = this.activatedRoute.snapshot.queryParams['subId'];
    const requestType = this.activatedRoute.snapshot.queryParams['requestType'];
    const expertUuid = this.activatedRoute.snapshot.queryParams['expertUuid'];
    this.navController.navigateForward('/application-last-inquiry', {
      queryParams: {
        topId,
        subId,
        requestType,
        expertUuid,
      },
    });
  }

  // 이미지 비었는지 확인
  emptyImages() {
    return this.images.filter((img) => img != null && img.trim() !== '');
  }
}
