import { Injectable } from '@angular/core';
import { DbService } from './db.service';
import { CommonService } from './common.service';
import { Member } from '../models/member.model';
import { AuthService } from './auth.service';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  map,
  of,
  switchMap,
} from 'rxjs';
import * as dayjs from 'dayjs';
import {
  arrayRemove,
  arrayUnion,
  doc,
  runTransaction,
} from 'firebase/firestore';
import { Chat, ChatList, Message } from '../models/chat.model';
import { Request } from '../models/request.model';
import { Post } from '../models/post.model';
import { Estimate } from '../models/estimate.model';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  chatSub: Subscription;
  chat$: BehaviorSubject<Chat>;

  constructor(
    private db: DbService,
    private commonService: CommonService,
    private auth: AuthService
  ) {}

  /**
   * 채팅방 생성
   *
   * @param requestId 채팅방이 생성된 요청서 id
   * @param estimateId 채팅방이 생성된 견적서 id
   * @param partner 채팅방을 만들 상대방 유저들
   */
  async createChat(
    requestId: Request['id'],
    estimateId: Estimate['id'],
    partner: Member['uuid']
  ): Promise<string> {
    let chatId: string;

    const member = await this.auth.getUser();
    const uuid = member.uuid;

    let myChat: Chat[] = await this.db.toCollection$('chats', (ref) =>
      ref.where('uuid', 'array-contains', uuid)
    );

    let chats = myChat.filter(
      (chat) =>
        (chat.uuid.includes(uuid) || chat.exitUuid.includes(uuid)) &&
        requestId === chat.requestId
    );

    if (chats.length > 0) {
      if (chats[0].exitUuid.includes(uuid)) {
        await this.db.updateAt(`chats/${chats[0].id}`, {
          [uuid]: {
            readIndex: chats[0].messagesLength,
            startIndex: chats[0].messagesLength,
            partner,
          },
          uuid: arrayUnion(uuid),
          exitUuid: arrayRemove(uuid),
        });
      }

      chatId = chats[0].id;
    } else {
      const chat: Chat = {
        id: this.commonService.generateFilename(),
        dateCreated: new Date().toISOString(),
        messagesLength: 0,
        requestId,
        estimateId,
        uuid: [uuid, partner],
        [uuid]: {
          readIndex: 0,
          startIndex: 0,
          partner: partner,
        },
        [partner]: {
          readIndex: 0,
          startIndex: 0,
          partner: uuid,
        },
        exitUuid: [],
      };
      await this.db.updateAt(`chats/${chat.id}`, chat);
      chatId = chat.id;
    }

    return chatId;
  }

  /**
   * 채팅리스트 가져오기
   * @param contentId 좋아요한 채팅에서 진입시
   */
  getChatList(contentId?: string): Observable<ChatList[]> {
    return this.auth.user$.pipe(
      switchMap((member: Member): Observable<ChatList[]> => {
        if (!member || !member.dateCreated) {
          return of([]);
        } else {
          let uuid: string = member.uuid;
          return this.db
            .collection$('chats', (ref) =>
              ref
                .where('uuid', 'array-contains', member.uuid)
                .orderBy('dateCreated', 'desc')
            )
            .pipe(
              switchMap((chats: Chat[]) => {
                if (chats.length) {
                  let result = chats
                    .filter((chat: Chat) => {
                      const isNotBlocked = !member.blockUser.includes(
                        chat[uuid].partner
                      );
                      const hasUnreadMessages =
                        chat.messagesLength !== chat[uuid].startIndex;
                      const matchesContentId = contentId
                        ? chat.id === contentId
                        : true;

                      return (
                        isNotBlocked && hasUnreadMessages && matchesContentId
                      );
                    })
                    .map((chat: Chat) => {
                      let partner: Observable<Member> = this.db.doc$(
                        `members/${chat[uuid].partner}`
                      );

                      let messages: Observable<Message[]> = this.db.collection$(
                        `messages`,
                        (ref) =>
                          ref
                            .where('chatId', '==', chat.id)
                            .orderBy('index', 'desc')
                            .orderBy('dateCreated', 'asc')
                        // .limit(1)
                      );

                      const estimate: Observable<Estimate> = this.db.doc$(
                        `estimates/${chat.estimateId}`
                      );

                      const request: Observable<Request> = this.db.doc$(
                        `requests/${chat.requestId}`
                      );

                      return combineLatest(
                        of(chat),
                        partner,
                        estimate,
                        request,
                        messages
                      );
                    });

                  if (result?.length == 0) {
                    return of([]);
                  }

                  return combineLatest(result);
                } else {
                  return of([]);
                }
              }),
              map((chats: any) => {
                return chats.map(
                  ([
                    chatData,
                    partnerData,
                    estimateData,
                    requestData,
                    messagesData,
                  ]) => {
                    const chat: Chat = chatData;
                    const partner: Member = partnerData;
                    const estimate: Estimate = estimateData;
                    const request: Request = requestData;
                    const messages: Message[] = messagesData;

                    let chatList: ChatList = {
                      id: chat.id,
                      dateCreated: chat.dateCreated,
                      uuid: chat.uuid,
                      partner,
                      // lastChat:
                      //   messages[0] &&
                      //   messages[0].index >= chat[uuid].startIndex
                      //     ? messages[0]
                      //     : null,
                      lastChat:
                        messages[0] &&
                        messages[0].type === 'refund' &&
                        messages[0].uuid !== member.uuid
                          ? messages[1]
                          : messages[0] &&
                            messages[0].type === 'refund' &&
                            messages[0].uuid === member.uuid &&
                            messages[0].index >= chat[uuid].startIndex
                          ? messages[0]
                          : messages[0] &&
                            messages[0].index >= chat[uuid].startIndex
                          ? messages[0]
                          : null,
                      messagesLength: chat.messagesLength,
                      estimate,
                      request,
                      myInfo: chat[uuid],
                      checkSwitch: false,
                    };
                    return chatList;
                  }
                );
              }),
              map((chatlist: ChatList[]) => {
                // 채팅 최신순 정렬
                return chatlist.sort((a, b) =>
                  (a.lastChat ? a.lastChat.dateCreated : a.dateCreated) >
                  (b.lastChat ? b.lastChat.dateCreated : b.dateCreated)
                    ? -1
                    : (a.lastChat ? a.lastChat.dateCreated : a.dateCreated) <
                      (b.lastChat ? b.lastChat.dateCreated : b.dateCreated)
                    ? 1
                    : 0
                );
              })
            );
        }
      })
    );
  }

  /**
   * 채팅내용 가져오기
   * @param chatId 불러올 채팅의 id
   */
  chatInit(chatId): Promise<BehaviorSubject<Chat>> {
    this.clearChat();
    return new Promise(async (resolve) => {
      const member = await this.auth.getUser();
      const uuid = member.uuid;

      this.chatSub = this.db
        .doc$(`chats/${chatId}`)
        .pipe(
          switchMap((chat: Chat) => {
            let partner: Observable<Member> = this.db.doc$(
              `members/${chat[uuid].partner}`
            );

            return combineLatest(
              of(chat),
              partner,

              this.db.doc$(`estimates/${chat.estimateId}`),
              this.db.doc$(`requests/${chat.requestId}`),
              this.db
                .collection$(`messages`, (ref) =>
                  ref
                    .where('chatId', '==', chatId)
                    .where('index', '>=', chat[uuid].startIndex - 1)
                    .orderBy('index', 'desc')
                )
                .pipe(
                  map((messages: Message[]) => {
                    if (
                      messages.length === 1 &&
                      chat.messagesLength > 1 &&
                      chat.messagesLength - chat[uuid].startIndex > 1
                    ) {
                      return [];
                    } else {
                      return messages
                        .filter(
                          (e) =>
                            e.type === 'estimateId' ||
                            e.index >= chat[uuid].startIndex
                        )
                        .sort((a, b) =>
                          a.dateCreated > b.dateCreated
                            ? 1
                            : a.dateCreated < b.dateCreated
                            ? -1
                            : 0
                        );
                    }
                  })
                )
            );
          })
        )
        .subscribe(
          ([
            chatData,
            partnersData,
            estimateData,
            requestData,
            messagesData,
          ]) => {
            let chat: Chat = chatData;
            let partner: Member = partnersData;
            const estimate: Estimate = estimateData;
            const request: Request = requestData;
            let messages: Message[] = messagesData;
            let result = { ...chat, partner, estimate, request, messages };
            if (this.chat$) {
              if (
                (chat.messagesLength > 1 && messages.length > 1) ||
                chat.messagesLength <= 1
              ) {
                this.chat$.next(result);
              }
            } else {
              this.chat$ = new BehaviorSubject(result);
            }
            resolve(this.chat$);
          }
        );
    });
  }

  /**
   * 메세지 보내기
   *
   * @param chatId 채팅을 보내는 채팅방의 id
   * @param type 보내는 채팅의 종류 (txt | img | estimateId(견적서 id))
   * @param content 채팅 내용 (글 혹은 이미지 url)
   * @param index 해당 채팅의 index
   * @returns
   */
  async sendMessage(
    chatId: string,
    type:
      | 'txt' // 텍스트
      | 'img' // 이미지
      | 'estimateId' // 견적서 안내
      | 'requestDeal' // 거래 요청
      | 'requestReview' // 리뷰 요청
      | 'confirmDeal' // 거래 완료
      | 'viewEstimate' // 견적서 확인
      | 'refund', // 48시간 미확인 환불
    content: string
  ): Promise<void> {
    const member = await this.auth.getUser();
    const uuid = member.uuid;

    let message: Message = {
      id: this.commonService.generateFilename(),
      dateCreated: new Date().toISOString(),
      chatId,
      content,
      index: 0,
      type,
      uuid,
    };

    try {
      runTransaction(this.db.firestore, async (transaction) => {
        const chatDocRef = doc(this.db.firestore, `chats/${chatId}`);
        const chatDoc = await transaction.get(chatDocRef);

        if (!chatDoc.exists()) {
          throw 'Document does not exist!';
        }

        let chatData = chatDoc.data();
        message.index = chatData.messagesLength;
        const messagesLength = message.index + 1;

        transaction.update(chatDocRef, {
          messagesLength,
          [uuid]: {
            readIndex: message.index + 1,
            startIndex: chatData[uuid].startIndex || 0,
            partner: chatData[uuid].partner,
          },
        });

        await this.db.updateAt(`messages/${message.id}`, message);
      });
    } catch (e) {}
  }

  /**
   * 안읽은 채팅갯수(뱃지)
   * @returns
   */
  getBadge(): Observable<number> {
    if (this.auth.user$) {
      return this.auth.user$.pipe(
        switchMap((member: Member): Observable<number> => {
          if (!member) {
            return of(0);
          } else {
            let uuid = member.uuid;
            return this.db
              .collection$('chats', (ref) =>
                ref
                  .where('uuid', 'array-contains', uuid)
                  .where('exitUuid', '==', [])
              )
              .pipe(
                map((chats: Chat[] | any) => {
                  // 차단한 유저는 걸러지도록 필터(리젝사유로 인한 추가)
                  const chatDatas = chats.filter((v) => {
                    const partnerUuid = v.uuid.find(
                      (id: string) => id !== member.uuid
                    );
                    return !member.blockUser.includes(partnerUuid);
                  });

                  let unReads: number = 0;
                  for (let i = 0; i < chatDatas.length; i++) {
                    let chat = chatDatas[i];
                    if (chat.messagesLength == chat[uuid].startIndex) {
                      continue;
                    }
                    if (chat.messagesLength == chat[uuid].checkLength) {
                      continue;
                    }

                    let unRead = chat.messagesLength - chat[uuid].readIndex;
                    unReads += unRead;
                  }
                  return unReads;
                })
              );
          }
        })
      );
    }
  }

  /**
   * 채팅방 나가기
   *
   * @param chatId 나가는 채팅방의 id
   * @param isExit 채팅방 삭제여부(신고 || 차단)
   */
  async exitChat(chatId: Chat['id'], isExit?: boolean): Promise<void> {
    const member = await this.auth.getUser();
    let uuid = member.uuid;

    runTransaction(this.db.firestore, async (transaction) => {
      const chatDocRef = doc(this.db.firestore, `chats/${chatId}`);
      const chatDoc = await transaction.get(chatDocRef);
      if (!chatDoc.exists()) {
        throw 'Document does not exist!';
      }

      const chatData = chatDoc.data();
      const messagesLength = chatData.messagesLength;

      const updateData = {
        [uuid]: {
          readIndex: messagesLength,
          startIndex: messagesLength,
          partner: chatData[uuid].partner,
        },
      };

      if (isExit) {
        updateData['uuid'] = arrayRemove(uuid);
        updateData['exitUuid'] = arrayUnion(uuid);
      }

      transaction.update(chatDocRef, updateData);
    });
  }

  /**
   *
   * @param chatId 현재 채팅 ID
   * @param messagesLength 저장할 메세지 숫자
   */
  async setReadIndex(chatId: Chat['id'], messagesLength: number) {
    const member = await this.auth.getUser();
    const uuid = member.uuid;

    this.db.updateAt(`chats/${chatId}`, {
      [uuid]: {
        readIndex: messagesLength,
      },
    });
  }

  clearChat() {
    if (this.chatSub) {
      this.chatSub.unsubscribe();
    }
    this.chat$ = null;
  }
}
