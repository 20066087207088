import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Swiper, Pagination, Zoom } from 'swiper';

@Component({
  selector: 'app-image-detail',
  templateUrl: './image-detail.component.html',
  styleUrls: ['./image-detail.component.scss'],
})
export class ImageDetailComponent implements OnInit {
  @ViewChild('videoPlayer') videoPlayer: any; // ViewChild를 사용하여 video 엘리먼트에 액세스
  datas = new Array(9);
  @Input() item;
  @Input() idx: number;
  playing = false;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    //스와이퍼
    setTimeout(() => {
      this.swiperOptions();
    }, 100);
  }

  // 스와이퍼
  swiperOptions() {
    const swiper = new Swiper('.swiperImgdetail', {
      slidesPerView: 1,
      spaceBetween: 10,
      initialSlide: this.idx,

      // 페이지네이션
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },

      // 확대
      zoom: {
        maxRatio: 10,
      },

      modules: [Zoom, Pagination],
    });

    swiper.update();
  }

  // 비디오 재생
  playVideo() {
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      this.videoPlayer.nativeElement.play();
      this.playing = true;
    }
  }

  // 비디오 정지
  pauseVideo() {
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      this.videoPlayer.nativeElement.pause();
      this.playing = false;
    }
  }

  // [모달 닫힘]
  modalClose() {
    this.modalController.dismiss();
  }
}
