import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent implements OnInit {
  member: Member;

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private auth: AuthService,
    private db: DbService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
  }

  //뒤로 얼럿
  closeModal() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.modalController.dismiss();
        }
      });
  }

  //저장
  async save() {
    await this.db.updateAt(`members/${this.member.uuid}`, {
      links: this.member.links,
    });
    this.modalController.dismiss({
      links: this.member.links,
    });
    this.alertService.presentToast('링크를 업데이트 하였습니다.');
  }
}
