import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { RequestService } from 'src/app/core/services/request.service';

@Component({
  selector: 'app-application-textarea',
  templateUrl: './application-textarea.component.html',
  styleUrls: ['./application-textarea.component.scss'],
})
export class ApplicationTextareaComponent implements OnInit {
  textForm = new FormGroup({
    detail: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(200),
    ]),
  });
  constructor(
    private navController: NavController,
    private requestService: RequestService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // 저장된 데이터가 있다면 불러오기
    const reservation = this.requestService.request;
    if (reservation.part) {
      this.textForm.get('detail').setValue(reservation.part);
    }
  }

  //이전
  goBack() {
    const reservation = this.requestService.request;
    reservation.part = this.textForm.get('detail').value;
    this.navController.pop();
  }

  //다음. 이미지 등록
  goAddImage() {
    const reservation = this.requestService.request;
    reservation.part = this.textForm.get('detail').value;

    const topId = this.activatedRoute.snapshot.queryParams['topId'];
    const subId = this.activatedRoute.snapshot.queryParams['subId'];
    const requestType = this.activatedRoute.snapshot.queryParams['requestType'];
    const expertUuid = this.activatedRoute.snapshot.queryParams['expertUuid'];
    this.navController.navigateForward('/application-image', {
      queryParams: {
        topId,
        subId,
        requestType,
        expertUuid,
      },
    });
  }
}
