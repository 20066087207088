// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.padding-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.icon {
  width: 5.25rem;
  height: 5.25rem;
  margin-bottom: 1.25rem;
}
.icon img {
  width: 100%;
  height: 100%;
}

p {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 700;
  color: var(--gray-scale900);
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/application-complete/application-complete.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,sBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;AAEJ;;AAEA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,2BAAA;EACA,kBAAA;AACF","sourcesContent":[".padding-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n\n.icon {\n  width: 5.25rem;\n  height: 5.25rem;\n  margin-bottom: 1.25rem;\n  img {\n    width: 100%;\n    height: 100%;\n  }\n}\n\np {\n  font-size: 1.125rem;\n  line-height: 1.625rem;\n  font-weight: 700;\n  color: var(--gray-scale900);\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
