// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 700;
  color: var(--gray-scale900);
  margin-bottom: 0.25rem;
}

.sub {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 400;
  color: var(--gray-scale900);
  margin-bottom: 2.5rem;
}

.blk-line-btn {
  width: 100%;
  height: 3.25rem;
  border: 1px solid var(--gray-scale900);
  border-radius: 6px;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  font-weight: 500;
  color: var(--gray-scale900);
  --color: var(--gray-scale900);
}
.blk-line-btn[disabled] {
  border: 1px solid var(--gray-scale300);
  font-weight: 400;
  color: var(--gray-scale300);
  --color: var(--gray-scale300);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/withdrawal-check/withdrawal-check.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,2BAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,2BAAA;EACA,qBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,sCAAA;EACA,kBAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;EACA,2BAAA;EACA,6BAAA;AACF;AAAE;EACE,sCAAA;EACA,gBAAA;EACA,2BAAA;EACA,6BAAA;AAEJ","sourcesContent":["h3 {\n  font-size: 1.125rem;\n  line-height: 1.625rem;\n  font-weight: 700;\n  color: var(--gray-scale900);\n  margin-bottom: 0.25rem;\n}\n\n.sub {\n  font-size: 1.125rem;\n  line-height: 1.625rem;\n  font-weight: 400;\n  color: var(--gray-scale900);\n  margin-bottom: 2.5rem;\n}\n\n.blk-line-btn {\n  width: 100%;\n  height: 3.25rem;\n  border: 1px solid var(--gray-scale900);\n  border-radius: 6px;\n  font-size: 1.125rem;\n  line-height: 1.6875rem;\n  font-weight: 500;\n  color: var(--gray-scale900);\n  --color: var(--gray-scale900);\n  &[disabled] {\n    border: 1px solid var(--gray-scale300);\n    font-weight: 400;\n    color: var(--gray-scale300);\n    --color: var(--gray-scale300);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
