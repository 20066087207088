import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { SubCategory } from 'src/app/core/models/category.model';
import { Member } from 'src/app/core/models/member.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { ExpertService } from 'src/app/core/services/expert.service';

@Component({
  selector: 'app-join-expert',
  templateUrl: './join-expert.component.html',
  styleUrls: ['./join-expert.component.scss'],
})
export class JoinExpertComponent implements OnInit {
  member: Member;
  subCategories: SubCategory[];
  copyCategories: SubCategory[];
  keyword: string = '';
  selectedServices = {};
  trueServiceIds: string[] = [];

  isAnyCheckboxSelected() {
    return Object.values(this.selectedServices).includes(true);
  }

  constructor(
    private navController: NavController,
    private auth: AuthService,
    private db: DbService,
    private expertService: ExpertService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    await this.getData();
    const data = this.expertService.getSelectedServices();
    this.selectedServices = data.select || {};
    this.trueServiceIds = data.trueServiceIds || [];
  }

  // 선택된 값의 id만
  trueIds(services: any): string[] {
    return Object.keys(services).filter((key) => services[key] === true);
  }

  // 서브카테고리 데이터 불러오기
  async getData() {
    this.subCategories = this.copyCategories = await this.db.toCollection$(
      'subCategories',
      (ref) =>
        ref.where('deleteSwitch', '==', false).orderBy('dateCreated', 'asc')
    );
  }

  // 검색
  goSearch(keyword: string) {
    this.subCategories = this.copyCategories.filter((val) =>
      val?.name.includes(keyword)
    );
  }

  //시공 가능 지역으로 이동
  goAddress() {
    this.trueServiceIds = this.trueIds(this.selectedServices);
    this.expertService.setSelectedServices({
      subIds: this.trueServiceIds,
    });
    this.navController.navigateForward('/join-expert-address', {
      queryParams: { type: 'join' },
    });
  }

  // 뒤로가기
  close() {
    this.expertService.clearData();
    this.navController.pop();
  }
}
