// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-btn {
  width: 1.5rem;
  height: 1.5rem;
  --background: url('pop-close-btn.png') no-repeat center /
    100% 100%;
  --border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
}

.container-wrap {
  position: relative;
}
.container-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.view-btn {
  width: 100%;
  height: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: var(--gray-scale900);
  --background: var(--gray-scale050);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/home-pop/home-pop.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA;aAAA;EAEA,oBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AACJ;;AAGA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;EACA,kCAAA;AAAF","sourcesContent":[".close-btn {\n  width: 1.5rem;\n  height: 1.5rem;\n  --background: url(../../../../assets/imgs/pop-close-btn.png) no-repeat center /\n    100% 100%;\n  --border-radius: 50%;\n  border-radius: 50%;\n  position: absolute;\n  top: 0.625rem;\n  right: 0.625rem;\n}\n\n.container-wrap {\n  position: relative;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n\n.view-btn {\n  width: 100%;\n  height: 3rem;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 500;\n  color: var(--gray-scale900);\n  --background: var(--gray-scale050);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
