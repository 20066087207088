// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.padding-container {
  width: 100%;
  padding: 1.5rem 1rem;
}

.save-btn {
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--main);
  --color: var(--main);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/password-edit/password-edit.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,oBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,oBAAA;AACF","sourcesContent":[".padding-container {\n  width: 100%;\n  padding: 1.5rem 1rem;\n}\n\n.save-btn {\n  width: 2.75rem;\n  height: 2.75rem;\n  font-size: 1rem;\n  line-height: 1.5rem;\n  font-weight: 700;\n  color: var(--main);\n  --color: var(--main);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
