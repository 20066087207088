import { Component, Input, OnInit } from '@angular/core';
import { SubCategory } from 'src/app/core/models/category.model';
import { Request } from 'src/app/core/models/request.model';
import { Member } from 'src/app/core/models/member.model';
import { DbService } from 'src/app/core/services/db.service';
import { Review } from 'src/app/core/models/review.model';

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.scss'],
})
export class CategoryItemComponent implements OnInit {
  @Input() expertUuid: Member['uuid'];
  @Input() writerUuid: Member['uuid'];
  @Input() type: 'member' | 'expert';
  @Input() subCategoryName: SubCategory['name'];
  @Input() requestId: Request['id'];
  @Input() reviewId: Review['id'];

  expert: Member;
  writer: Member;
  request: Request;
  serviceName: string;
  review: Review;

  constructor(private db: DbService) {}

  async ngOnInit() {
    this.expert = await this.db.toDoc$(`members/${this.expertUuid}`); // 전문가

    // 관리자가 작성한 리뷰가 아닌경우에만 보여지도록
    if (this.writerUuid != 'admin' || this.requestId != 'admin') {
      this.writer = await this.db.toDoc$(`members/${this.writerUuid}`); // 리뷰 작성자
      this.request = await this.db.toDoc$(`requests/${this.requestId}`);
    }
    // 관리자가 작성한 경우
    if (this.reviewId) {
      this.review = await this.db.toDoc$(`reviews/${this.reviewId}`);
    }

    let noselectService;
    if (this.review?.isAdminWrite) {
      const sub = await this.db.toDoc$(
        `subCategories/${this.review.subCategoryId}`
      );
      this.serviceName = sub.name;
    } else if (this.request?.subCategoryId) {
      const sub = await this.db.toDoc$(
        `subCategories/${this.request.subCategoryId}`
      );
      this.serviceName = sub.name;
    } else {
      noselectService = await Promise.all(
        this.expert.services.map(async (v) => {
          const sub = await this.db.toDoc$(`subCategories/${v}`);
          return sub.name;
        })
      );
      this.serviceName = noselectService.join('/');
    }
  }
}
