import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { DbService } from 'src/app/core/services/db.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Member } from 'src/app/core/models/member.model';
import { InsurancePayment } from 'src/app/core/models/insurance-payment.model';
import { CommonService } from 'src/app/core/services/common.service';
import { Notification } from 'src/app/core/models/notification.model';
import { Request } from 'src/app/core/models/request.model';
import * as dayjs from 'dayjs';
import { increment } from 'firebase/firestore';
import { InAppService } from 'src/app/core/services/in-app.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { Insurance, Point } from 'src/app/core/models/admin.model';
import { Capacitor } from '@capacitor/core';
import { IamportService } from 'src/app/core/services/iamport.service';

@Component({
  selector: 'app-insurance-payment',
  templateUrl: './insurance-payment.component.html',
  styleUrls: ['./insurance-payment.component.scss'],
})
export class InsurancePaymentComponent implements OnInit {
  store?: CdvPurchase.Store;
  isWeb: boolean;
  member: Member;

  points = [];

  serviceName: string;
  expertUuid: Member['uuid'];
  insuranceBenefit = { title: '', content: '' };
  select: number; // 선택한 보험료
  selectId: Point['id'];
  requestId: Request['id'];
  agree: boolean = false;
  insuranceName: string = '';

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private db: DbService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    public platform: Platform,
    private commonService: CommonService,
    private inAppService: InAppService,
    private loadingService: LoadingService,
    private iamportService: IamportService
  ) {}

  async ngOnInit() {
    this.store = CdvPurchase.store;
    if (!Capacitor.isNativePlatform()) {
      this.isWeb = true;
    } else {
      this.isWeb = false;
    }

    this.expertUuid = this.activatedRoute.snapshot.queryParams['expertUuid'];
    this.serviceName = this.activatedRoute.snapshot.queryParams['serviceName'];
    this.requestId = this.activatedRoute.snapshot.queryParams['requestId'];

    // 보험료 혜택 설명관리
    const admin = await this.db.toDoc$(`admin/terms`);
    const info = admin.insuranceBenefit;
    this.insuranceBenefit = { title: info.title, content: info.content };
  }

  // 관리자가 설정한 보험료 금액 데이터 불러오기
  async ionViewWillEnter() {
    this.member = await this.auth.getUser();
    const admin = await this.db.toDoc$(`admin/management`);
    this.points = admin.insurance;

    const params = this.activatedRoute.snapshot.queryParams;
    if (params && (params.imp_success || params.imp_success == 'true')) {
      await this.loadingService.load();
      const obj = localStorage.getItem('obj');
      const conversion = JSON.parse(obj);

      this.expertUuid = conversion.expertUuid;
      this.serviceName = conversion.serviceName;
      this.requestId = conversion.requestId;
      this.insuranceName = conversion.insuranceName;
      this.select = conversion.select;
      this.selectId = conversion.selectId;

      await this.savePaymentData();
      localStorage.removeItem('obj');
    }
  }

  // 닫기
  closeModal() {
    this.navController.pop();
  }

  // 보험료 약관
  goTerms() {
    this.navController.navigateForward('/terms', {
      queryParams: { type: 'insuranceInfo' },
    });
  }

  onRadioChange(event: any) {
    const selectedValue = event.detail.value;
    const selectedItem = this.points.find(
      (item) =>
        (this.platform.is('ios') || this.platform.is('android')
          ? item.inapp
          : item.pg) === selectedValue
    );

    if (selectedItem) {
      this.selectId = selectedItem.id;
      this.select = selectedValue;
    }
  }

  // 보험id
  insurance(item: Insurance) {
    this.insuranceName = item.section;
  }

  // 결제하기
  async goComplete() {
    await this.loadingService.load();

    const request = await this.db.toDoc$(`requests/${this.requestId}`);
    const expert = await this.db.toDoc$(`members/${this.expertUuid}`);
    let payment: InsurancePayment = {
      id: this.commonService.generateFilename(),
      uuid: this.member.uuid,
      dateCreated: new Date().toISOString(),
      serviceName: this.serviceName,
      hopeDate: request.hopeDate,
      insuranceType: `${this.insuranceName} 이하 공사`,
      expertName: expert.activityName,
      expertUuid: this.expertUuid,
      price: this.select,
      isRefund: false,
      method: this.isWeb ? 'PG 결제' : '인앱 결제',
      requestId: this.requestId,
    };

    // 인앱결제
    if (!this.isWeb) {
      this.inAppService.setState = true;
      let productId = this.selectId;

      let product: CdvPurchase.Product = this.inAppService.products.find(
        (e) => e.id === productId && e.canPurchase
      );

      if (product) {
        // 결제 성공 후 처리할 콜백 함수 정의
        this.inAppService.setPaymentSuccessCallback(async (data) => {
          try {
            // 관리자 대시보드 업데이트
            const id = dayjs().format('YYYYMMDD');
            const statisticsData = await this.db.toDoc$(`statistics/${id}`);

            await Promise.all([
              this.db.updateAt(`insurancePayments/${payment.id}`, payment),
              this.db.updateAt(`statistics/${id}`, {
                id,
                date: {
                  year: dayjs().format('YYYY'),
                  month: dayjs().format('MM'),
                  date: dayjs().format('DD'),
                },
                joinMembersCount: statisticsData
                  ? statisticsData.joinMembersCount
                  : 0,
                exitMembersCount: statisticsData
                  ? statisticsData.exitMembersCount
                  : 0,
                pointPaymentCount: statisticsData
                  ? statisticsData.pointPaymentCount
                  : 0,
                pointRefundCount: statisticsData
                  ? statisticsData.pointRefundCount
                  : 0,
                insurancePaymentCount: increment(this.select),

                insuranceRefundCount: statisticsData
                  ? statisticsData.insuranceRefundCount
                  : 0,
              }),
              this.sendAlarm(),
            ]);
            this.loadingService.hide();
            this.modalController.dismiss();
            this.navController.navigateForward('/insurance-complete', {
              queryParams: { paymentId: payment.id },
            });
          } catch (error) {
            this.loadingService.hide();
          }
        });

        try {
          this.loadingService.hide();
          await this.store.order(product.offers[0]);
        } catch (error) {
          this.loadingService.hide();
          if (error.isError) {
          }
        }
      }
    }
    // PG 결제
    else {
      this.loadingService.hide();
      try {
        let obj = {
          expertUuid: this.expertUuid,
          serviceName: this.serviceName,
          requestId: this.requestId,
          insuranceName: this.insuranceName,
          select: this.select,
          selectId: this.selectId,
        };
        localStorage.setItem('obj', JSON.stringify(obj));

        const pay = await this.iamportService.payment(
          Number(this.select),
          this.member.name,
          this.member.phone,
          this.member.email,
          'member'
        );

        if (pay === true) {
          this.loadingService.load();
          this.savePaymentData();
          this.loadingService.hide();
        } else {
          this.loadingService.hide();
        }
      } catch (error) {
        this.loadingService.hide();
      }
    }
  }

  // 보험료 PG결제 함수
  async savePaymentData() {
    try {
      const request = await this.db.toDoc$(`requests/${this.requestId}`);
      const expert = await this.db.toDoc$(`members/${this.expertUuid}`);
      let payment: InsurancePayment = {
        id: this.commonService.generateFilename(),
        uuid: this.member.uuid,
        dateCreated: new Date().toISOString(),
        serviceName: this.serviceName,
        hopeDate: request.hopeDate,
        insuranceType: `${this.insuranceName} 이하 공사`,
        expertName: expert.activityName,
        expertUuid: this.expertUuid,
        price: this.select,
        isRefund: false,
        method: this.isWeb ? 'PG 결제' : '인앱 결제',
        requestId: this.requestId,
      };

      // 관리자 대시보드 업데이트
      const id = dayjs().format('YYYYMMDD');
      const statisticsData = await this.db.toDoc$(`statistics/${id}`);
      await Promise.all([
        this.db.updateAt(`insurancePayments/${payment.id}`, payment),
        this.db.updateAt(`statistics/${id}`, {
          id,
          date: {
            year: dayjs().format('YYYY'),
            month: dayjs().format('MM'),
            date: dayjs().format('DD'),
          },
          joinMembersCount: statisticsData
            ? statisticsData.joinMembersCount
            : 0,
          exitMembersCount: statisticsData
            ? statisticsData.exitMembersCount
            : 0,
          pointPaymentCount: statisticsData
            ? statisticsData.pointPaymentCount
            : 0,
          pointRefundCount: statisticsData
            ? statisticsData.pointRefundCount
            : 0,
          insurancePaymentCount: increment(this.select),

          insuranceRefundCount: statisticsData
            ? statisticsData.insuranceRefundCount
            : 0,
        }),
        this.sendAlarm(),
      ]);

      this.loadingService.hide();
      this.modalController.dismiss();
      this.navController.navigateForward('/insurance-complete', {
        queryParams: { paymentId: payment.id },
      });
    } catch (error) {
      this.loadingService.hide();
    }
  }

  // 관리자에게 알림보내기
  async sendAlarm() {
    let notifications: Notification = {
      id: this.commonService.generateFilename(),
      uuid: 'admin',
      dateCreated: new Date().toISOString(),
      readSwitch: false,
      title: '보험료 신청 요청',
      content: '보험료 신청이 접수 되었습니다.',
      url: '/insurance-payment-list',
      checkSwitch: false,
      isAdminPush: false,
    };
    await this.db.updateAt(`notifications/${notifications.id}`, notifications);
  }
}
