import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Storage, ref, uploadBytes, uploadString } from '@angular/fire/storage';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  constructor(
    private actionSheetController: ActionSheetController,
    private storage: Storage
  ) {}

  async selectMedia(parentPath: string): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      const actionSheet = await this.actionSheetController.create({
        cssClass: 'actionsheet-custom',
        buttons: [
          {
            text: '카메라',
            handler: async () => {
              const cameraUrl = await this.captureFromCamera(parentPath);
              resolve([cameraUrl]);
            },
          },
          {
            text: '갤러리',
            handler: () => {
              this.selectFromGallery(parentPath).then(resolve).catch(reject);
            },
          },
          {
            text: '취소',
            role: 'cancel',
          },
        ],
      });

      await actionSheet.present();
    });
  }

  // 카메라
  async captureFromCamera(parentPath: string): Promise<string> {
    try {
      const capturedImage = await Camera.getPhoto({
        quality: 60,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
      });

      const base64DataUrl = capturedImage.dataUrl;
      const fileName = `${this.generateFilename()}.jpeg`;
      const storageRef = ref(this.storage, `${parentPath}/${fileName}`);
      await uploadString(storageRef, base64DataUrl, 'data_url');
      const downloadUrl = `https://storage.googleapis.com/${storageRef.bucket}/${storageRef.fullPath}`;
      return downloadUrl;
    } catch (error) {
      console.error('Error capturing from camera:', error);
      throw error;
    }
  }

  // 갤러리
  async selectFromGallery(parentPath: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.accept = 'image/*,video/*';
      inputElement.multiple = true;

      inputElement.onchange = async (event: any) => {
        const files = event.target.files;
        const urls: string[] = [];

        for (const file of files) {
          const fileName =
            this.generateFilename() + '.' + file.name.split('.').pop();

          const storageRef = ref(this.storage, `${parentPath}/${fileName}`);

          const fileSnapshot = await uploadBytes(storageRef, file);

          const url = `https://storage.googleapis.com/${fileSnapshot.metadata.bucket}/${fileSnapshot.metadata.fullPath}`;
          urls.push(url);
        }

        resolve(urls);
      };

      inputElement.click();
    });
  }

  // 랜덤 ID 생성
  generateFilename(): string {
    const length = 10;
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let text = '';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text + new Date().valueOf();
  }
}
