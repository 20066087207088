import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  loginType: string;
  textForm = new FormGroup({
    detail: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(500),
    ]),
  });

  constructor(
    private navController: NavController,
    private alertService: AlertService
  ) {
    //퍼블확인용 로컬스토리지
    this.loginType = localStorage.getItem('loginType');
  }

  ngOnInit() {}

  //뒤로 얼럿
  backAlert() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.navController.pop();
        }
      });
  }

  //신고하기
  reportAlert() {
    if (this.loginType === 'user') {
      this.alertService
        .cancelOkBtn(
          '전문가를 신고하시겠습니까? 전문가 신고 시 즉시 차단 됩니다.'
        )
        .then((ok) => {
          if (ok) {
            this.navController.navigateForward('/chat-list');
            this.alertService.presentToast('전문가를 신고 및 차단하였습니다.');
          }
        });
    } else {
      this.alertService
        .cancelOkBtn('고객을 신고하시겠습니까? 고객 신고 시 즉시 차단 됩니다.')
        .then((ok) => {
          if (ok) {
            this.navController.navigateForward('/chat-list');
            this.alertService.presentToast('고객을 신고 및 차단하였습니다.');
          }
        });
    }
  }
}
