// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: var(--gray-scale900);
  margin-bottom: 0.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.date {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--gray-scale500);
}

.common-item {
  --inner-padding-top: 1.25rem;
  --inner-padding-bottom: 1.25rem;
  --inner-padding-start: 1rem;
  --inner-padding-end: 2.5rem;
  border-bottom: 1px solid var(--gray-scale100);
  --background: url('notice-arrow.png') no-repeat center
    right 1rem / 1.5rem 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/notice-list/notice-list.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;AACF;;AAEA;EACE,4BAAA;EACA,+BAAA;EACA,2BAAA;EACA,2BAAA;EACA,6CAAA;EACA;8BAAA;AAEF","sourcesContent":[".title {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  font-weight: 500;\n  color: var(--gray-scale900);\n  margin-bottom: 0.25rem;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.date {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 400;\n  color: var(--gray-scale500);\n}\n\n.common-item {\n  --inner-padding-top: 1.25rem;\n  --inner-padding-bottom: 1.25rem;\n  --inner-padding-start: 1rem;\n  --inner-padding-end: 2.5rem;\n  border-bottom: 1px solid var(--gray-scale100);\n  --background: url(../../../../assets/imgs/notice-arrow.png) no-repeat center\n    right 1rem / 1.5rem 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
