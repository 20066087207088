// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mb-2 {
  margin-bottom: 0.125rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/join-password/join-password.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF","sourcesContent":[".mb-2 {\n  margin-bottom: 0.125rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
