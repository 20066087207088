import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  filters = {
    service: '',
    si: '',
    gu: '',
    categoryType: '',
  };

  initFilters = {
    service: '',
    si: '',
    gu: '',
    categoryType: '',
  };

  constructor() {}

  /**
   * 커뮤니티 필터
   * @param posts 게시물
   * @param allView 전체보기 유무
   * @param sortFilter 정렬 필터
   * @param serviceFilter 서비스 필터
   * @returns
   */
  communityFilter(
    posts: any[],
    allView: boolean,
    sortFilter: string,
    serviceFilter: string
  ): any[] {
    if (posts?.length < 1) {
      return [];
    }

    let filteredPosts = [...posts];

    if (allView && sortFilter === '최신순' && serviceFilter === '서비스별') {
      return filteredPosts;
    }

    // 서비스 필터링
    if (serviceFilter !== '서비스별') {
      filteredPosts = filteredPosts.filter(
        (val) => val.service === serviceFilter
      );
    }

    // 정렬
    if (sortFilter === '최신순') {
      filteredPosts.sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1));
    } else if (sortFilter === '인기순') {
      filteredPosts.sort((a, b) => {
        const popularityA = a.likeCount + a.commentCount;
        const popularityB = b.likeCount + b.commentCount;

        if (popularityA !== popularityB) {
          return popularityB - popularityA; // 인기순 정렬 (내림차순)
        } else {
          return b.viewCount < a.viewCount
            ? -1
            : b.viewCount > a.viewCount
            ? 1
            : 0;
        }
      });
    } else if (sortFilter === '조회수순') {
      filteredPosts.sort((a, b) => b.viewCount - a.viewCount);
    }

    return filteredPosts;
  }

  // 전문가찾기 필터
  setFilter(filter) {
    this.filters = filter;
  }

  //초기화
  clearData() {
    this.filters = JSON.parse(JSON.stringify(this.initFilters));
  }
}
