import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  type:
    | 'personal'
    | 'service'
    | 'unique'
    | 'mobileCarrier'
    | 'adInfo'
    | 'insuranceInfo';
  content: string = '';

  constructor(private activatedRoute: ActivatedRoute, private db: DbService) {}

  ngOnInit() {
    this.type = this.activatedRoute.snapshot.queryParams.type;
    this.getTerms();
  }

  // 약관 불러오기
  async getTerms() {
    const data = await this.db.toDoc$('admin/terms');
    this.content =
      this.type === 'personal'
        ? data.personal
        : this.type === 'service'
        ? data.service
        : this.type === 'unique'
        ? data.unique
        : this.type === 'mobileCarrier'
        ? data.mobileCarrier
        : this.type === 'insuranceInfo'
        ? data.insuranceInfo
        : data.adInfo;
  }
}
