import { Injectable } from '@angular/core';
import {
  AlertController,
  NavController,
  ToastController,
} from '@ionic/angular';
import type { TextFieldTypes } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private backAlertDisplayed = false; // 뒤로가기 알림 상태 플래그
  private alertDisplayed = false; // 알림 표시 상태 플래그

  constructor(
    private alertCtrl: AlertController,
    private toastCrtl: ToastController,
    public navController: NavController
  ) {}

  // 단일 버튼 알림
  async presentAlert(title: string, message: string) {
    const alert = await this.alertCtrl.create({
      cssClass: 'present-alert',
      header: title,
      message: message,
      backdropDismiss: false,
      buttons: ['확인'],
    });

    await alert.present();
  }

  async okBtn(
    message: string,
    header?: string,
    okText = '확인',
    role = 'ok'
  ): Promise<any> {
    this.alertDisplayed = true;
    return new Promise(async (resolve: any) => {
      const alert = await this.alertCtrl.create({
        cssClass: 'ok-alert',
        header,
        message,
        backdropDismiss: false,
        buttons: [
          {
            text: okText,
            role,
            handler: () => {
              this.alertDisplayed = false;
              resolve(true);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  // 취소 및 확인 버튼이 있는 알림
  async cancelOkBtn(
    message: string,
    header?: string,
    cancelText = '취소',
    okText = '확인'
  ): Promise<any> {
    this.alertDisplayed = true;
    return new Promise(async (resolve: any) => {
      const alert = await this.alertCtrl.create({
        cssClass: 'cancel-ok-alert',
        header,
        message,
        backdropDismiss: false,
        buttons: [
          {
            text: cancelText,
            role: 'cancel',
            handler: () => {
              this.alertDisplayed = false;
              resolve(false);
            },
          },
          {
            text: okText,
            role: 'ok',
            handler: () => {
              this.alertDisplayed = false;
              resolve(true);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  // 확인 및 취소 버튼이 있는 알림
  async okCancelBtn(
    message: string,
    header?: string,
    okText = '확인',
    cancelText = '취소'
  ): Promise<any> {
    this.alertDisplayed = true;
    return new Promise(async (resolve: any) => {
      const alert = await this.alertCtrl.create({
        cssClass: 'ok-cancel-alert',
        header,
        message,
        backdropDismiss: false,
        buttons: [
          {
            text: okText,
            role: 'ok',
            handler: () => {
              this.alertDisplayed = false;
              resolve(true);
            },
          },
          {
            text: cancelText,
            role: 'cancel',
            handler: () => {
              this.alertDisplayed = false;
              resolve(false);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  // 입력 필드가 있는 알림
  async inputAlert(
    inputType: TextFieldTypes | 'checkbox' | 'radio' | 'textarea',
    placeholder = '',
    message: string,
    header?: string,
    okText = '확인'
  ): Promise<any> {
    this.alertDisplayed = true;
    return new Promise(async (resolve: any) => {
      const alert = await this.alertCtrl.create({
        cssClass: 'input-alert',
        header,
        message,
        backdropDismiss: false,
        inputs: [
          {
            type: inputType as any,
            placeholder: placeholder,
          },
        ],
        buttons: [
          {
            text: '취소',
            role: 'cancel',
            handler: () => {
              this.alertDisplayed = false;
              resolve(false);
            },
          },
          {
            text: okText,
            role: 'ok',
            handler: (data) => {
              this.alertDisplayed = false;
              if (data && data[0] !== '') {
                resolve(data[0]);
              } else {
                return false;
              }
            },
          },
        ],
      });

      await alert.present();
    });
  }

  // 비밀번호 입력 필드가 있는 알림
  async inputPasswordAlert(message: string, header?: string): Promise<any> {
    this.alertDisplayed = true;
    return new Promise(async (resolve: any) => {
      const alert = await this.alertCtrl.create({
        cssClass: 'input-alert',
        header,
        message,
        backdropDismiss: false,
        inputs: [
          {
            type: 'password',
            placeholder: '',
          },
        ],
        buttons: [
          {
            text: '취소',
            role: 'cancel',
            handler: () => {
              this.alertDisplayed = false;
              resolve(false);
            },
          },
          {
            text: '확인',
            role: 'ok',
            handler: (value) => {
              this.alertDisplayed = false;
              resolve(value);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  // 토스트 메시지
  async presentToast(message: string, duration = 2000) {
    const toast = await this.toastCrtl.create({
      cssClass: 'toast',
      message: message,
      duration,
    });

    toast.present();
  }

  // 뒤로가기 알림
  async backAlert() {
    if (this.alertDisplayed) {
      return; // 이미 알림이 표시되고 있으면 아무 작업도 하지 않음
    }

    this.backAlertDisplayed = true;
    const ok = await this.cancelOkBtn(
      '입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?'
    );
    if (ok) {
      this.navController.pop();
    }
    this.backAlertDisplayed = false; // 알림이 닫히면 플래그를 false로 설정
  }

  // 현재 알림이 표시되고 있는지 확인
  isAlertDisplayed(): boolean {
    return this.alertDisplayed;
  }

  setBackAlertDisplayed(displayed: boolean) {
    this.backAlertDisplayed = displayed;
  }

  isBackAlertDisplayed(): boolean {
    return this.backAlertDisplayed;
  }
}
