// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choice {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 400;
  color: var(--gray-scale500);
}

.textarea-item::part(native) {
  flex-direction: column;
}
.textarea-item ion-textarea {
  min-height: 10rem;
  max-height: 10rem;
}
.textarea-item ion-textarea .textarea-wrapper .sc-ion-textarea-ios {
  height: 100%;
}

.num {
  text-align: right;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 400;
  color: var(--gray-scale500);
}

.title-sub {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 400;
  color: var(--gray-scale900);
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/application-last-inquiry/application-last-inquiry.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,2BAAA;AACF;;AAEE;EACE,sBAAA;AACJ;AACE;EACE,iBAAA;EACA,iBAAA;AACJ;AAAI;EACE,YAAA;AAEN;;AAEA;EACE,iBAAA;EACA,WAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;EACA,2BAAA;AACF;;AACA;EACE,mBAAA;EACA,sBAAA;EACA,gBAAA;EACA,2BAAA;EACA,qBAAA;AAEF","sourcesContent":[".choice {\n  font-size: 1.125rem;\n  line-height: 1.625rem;\n  font-weight: 400;\n  color: var(--gray-scale500);\n}\n.textarea-item {\n  &::part(native) {\n    flex-direction: column;\n  }\n  ion-textarea {\n    min-height: 10rem;\n    max-height: 10rem;\n    .textarea-wrapper .sc-ion-textarea-ios {\n      height: 100%;\n    }\n  }\n}\n.num {\n  text-align: right;\n  width: 100%;\n  font-size: 0.875rem;\n  line-height: 1.3125rem;\n  font-weight: 400;\n  color: var(--gray-scale500);\n}\n.title-sub {\n  font-size: 0.875rem;\n  line-height: 1.3125rem;\n  font-weight: 400;\n  color: var(--gray-scale900);\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
