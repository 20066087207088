import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-expert-introduction-write',
  templateUrl: './expert-introduction-write.component.html',
  styleUrls: ['./expert-introduction-write.component.scss'],
})
export class ExpertIntroductionWriteComponent implements OnInit {
  member: Member;
  memberForm = new FormGroup({
    detail: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(100),
    ]),
  });
  constructor(
    private alertService: AlertService,
    private modalController: ModalController,
    private auth: AuthService,
    private db: DbService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    if (this.member.introduce) {
      this.memberForm.get('detail').setValue(this.member.introduce);
    }
  }

  //닫기
  closeModal() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.modalController.dismiss();
        }
      });
  }

  //저장
  async save() {
    await this.db.updateAt(`members/${this.member.uuid}`, {
      introduce: this.memberForm.get('detail').value,
    });
    this.modalController.dismiss({
      introduce: this.memberForm.get('detail').value,
    });
    this.alertService.presentToast('전문가 소개글을 업데이트 하였습니다.');
  }
}
