import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActionSheetController, NavController } from '@ionic/angular';
import { TopCategory } from 'src/app/core/models/category.model';
import { Post } from 'src/app/core/models/post.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { CommunityService } from 'src/app/core/services/community.service';
import { DbService } from 'src/app/core/services/db.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { VideoService } from 'src/app/core/services/video.service';

@Component({
  selector: 'app-post-write',
  templateUrl: './post-write.component.html',
  styleUrls: ['./post-write.component.scss'],
})
export class PostWriteComponent implements OnInit {
  post: Post;
  postId: Post['id'];
  topCategories: TopCategory[] | any;
  selectedCategory: string = '서비스를 선택해주세요.';
  datas = []; // 이미지 + 영상
  memberForm = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.minLength(2)]),
    detail: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(2000),
    ]),
  });

  constructor(
    private actionSheetController: ActionSheetController,
    private alertService: AlertService,
    private navController: NavController,
    private videoService: VideoService,
    private activatedRoute: ActivatedRoute,
    private communityService: CommunityService,
    private db: DbService,
    private auth: AuthService,
    private commonService: CommonService,
    private loadingService: LoadingService
  ) {
    this.postId = this.activatedRoute.snapshot.queryParams.postId;
  }

  async ngOnInit() {
    await this.getData();

    if (this.postId) {
      this.post = await this.db.toDoc$(`posts/${this.postId}`);
      this.selectedCategory = this.post.service;
      this.memberForm.get('title').setValue(this.post.title);
      this.memberForm.get('detail').setValue(this.post.content);
      this.datas = this.post.datas;
    }
  }

  // 카테고리 데이터 불러오기
  async getData() {
    this.topCategories = await this.communityService.getTopCategories('noHome');
  }

  // 서비스 선택
  async selectAction() {
    const buttons = this.topCategories.map((category) => ({
      text: category.name,
      handler: () => {
        this.selectedCategory = category.name;
      },
    }));

    buttons.push({
      text: '취소',
      role: 'cancel',
      handler: () => {},
    });

    const actionSheet = await this.actionSheetController.create({
      buttons: buttons,
    });

    await actionSheet.present();
  }

  // 사진 선택
  async addImg() {
    try {
      if (this.datas?.length >= 6) {
        this.alertService.okBtn('최대 6장까지 등록 가능합니다.');
        return;
      }

      const media = await this.videoService.selectMedia('posts');
      if (media) {
        await this.loadingService.load();
        media.forEach((item) => {
          if (item.includes('mp4') || item.includes('mov')) {
            this.datas.push({ image: item, videoCheck: true });
          } else {
            this.datas.push({ image: item, videoCheck: false });
          }

          this.loadingService.hide();
        });
      }
    } catch (error) {
      this.loadingService.hide();
      console.error('에러가 발생했습니다.', error);
    }
  }

  // 사진 삭제
  async delImg(idx: number) {
    this.datas.splice(idx, 1);
  }

  // [저장하기]
  goSave() {
    if (this.datas?.length >= 6) {
      this.datas = this.datas.slice(0, 6);
    }

    this.alertService
      .cancelOkBtn(
        `입력한 내용으로 게시물을 ${
          this.postId ? '수정' : '등록'
        } 하시겠습니까?`
      )
      .then(async (ok) => {
        if (ok) {
          await this.loadingService.load();
          const id = this.postId
            ? this.postId
            : this.commonService.generateFilename();
          let post: Post = {
            id,
            uuid: this.auth.uid(),
            service: this.selectedCategory,
            dateCreated: this.postId
              ? this.post.dateCreated
              : new Date().toISOString(),
            datas: this.datas,
            title: this.memberForm.get('title').value,
            content: this.memberForm.get('detail').value,
            viewCount: this.postId ? this.post.viewCount : 0,
            likeCount: this.postId ? this.post.likeCount : 0,
            commentCount: this.postId ? this.post.commentCount : 0,
            reportUser: this.postId ? this.post.reportUser : [],
            isDelete: false,
          };
          await this.db.updateAt(`posts/${id}`, post);
          this.loadingService.hide();
          this.alertService.presentToast(
            `게시물을 ${this.postId ? '수정' : '등록'}했습니다.`
          );
          this.navController.pop();
        }
      });
  }

  // 활성화 조건
  disabled() {
    if (
      this.selectedCategory === '서비스를 선택해주세요.' ||
      this.memberForm.get('title').value?.length < 2 ||
      this.memberForm.get('detail').value?.length < 10
    ) {
      return true;
    } else {
      return false;
    }
  }

  //뒤로 얼럿
  backAlert() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.navController.pop();
        }
      });
  }
}
