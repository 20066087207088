// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-close {
  width: 2.75rem;
  height: 2.75rem;
  --background: url('modal-close-icon.png') no-repeat
    center / 100% 100%;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale750);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/service-detail/service-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA;sBAAA;AAEF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AACF","sourcesContent":[".modal-close {\n  width: 2.75rem;\n  height: 2.75rem;\n  --background: url(../../../../assets/imgs/modal-close-icon.png) no-repeat\n    center / 100% 100%;\n}\n\np {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  font-weight: 400;\n  color: var(--gray-scale750);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
