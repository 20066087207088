import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Estimate } from 'src/app/core/models/estimate.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService, leftJoinDocument } from 'src/app/core/services/db.service';
import { lastValueFrom, map, take } from 'rxjs';
import { Member } from 'src/app/core/models/member.model';
import { ChatService } from 'src/app/core/services/chat.service';
import { Chat } from 'src/app/core/models/chat.model';

@Component({
  selector: 'app-estimate-check-detail',
  templateUrl: './estimate-check-detail.component.html',
  styleUrls: ['./estimate-check-detail.component.scss'],
})
export class EstimateCheckDetailComponent implements OnInit {
  @Input() item;
  @Input() id: Estimate['id'];
  estimates: Estimate | any;
  member: Member;

  constructor(
    private activatedRoute: ActivatedRoute,
    private navController: NavController,
    private db: DbService,
    private auth: AuthService,
    private chatService: ChatService
  ) {}

  async ngOnInit() {
    this.item = this.activatedRoute.snapshot.queryParams['item'];

    this.member = await this.auth.getUser();
    await this.getData();
  }

  // 견적서 데이터 불러오기
  async getData() {
    this.estimates = await lastValueFrom(
      this.db
        .collection$('estimates', (ref) =>
          ref
            .where('acceptUuid', '==', this.member.uuid)
            .where('requestId', '==', this.item.requestId.id)
            .where('isDelete', '==', false)
            .orderBy('dateCreated', 'desc')
        )
        .pipe(leftJoinDocument(this.db.firestore, 'uuid', 'members'), take(1))
    );
  }

  // 견적서 확인하기
  async goCheckDetail(item: Estimate | any) {
    // 전문가에게 조회했다는 메시지 보내기
    const chat = await lastValueFrom(
      this.db
        .collection$('chats', (ref) => ref.where('estimateId', '==', item.id))
        .pipe(
          map((chats: Chat[]) => {
            return chats.filter(
              (chat: Chat) => !chat.exitUuid.includes(this.member.uuid)
            );
          }),
          take(1)
        )
    );

    if (chat?.length > 0 && !item.confirmEstimate) {
      await this.chatService.sendMessage(
        chat[0].id,
        'viewEstimate',
        '견적서 확인'
      );
    }
    // 견적서 읽음처리
    await this.db.updateAt(`estimates/${item.id}`, {
      confirmEstimate: true,
      confirmDate: new Date().toISOString(),
    });

    this.navController.navigateForward('/expert-detail', {
      queryParams: {
        type: 'cost',
        expertUuid: item.uuid.uuid,
        price: item.price,
        requestId: item.requestId,
        estimateId: item.id,
      },
    });
  }

  // 채팅하기
  goChat(item: Estimate | any) {
    this.chatService
      .createChat(item.requestId, item.id, item.uuid.uuid)
      .then(async (data) => {
        this.navController.navigateForward('/chat-detail', {
          queryParams: {
            chatId: data,
            partnerUuid: item.uuid.uuid,
          },
        });
      });
  }

  // 요청서 확인
  goDetail(item: Estimate | any) {
    this.navController.navigateForward('/estimate-request-detail', {
      queryParams: {
        requestId: item['requestId'].id,
        serviceName: item['expertUuid']
          ? item['expertUuid']?.services
          : item['requestId']['subCategoryId']?.name,
        expertName: item['uuid'].activityName,
        viewType: 'estimate',
      },
    });
  }
}
