import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-automatic-charging-change',
  templateUrl: './automatic-charging-change.component.html',
  styleUrls: ['./automatic-charging-change.component.scss'],
})
export class AutomaticChargingChangeComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private navController: NavController
  ) {}

  ngOnInit() {}

  goPayment() {
    this.alertService
      .cancelOkBtn('자동 충전 금액을 \n수정하시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.navController.navigateForward('/point-charge');
          this.alertService.presentToast('자동 충전 금액이 변경되었습니다.');
        }
      });
  }
}
