import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'pointFilter',
})
export class PointFilterPipe implements PipeTransform {
  transform(items, filter, year: number, month: number): any {
    if (!items) return [];

    // 년과 월 기준으로 필터링
    items = items.filter((item) => {
      const itemDate = dayjs(item.dateCreated); // Firebase Timestamp에서 dayjs 객체로 변환
      return itemDate.year() === year && itemDate.month() + 1 === month;
    });

    if (!filter || filter === '전체') {
      return items || [];
    } else if (filter === '포인트 충전') {
      return items.filter((e) => e.type == '포인트 충전') || [];
    } else if (filter === '포인트 사용') {
      return items.filter((e) => e.type == '포인트 사용') || [];
    } else if (filter === '보너스 포인트') {
      return (
        items.filter(
          (e) => e.type == '보너스 포인트' || e.type == '충전 보너스 포인트'
        ) || []
      );
    }
  }
}
