// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  width: 100%;
  padding: 1.5rem 1rem;
}

.no-list {
  height: calc(100vh - 9.375rem - 3rem);
  width: 100%;
}

.comment-card {
  width: 100%;
  padding: 1.25rem 1rem;
  border-bottom: 1px solid var(--gray-scale100);
}

.top-profile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}
.top-profile .avatar {
  width: 2.75rem;
  min-width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  overflow: hidden;
}
.top-profile .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-profile .text .name {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: var(--gray-scale750);
}
.top-profile .text .date {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--gray-scale500);
}

.content {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale900);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: pre-line;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/my-post/my-post.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,oBAAA;AACF;;AAEA;EACE,qCAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,qBAAA;EACA,6CAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,sBAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AAAI;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAEN;AAGI;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AADN;AAII;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;AAFN;;AAOA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,uBAAA;EACA,qBAAA;EACA,qBAAA;AAJF","sourcesContent":[".list {\n  width: 100%;\n  padding: 1.5rem 1rem;\n}\n\n.no-list {\n  height: calc(100vh - 9.375rem - 3rem);\n  width: 100%;\n}\n\n.comment-card {\n  width: 100%;\n  padding: 1.25rem 1rem;\n  border-bottom: 1px solid var(--gray-scale100);\n}\n\n.top-profile {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  margin-bottom: 0.75rem;\n\n  .avatar {\n    width: 2.75rem;\n    min-width: 2.75rem;\n    height: 2.75rem;\n    border-radius: 50%;\n    overflow: hidden;\n    img {\n      width: 100%;\n      height: 100%;\n      object-fit: cover;\n    }\n  }\n\n  .text {\n    .name {\n      font-size: 1rem;\n      line-height: 1.5rem;\n      font-weight: 500;\n      color: var(--gray-scale750);\n    }\n\n    .date {\n      font-size: 0.875rem;\n      line-height: 1.25rem;\n      font-weight: 400;\n      color: var(--gray-scale500);\n    }\n  }\n}\n\n.content {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  font-weight: 400;\n  color: var(--gray-scale900);\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  word-break: break-all;\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
