import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { arrayUnion } from 'firebase/firestore';
import { SubCategory, TopCategory } from 'src/app/core/models/category.model';
import { Member } from 'src/app/core/models/member.model';
import { Request } from 'src/app/core/models/request.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DbService } from 'src/app/core/services/db.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { RequestService } from 'src/app/core/services/request.service';
import { Notification } from 'src/app/core/models/notification.model';
import { PushService } from 'src/app/core/services/push.service';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-application-last-inquiry',
  templateUrl: './application-last-inquiry.component.html',
  styleUrls: ['./application-last-inquiry.component.scss'],
})
export class ApplicationLastInquiryComponent implements OnInit {
  request: Request;
  inquiryContent: string = '';
  topId: TopCategory['id'];
  subId: SubCategory['id'];
  expertUuid: Member['uuid'];
  requestType: 'home' | 'search' | 'estimate';

  constructor(
    private navContoller: NavController,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private commonService: CommonService,
    private db: DbService,
    private auth: AuthService,
    private requestService: RequestService,
    private pushService: PushService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.topId = this.activatedRoute.snapshot.queryParams['topId'];
    this.subId = this.activatedRoute.snapshot.queryParams['subId'];
    this.requestType = this.activatedRoute.snapshot.queryParams['requestType'];
    this.expertUuid = this.activatedRoute.snapshot.queryParams['expertUuid'];
    this.request = this.requestService.request;
  }

  //견적 요청 완료
  async goComplete() {
    const member = await this.auth.getUser();
    const top = await this.db.toDoc$(`topCategories/${this.topId}`);

    this.alertService
      .cancelOkBtn('작성한 내용으로 견적을 요청 하시겠습니까?')
      .then(async (ok) => {
        if (ok) {
          let request: Request = {
            id: this.commonService.generateFilename(),
            uuid: member.uuid,
            dateCreated: new Date().toISOString(),
            topCategoryId: this.topId || '',
            topCategoryName: top ? top.name : '',
            subCategoryId: this.subId || '',
            address: this.request.address,
            addressDetail: this.request.addressDetail,
            addressSiGu: {
              si: this.request.addressSiGu.si,
              gu: this.request.addressSiGu.gu,
            },
            buildingType: this.request.buildingType,
            hopeDate: this.request.hopeDate,
            pyeong: this.request.pyeong,
            part: this.request.part,
            images: this.request.images,
            question: this.inquiryContent,
            isDesignate: this.expertUuid ? true : false,
            estimateCount: 0,
            expertUuid: this.expertUuid || '',
            estimateId: '',
            dealStatus: '거래 전',
            isDelete: false,
            senders: [],
            estimateStatus: '상담 진행 대기중',
            deleteExperts: [],
            receiveExpertsUuid: [],
            confirmDealDate: '',
          };

          await this.db.updateAt(`requests/${request.id}`, request);

          // requestType === 'estimate' (전문가 상세 진입)
          if (this.requestType === 'estimate') {
            const selectExpert = await this.db.toDoc$(
              `members/${this.expertUuid}`
            );
            let notification: Notification = {
              id: this.commonService.generateFilename(),
              uuid: this.expertUuid,
              dateCreated: new Date().toISOString(),
              readSwitch: false,
              title: '견적서 요청',
              content: '견적서가 도착 하였습니다. 확인해주세요.',
              url: '/tabs/request-list',
              checkSwitch: false,
              isAdminPush: false,
            };
            await this.db.updateAt(
              `notifications/${notification.id}`,
              notification
            );

            this.sendPush(this.expertUuid);

            this.navContoller.navigateForward('/application-complete02');
          }
          // requestType === 'home' (카테고리 선택 진입) || requestType === 'search' (검색 진입)
          else {
            const allExperts = await this.db.toCollection$('members', (ref) =>
              ref.where('status', '==', 'activity')
            );

            const receiveExpertsUuid = []; // 전문가들의 uuid를 저장할 배열
            for (const expert of allExperts) {
              // 선택된 전문가의 서비스와 일치하는 값이 있는 데이터를 찾기
              if (expert?.services?.length > 0) {
                const isServiceMatch = expert.services.includes(this.subId);

                const expertArea = expert.areas.map((a) =>
                  a.si === 'all' && a.gu === 'all'
                    ? '전국'
                    : a.si !== 'all' && a.gu === 'all'
                    ? `${a.si} 전체`
                    : `${a.si} ${a.gu}`
                );

                let isAreaMatch = false;
                if (
                  expertArea == '전국' ||
                  expertArea.includes(
                    `${request.addressSiGu.si} ${request.addressSiGu.gu}`
                      .split(' ')
                      .slice(0, 2)
                      .join(' ')
                  ) ||
                  expertArea.includes(`${request.addressSiGu.si} 전체`)
                ) {
                  isAreaMatch = true;
                }

                // 견적을 받은 전문가들 Uuid 추가
                if (isServiceMatch && isAreaMatch) {
                  receiveExpertsUuid.push(expert.uuid);
                  let notification: Notification = {
                    id: this.commonService.generateFilename(),
                    uuid: expert.uuid,
                    dateCreated: new Date().toISOString(),
                    readSwitch: false,
                    title: '견적서 요청',
                    content: '견적서가 도착 하였습니다. 확인해주세요.',
                    url: '/tabs/request-list',
                    checkSwitch: false,
                    isAdminPush: false,
                  };
                  await this.db.updateAt(
                    `notifications/${notification.id}`,
                    notification
                  );
                  this.sendPush(expert.uuid);
                }
              }
            }

            if (receiveExpertsUuid.length > 0) {
              await this.db.updateAt(`requests/${request.id}`, {
                receiveExpertsUuid: arrayUnion(...receiveExpertsUuid),
              });
            }
            this.navContoller.navigateForward('/application-complete');
          }
          this.requestService.clearData();
        }
      });
  }

  // 푸시 보내기
  sendPush(uuid: Member['uuid']) {
    let obj = {
      content: '견적서가 도착 하였습니다. 확인해주세요.',
      link: '/tabs/request-list',
      type: 'member',
    };
    this.pushService.memberPush(obj, uuid);
  }
}
