import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { take } from 'rxjs';
import { Member } from '../models/member.model';
import { DbService } from './db.service';
import OneSignal from 'onesignal-cordova-plugin';

const USER_APP_ID = '834f46ac-19d4-47e9-a4fa-960e49732742';
const USER_ANDROID_CHANNEL_ID = 'ba34554b-a0bc-44d1-9b77-b5de9f460b40';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
    private db: DbService
  ) {}

  async getId() {
    if (this.platform.is('capacitor')) {
      return new Promise<{ userId: string }>((resolve) => {
        OneSignal.getDeviceState(({ userId }) => {
          resolve({ userId });
        });
      });
    } else {
      return false;
    }
  }

  // 알림내역에 쌓이지 않음
  sendPush(pushId: Array<string>, message: string, data?: any) {
    return new Promise<any>((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
        }),
      };
      let body = {
        //push 정보
        app_id: USER_APP_ID,
        headings: { en: '반셀인' },
        contents: { en: message },
        data: { url: data.url, type: data.type },
        include_player_ids: pushId,
        android_channel_id: USER_ANDROID_CHANNEL_ID,
        ios_badgeType: 'Increase',
        ios_badgeCount: 1,
      };

      if (pushId) {
        //로그인을 했을 경우에만
        this.httpClient
          .post('https://onesignal.com/api/v1/notifications', body, httpOptions)
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
      }
    });
  }

  // 회원에게 push와 알림 보내기
  memberPush(obj, uuid) {
    this.db
      .collection$(`members`, (ref) =>
        ref
          .where('status', '==', 'activity')
          .where('uuid', '==', uuid)
          .where('appAlarmSwitch', '==', true)
      )
      .pipe(take(1))
      .subscribe(async (datas: Member[]) => {
        if (datas && datas.length > 0) {
          for await (let ele of datas) {
            if (ele.pushId.length > 0) {
              await this.sendPush(ele.pushId, obj.content, {
                url: obj.link,
                type: obj.type,
              });
            }
          }
        }
      });
  }
}
