// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-item {
  padding: 1.5rem 0 1.5rem;
}
.review-item:not(:first-child) {
  border-top: 8px solid #f4f4f4;
}
.review-item:not(:last-child) {
  border-bottom: 1px solid var(--gray-scale100);
}

.list {
  width: 100%;
}

.top-padding {
  width: 100%;
  padding: 0 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/my-review-list/my-review-list.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;AACE;EACE,6BAAA;AACJ;AAEE;EACE,6CAAA;AAAJ;;AAIA;EACE,WAAA;AADF;;AAGA;EACE,WAAA;EACA,eAAA;AAAF","sourcesContent":[".review-item {\n  padding: 1.5rem 0 1.5rem;\n\n  &:not(:first-child) {\n    border-top: 8px solid #f4f4f4;\n  }\n\n  &:not(:last-child) {\n    border-bottom: 1px solid var(--gray-scale100);\n  }\n}\n\n.list {\n  width: 100%;\n}\n.top-padding {\n  width: 100%;\n  padding: 0 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
