import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor() {}

  /**
   * 이메일 정규식
   * @param {string} email
   * @returns
   */
  checkEmailFormat(email: string) {
    const emailReg =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    return emailReg.test(email);
  }

  /**
   * 비밀번호 정규식 (6글자)
   * @param {string} pwd
   * @returns
   */
  checkPwdFormat(pwd: string) {
    const pwdReg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
    return pwd?.length >= 6;
  }

  /**
   * 휴대폰 번호 정규식(숫자를 제외한 모든 문자를 입력하면 안됨)
   * @param {string} phone
   * @returns
   */
  checkPhoneFormat(phone: string) {
    const phoneReg = /^(?=.*[0-9])(?=.*^.{10,11}$)[0-9]+$/;
    return phoneReg.test(phone);
  }
}
