// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-info {
  width: 100%;
  padding: 1rem;
}

.card {
  width: 100%;
  background: #fff;
  padding: 1rem;
  border-radius: 6px;
  overflow: hidden;
}
.card app-estimate-shared {
  width: 100%;
}
.card .main-btn {
  margin-top: 0.75rem;
}

.cost-item {
  padding: 1.5rem 1rem;
}
.cost-item:not(:first-child) {
  border-top: 8px solid #f4f4f4;
}

.expect-cost {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--gray-scale100);
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
}
.expect-cost .gray {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale600);
}
.expect-cost .cost {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: #07213a;
}

.btn-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.0625rem;
}
.btn-wrap .main-btn {
  height: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: #fff;
}
.btn-wrap .main-line-btn {
  height: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: #07213a;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/common/estimate-check-detail/estimate-check-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,oBAAA;AADF;AAEE;EACE,6BAAA;AAAJ;;AAIA;EACE,WAAA;EACA,qBAAA;EACA,sCAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAGE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AADJ;AAIE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAFJ;;AAMA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAHF;AAKE;EACE,cAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,WAAA;AAHJ;AAME;EACE,cAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,cAAA;AAJJ","sourcesContent":[".top-info {\n  width: 100%;\n  padding: 1rem;\n}\n\n.card {\n  width: 100%;\n  background: #fff;\n  padding: 1rem;\n  border-radius: 6px;\n  overflow: hidden;\n\n  app-estimate-shared {\n    width: 100%;\n  }\n\n  .main-btn {\n    margin-top: 0.75rem;\n  }\n}\n\n.cost-item {\n  padding: 1.5rem 1rem;\n  &:not(:first-child) {\n    border-top: 8px solid #f4f4f4;\n  }\n}\n\n.expect-cost {\n  width: 100%;\n  padding: 0.75rem 1rem;\n  border: 1px solid var(--gray-scale100);\n  border-radius: 6px;\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 0.75rem;\n\n  .gray {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 400;\n    color: var(--gray-scale600);\n  }\n\n  .cost {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 700;\n    color: #07213a;\n  }\n}\n\n.btn-wrap {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 1.0625rem;\n\n  .main-btn {\n    height: 2.5rem;\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 700;\n    color: #fff;\n  }\n\n  .main-line-btn {\n    height: 2.5rem;\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 700;\n    color: #07213a;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
