import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { MemberService } from 'src/app/core/services/member.service';

@Component({
  selector: 'app-join-agree',
  templateUrl: './join-agree.component.html',
  styleUrls: ['./join-agree.component.scss'],
})
export class JoinAgreeComponent implements OnInit {
  member: Member;
  agreeAllTerms: boolean = false; //전체동의
  age: boolean = false; // 만 14세 이상

  constructor(
    private navController: NavController,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.member = this.memberService.member;
    if (
      this.member.agree.personal &&
      this.member.agree.service &&
      this.age &&
      this.member.agree.adInfo
    ) {
      this.agreeAllTerms = true;
    }
  }

  // 전체 동의
  checkAgreeAll(num) {
    if (this.agreeAllTerms && num == 0) {
      this.agreeAllTerms = true;
      this.member.agree.personal = true;
      this.member.agree.service = true;
      this.age = true;
      this.member.agree.adInfo = true;
    } else if (
      num == 0 &&
      this.member.agree.personal &&
      this.member.agree.service &&
      this.age &&
      this.member.agree.adInfo
    ) {
      this.member.agree.personal = false;
      this.member.agree.service = false;
      this.age = false;
      this.member.agree.adInfo = false;
    }
    if (
      this.member.agree.personal &&
      this.member.agree.service &&
      this.age &&
      this.member.agree.adInfo &&
      num != 0
    ) {
      this.agreeAllTerms = true;
    } else if (num != 0) {
      this.agreeAllTerms = false;
    }
  }

  //약관보기
  goTerms(type) {
    this.navController.navigateForward('/terms', {
      queryParams: { type },
    });
  }

  //회원가입 완료
  goJoinComplete() {
    this.memberService.joinAuth(
      this.member.email,
      this.member.conrimePwd,
      this.member.loginType,
      this.member.recommendCode
    );
  }
}
