import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { IamportService } from 'src/app/core/services/iamport.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-payment-regist',
  templateUrl: './payment-regist.component.html',
  styleUrls: ['./payment-regist.component.scss'],
})
export class PaymentRegistComponent implements OnInit {
  member: Member;
  selectId: string = '';
  cardInfo = {
    cardNumber: null,
    expiryDate: null,
    pwd: null,
    birthOrBusinessNum: '',
  };
  customerUid = ''; // 발급시 설정할 빌링키

  constructor(
    private alertService: AlertService,
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private db: DbService,
    private http: HttpClient,
    private iamportService: IamportService,
    private loadingService: LoadingService
  ) {}

  async ngOnInit() {
    this.selectId = this.activatedRoute.snapshot.queryParams['selectId'];
    this.member = await this.auth.getUser();
  }

  //완료 버튼 클릭 시: 올바르지 않은 카드정보 입력 시 Alert노출
  noComplete() {
    this.alertService.okBtn('카드 정보를 다시 확인해주세요.');
  }

  //수정 버튼 클릭 시: 올바른 카드정보 입력 시
  goCompleteEdit() {
    this.alertService
      .cancelOkBtn('카드 정보를 수정하시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.navController.navigateForward('/point-charge');
          this.alertService.presentToast('카드 정보가 변경되었습니다.');
        }
      });
  }

  //수정 버튼 클릭 시: 올바르지 않은 카드정보 입력 시 Alert노출
  noCompleteEdit() {
    this.alertService.okBtn('카드 정보를 다시 확인해주세요.');
  }

  // 카드번호 숫자만 입력되도록
  onCardNumberInput(event: any) {
    let inputValue = event.target.value;
    // 숫자 외의 문자 제거 (정규식 사용)
    inputValue = inputValue.replace(/[^0-9]/g, '');
    // 최대 16자리까지만 허용
    if (inputValue.length > 16) {
      inputValue = inputValue.substring(0, 16);
    }
    // 입력값을 다시 설정
    this.cardInfo.cardNumber = inputValue;
    event.target.value = inputValue;
  }

  // [완료]
  async goComplete() {
    // 카드 확인처리
    await this.loadingService.load();
    const cardCheck = await this.checkCard();

    if (!cardCheck) {
      this.noComplete();
      return;
    }

    // 카드번호 끝 4자리
    const cardNumber = this.cardInfo.cardNumber.toString();
    const lastFourDigits = cardNumber.slice(-4);
    await this.db.updateAt(`members/${this.member.uuid}`, {
      autoChargeId: this.selectId,
      paymentCardInfo: {
        cardNumber: this.cardInfo.cardNumber,
        bank: '',
        expiryDate: this.cardInfo.expiryDate,
        pwd: this.cardInfo.pwd,
        birthOrBusinessNum: this.cardInfo.birthOrBusinessNum,
        customerUid: this.member.uuid + lastFourDigits,
      },
    });
    this.navController.navigateForward('/point-charge');
    this.alertService.presentToast('자동 충전이 등록되었습니다.');
  }

  // 카드 유효한지 체크
  // 카드 비번 + 유효기간은 실연동이 되어야지 같이 인증된다. 테스트 모드에서는 카드번호만 인증
  async checkCard() {
    // 카드번호 끝 4자리
    const cardNumber = this.cardInfo.cardNumber.toString();
    const lastFourDigits = cardNumber.slice(-4);

    this.customerUid = this.member.uuid + lastFourDigits;

    return new Promise(async (resolve, reject) => {
      const token = await this.iamportService.getToken();

      if (token) {
        this.http
          .post(
            'https://us-central1-banseliin.cloudfunctions.net/generateBillingKey',
            {
              card_number: this.cardInfo.cardNumber, // 카드 번호
              expiry: this.cardInfo.expiryDate, // 카드 유효기간
              birth: this.cardInfo.birthOrBusinessNum, // 생년월일 (법인카드면 사업자번호)
              pwd_2digit: this.cardInfo.pwd, // 카드 비밀번호 앞 두자리
              customer_uid: this.customerUid,
              token: token,
            }
          )
          .toPromise()
          .then((data: any) => {
            this.loadingService.hide();
            if (data.code === 0) {
              resolve(data.response);
            } else {
              resolve('');
            }
          })
          .catch((err) => {
            this.loadingService.hide();
            resolve('');
          });
      }
    });
  }
}
