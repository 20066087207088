import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'dateFormat',
  pure: false,
})
export class DateFormatPipe implements PipeTransform {
  transform(date: string): string {
    const now = dayjs(); // 현재 시간
    const dateObj = dayjs(date); // 표시 시간
    const diffSeconds = now.diff(dateObj, 'seconds'); // 표시 시간과 현재의 초 차이
    const diffMinutes = now.diff(dateObj, 'minutes'); // 표시 시간과 현재의 분 차이
    const diffHours = now.diff(dateObj, 'hours'); // 표시 시간과 현재의 시 차이
    const diffDays = now.diff(dateObj, 'days'); // 표시 시간과 현재의 날 차이
    const diffMonths = now.diff(dateObj, 'months'); // 표시 시간과 현재의 달 차이

    if (diffSeconds < 60) {
      // 60초 이내의 경우
      return '방금 전';
    } else if (diffMinutes < 60) {
      // 60분 이내의 경우
      return `${diffMinutes}분 전`;
    } else if (diffHours < 24) {
      // 24시간 이내의 경우
      return `${diffHours}시간 전`;
    } else if (diffDays < 30) {
      // 20일 이내의 경우
      return `${diffDays}일 전`;
    } else if (diffDays < 360) {
      // 360일 이내의 경우
      return `${diffMonths}개월 전`;
    } else {
      // 그외에는 일자로 표기
      return dayjs(date).format('YYYY.MM.DD');
    }
  }
}
