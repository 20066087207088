import { Component, OnInit } from '@angular/core';
import { Review } from 'src/app/core/models/review.model';
import { lastValueFrom, map, take } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService, leftJoinDocument } from 'src/app/core/services/db.service';
import { Member } from 'src/app/core/models/member.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-my-review-list',
  templateUrl: './my-review-list.component.html',
  styleUrls: ['./my-review-list.component.scss'],
})
export class MyReviewListComponent implements OnInit {
  topLimit = 10;
  member: Member;
  reviews: Review[] | any;
  type: 'member' | 'expert';

  constructor(
    private auth: AuthService,
    private db: DbService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.type = this.activatedRoute.snapshot.queryParams['type'];
    this.member = await this.auth.getUser();
    await this.getReviews();
  }

  // 리뷰 불러오기
  async getReviews() {
    const reviews = await lastValueFrom(
      this.db
        .collection$('reviews', (ref) => ref.where('isDelete', '==', false))
        .pipe(
          leftJoinDocument(this.db.firestore, 'expertUuid', 'members'),
          map((reviews: Review[]) => {
            // 'expert' 상황에서 review.uuid가 빈 문자열이 아닌 경우만 members 컬렉션과 조인
            return reviews.map(async (review) => {
              if (
                (this.type === 'member' && review.uuid) ||
                (this.type === 'expert' && review.uuid !== '')
              ) {
                // uuid가 있을 때만 조인
                const member = await lastValueFrom(
                  this.db.doc$(`members/${review.uuid}`).pipe(take(1))
                );
                return { ...review, uuid: member };
              }
              // uuid가 없으면 조인하지 않고 그대로 반환
              return review;
            });
          }),
          take(1)
        )
    );

    this.reviews = await Promise.all(reviews);
    // 내림차순 정렬
    this.reviews.sort((a, b) =>
      b.dateCreated < a.dateCreated ? -1 : b.dateCreated > a.dateCreated ? 1 : 0
    );

    const filteredReviews =
      this.type === 'member'
        ? this.reviews.filter(
            (review: Review | any) => review.uuid.uuid === this.member.uuid
          )
        : this.reviews.filter(
            (review: Review | any) =>
              review.expertUuid.uuid === this.member.uuid
          );

    this.reviews = filteredReviews;
  }

  // 인피니티 스크롤
  loadData(ev: any) {
    setTimeout(() => {
      this.topLimit += 10;
      ev.target.complete();
    }, 500);
  }
}
