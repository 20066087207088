import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';

@Component({
  selector: 'app-business-information-detail',
  templateUrl: './business-information-detail.component.html',
  styleUrls: ['./business-information-detail.component.scss'],
})
export class BusinessInformationDetailComponent implements OnInit {
  @Input() expertInfo: Member['expertInfo'];
  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  // 닫기
  closeModal() {
    this.modalController.dismiss();
  }
}
