// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fake-header-inner-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: calc(0.5rem + env(safe-area-inset-top)) 1rem 0.875rem 0.375rem;
  border-bottom: 1px solid var(--gray-scale100);
  position: sticky;
  background-color: #fff;
  top: 0;
  left: 0;
}
.fake-header-inner-wrap .search-wrap {
  width: calc(100% - 2.75rem);
}

.list-wrap {
  width: 100%;
  padding: 0 1rem 1.5rem;
}

.sub-title {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 700;
  color: var(--gray-scale900);
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.no-list {
  overflow-y: hidden;
  height: calc(100vh - 3.875rem - 6.25rem);
}

.resulet {
  width: 100%;
  margin-bottom: 0.875rem;
  border-radius: 0;
}
.resulet p {
  width: 100%;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--gray-scale900);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/search/search.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,uEAAA;EACA,6CAAA;EACA,gBAAA;EACA,sBAAA;EACA,MAAA;EACA,OAAA;AACF;AAAE;EACE,2BAAA;AAEJ;;AAEA;EACE,WAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,2BAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,wCAAA;AACF;;AAEA;EACE,WAAA;EACA,uBAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AAEJ","sourcesContent":[".fake-header-inner-wrap {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  padding: calc(0.5rem + env(safe-area-inset-top)) 1rem 0.875rem 0.375rem;\n  border-bottom: 1px solid var(--gray-scale100);\n  position: sticky;\n  background-color: #fff;\n  top: 0;\n  left: 0;\n  .search-wrap {\n    width: calc(100% - 2.75rem);\n  }\n}\n\n.list-wrap {\n  width: 100%;\n  padding: 0 1rem 1.5rem;\n}\n\n.sub-title {\n  font-size: 1.125rem;\n  line-height: 1.625rem;\n  font-weight: 700;\n  color: var(--gray-scale900);\n  margin-bottom: 1rem;\n  margin-top: 1.5rem;\n}\n\n.no-list {\n  overflow-y: hidden;\n  height: calc(100vh - 3.875rem - 6.25rem);\n}\n\n.resulet {\n  width: 100%;\n  margin-bottom: 0.875rem;\n  border-radius: 0;\n  p {\n    width: 100%;\n    text-align: left;\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 400;\n    color: var(--gray-scale900);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
